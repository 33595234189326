const BONUS_URL = '/bonus';

export const getBonusesByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${BONUS_URL}/${fleetId}${filters}`;
  } else {
    return `${BONUS_URL}/${fleetId}`;
  }
};

export const getAdvancedBonusesByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${BONUS_URL}/advanced/${fleetId}${filters}`;
  } else {
    return `${BONUS_URL}/advanced/${fleetId}`;
  }
};

export const createAdvancedBonus = () => {
  const url = `${BONUS_URL}/advanced`;
  return url;
};

export const updateAdvancedBonus = (bonusId) => {
  const url = `${BONUS_URL}/advanced/${bonusId}`;
  return url;
};

export const createBonus = () => {
  const url = `${BONUS_URL}`;
  return url;
};

export const updateBonus = (bonusId) => {
  const url = `${BONUS_URL}/${bonusId}`;
  return url;
};

export const getBonusByFleetId = () => {
  const url = `${BONUS_URL}`;
  return url;
};
