import React from 'react';

const RegisteredPill = ({ status }) => {
  return (
    <p className={`px-2 py-1 w-28 rounded-full text-center font-bold ${status === 1 ? 'text-green-700 bg-green-200' : 'text-red-700 bg-red-200'}`}>
      {status === 1 ? 'Registered' : 'Unregistered'}
    </p>
  );
};

export default RegisteredPill;
