import dayjs from 'dayjs';

// import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import WithdrawalPill from '../../../../Shared/components/Pills/WithdrawalPill';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';

const PreviousWithdrawals = ({ loading, error, previousWithdrawals }) => {
  const headers = [
    { label: 'Amount', key: 1 },
    { label: 'Requested At', key: 2 },
    { label: 'Processed At', key: 3 },
    { label: 'Status', key: 4 }
    // { label: 'Receipt', key: 5 }
  ];

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {previousWithdrawals && (
            <tbody>
              {previousWithdrawals.map((withdrawal) => (
                <tr className="bg-white border-b whitespace-nowrap" key={withdrawal.createdAt}>
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                    {formatNumber(withdrawal.withdrawableAmount)}
                  </th>
                  <td className="px-6 py-4">{dayjs(withdrawal?.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4">{withdrawal?.updatedAt ? dayjs(withdrawal?.updatedAt).format('MMM DD, YYYY') : '-'}</td>
                  <td className="px-4 py-6 text-green-500">
                    <WithdrawalPill status={withdrawal?.status} />
                  </td>
                  {/* <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Receipt"
                        small
                        rounded
                        bg="bg-gray-400"
                        border="border-gray-400"
                        onClick={() => {
                          console.log('object');
                        }}
                      />
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default PreviousWithdrawals;
