import dayjs from 'dayjs';

import Loader from '../../../../Shared/components/Loader';

const PurchaseHistoryTable = ({ purchases, loading, error }) => {
  const headers = [
    { label: 'DATE', key: 1 },
    { label: 'CREDITS', key: 2 },
    { label: 'AMOUNT', key: 3 },
    { label: 'STATUS', key: 4 },
    { label: 'RECEIPT', key: 5 }
  ];

  const statusPill = (status) => {
    return (
      <p className={`px-2 py-1 w-28 rounded-full text-center font-bold ${status === 1 ? 'text-green-700 bg-green-200' : 'text-orange-700 bg-orange-200'}`}>
        {status === 1 ? 'PAID' : 'FAILED'}
      </p>
    );
  };

  return (
    <div className="relative overflow-x-auto standard-border rounded-3xl max-h-[600px]">
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {purchases && (
            <tbody>
              {purchases.map((purchase) => {
                return (
                  <tr key={purchase.paymentId} className="bg-white border-b">
                    <td className="px-6 py-4">{dayjs(purchase.createdAt).format('MMM DD, YYYY HH:mm')}</td>
                    <td className="px-6 py-4">{purchase.credits}</td>
                    <td className="px-6 py-4">
                      {purchase.price} {purchase.currency}
                    </td>
                    <td className="px-6 py-4">{statusPill(purchase.isPaid)}</td>
                    <td className="px-6 py-4">{purchase.isPaid == 1 ? 'Sent on email' : ''}</td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default PurchaseHistoryTable;
