import React, { useState } from 'react';

import { forgotPasswordSendEmail } from '../../api/services/Auth';
import Loader from '../../components/Loader';

const ForgotPasswordForm = ({ goBack, emailSent }) => {
  const [resetEmail, setResetEmail] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const handleEmailSend = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const email = resetEmail;
      const response = await forgotPasswordSendEmail(email);

      console.log(response);

      emailSent();
      setSubmitting(false);
    } catch (err) {
      emailSent();
      console.log(err);
      setSubmitting(false);
    }
  };

  return (
    <div className="">
      <p className="mb-5 text-sm text-center">Enter your email and we`ll send you a link to reset your password.</p>
      <form className="flex flex-col w-full space-y-8" onSubmit={handleEmailSend}>
        <div className="">
          <label htmlFor="email-address" className="block text-sm font-semibold leading-6 text-gray-900">
            Email address
          </label>
          <input
            onChange={(e) => setResetEmail(e.target.value)}
            type="email"
            id="email-address"
            className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
            value={resetEmail}
            required={true}
          />
        </div>

        <button
          type="submit"
          disabled={resetEmail.length < 1}
          className={`${
            submitting ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue cursor-pointer'
          } relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group  disabled:bg-gray-300 disabled:cursor-not-allowed hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}>
          {submitting && (
            <span className="absolute inset-y-0 right-0 flex items-center pr-3">
              <div className="ml-2">
                <Loader small={true} />
              </div>
            </span>
          )}
          Send me the email
        </button>
      </form>
      <div className="flex items-center justify-center mt-4 text-sm">
        <p onClick={goBack} className="font-medium text-blue hover:opacity-80 hover:cursor-pointer">
          Go Back
        </p>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
