import Loader from '../../../../Shared/components/Loader';

const DeliveriesByUserHistory = ({ users, loading, error }) => {
  const headers = [
    { label: 'NAME', key: 1 },
    { label: 'DELIVERIES', key: 2 }
  ];

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[600px]">
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {users && (
            <tbody>
              {users.map((user) => (
                <tr key={user.addressId} className="bg-white border-b">
                  <td className="px-6 py-4">{user.customerFullName}</td>
                  <td className="px-6 py-4">{user.orderCount}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default DeliveriesByUserHistory;
