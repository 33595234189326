import PropTypes from 'prop-types';

const SmallHeading = ({ text }) => {
  return <div className="my-1 font-bold text-md text-blue">{text}</div>;
};

SmallHeading.defaultProps = {
  text: 'Small Heading Text'
};

SmallHeading.propTypes = {
  text: PropTypes.string.isRequired
};

export default SmallHeading;
