import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

const InfoModal = ({ isOpen, close, text, secondaryText, importantText }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="flex flex-col items-center justify-center w-full max-w-xl p-6 overflow-hidden text-center transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="text-2xl font-medium leading-6 text-gray-900">
                    Important! <span>&#9888;</span>
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="w-full mx-auto text-gray-500 text-md">{text}</p>
                    <p className="w-full mx-auto mt-2 font-bold text-gray-900 text-md">{secondaryText}</p>
                    <p className="w-full mx-auto font-semibold text-blue text-md">{importantText}</p>
                  </div>

                  <div className="mt-4 space-x-4">
                    <button
                      type="button"
                      className="inline-flex justify-center px-4 py-2 text-sm font-medium bg-blue-100 border rounded-md text-blue border-blue hover:bg-blue hover:text-white focus:outline-none"
                      onClick={close}>
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default InfoModal;
