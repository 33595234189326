import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import StatusFilter from '../../../Shared/components/Filters/StatusFilter';
import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getCitiesByFleetId } from '../../api/services/CityService';
import { getFleetById } from '../../api/services/FleetService';
import CityTable from '../../components/Drivers/Cities/CityTable';
import CreateCityPanel from '../../components/Drivers/Cities/CreateCityPanel';
import FleetCodePanel from '../../components/Drivers/Cities/FleetCodePanel';

const CitiesAndFleetCodes = () => {
  const [loading, setLoading] = useState(false);
  const [loadingFleet, setLoadingFleet] = useState(false);
  const [error, setError] = useState('');
  const [cities, setCities] = useState([]);
  const [fleet, setFleet] = useState({});

  const { user } = useAuth();
  const { search } = useLocation();

  const fleetId = user?.fleetId;
  const axiosPrivate = useAxiosPrivate();
  const citiesUrl = getCitiesByFleetId(fleetId, search);
  const fleetUrl = getFleetById(fleetId);

  useEffect(() => {
    setError('');
    getCities();
  }, [search]);

  useEffect(() => {
    getFleet();
  }, []);

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(citiesUrl);
      setLoading(false);
      console.log(response.data);
      if (response.data.length === 0) {
        setError('No cities have been found');
      } else {
        setCities(response.data);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getFleet = async () => {
    setLoadingFleet(true);
    try {
      const response = await axiosPrivate.get(fleetUrl);
      setFleet(response.data);
      setLoadingFleet(false);
    } catch (error) {
      console.log(error);
      setLoadingFleet(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center space-x-3 lg:flex-row bg-llYellow rounded-xl ">
        <div className="w-11/12 p-4 md:w-3/4 lg:w-1/2">
          <CreateCityPanel getCities={getCities} />
        </div>
        <div className="w-11/12 p-4 md:w-3/4 lg:w-1/2">
          <FleetCodePanel loading={loadingFleet} code={fleet.code} id={fleetId} getFleet={getFleet} />
        </div>
      </div>
      <div className="w-full m-auto mt-10 lg:max-w-6xl">
        <div className="flex flex-col items-baseline justify-between mt-5 sm:flex-row sm:items-center">
          <Heading text="Manage Cities" />
          {cities.length > 0 && (
            <div className="flex space-x-4">
              <div className="flex items-center space-x-2">
                <StatusFilter />
              </div>
            </div>
          )}
        </div>
        <CityTable cities={cities} loading={loading} error={error} getCities={getCities} />
      </div>
    </>
  );
};

export default CitiesAndFleetCodes;
