import React from 'react';

import DynamicIcon from '../../components/DynamicIcon';

const EmailSentConfirmation = ({ goBack }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <DynamicIcon icon="CheckCircleIcon" width="w-24" height="h-24" color="text-blue" />
      <p className="mt-2 text-center">Please check your email! </p>
      <p className="mt-2 text-center">We have sent you an email with a link to reset your password.</p>
      <div className="flex items-center justify-center mt-4 text-sm">
        <p onClick={goBack} className="font-medium text-blue hover:opacity-80 hover:cursor-pointer">
          Back to login
        </p>
      </div>
    </div>
  );
};

export default EmailSentConfirmation;
