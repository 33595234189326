const DELIVERY_URL = '/delivery';

export const createDeliveries = () => {
  const url = `${DELIVERY_URL}`;
  return url;
};

export const getDeliveriesTodayByBusinessId = (businessId) => {
  const url = `${DELIVERY_URL}/today/business/${businessId}`;
  return url;
};

export const getFutureDeliveriesByBusinessId = (businessId) => {
  const url = `${DELIVERY_URL}/future/business/${businessId}`;
  return url;
};

export const getDeliveriesTodayStatsByBusinessId = (businessId) => {
  const url = `${DELIVERY_URL}/today/stats/business/${businessId}`;
  return url;
};

export const cancelDelivery = (orderId) => {
  const url = `${DELIVERY_URL}/cancel/${orderId}`;
  return url;
};

export const createOrUpdateBusinessNote = (orderId) => {
  const url = `${DELIVERY_URL}/note/business/${orderId}`;
  return url;
};
