import axios from '../axios';

const USER_URL = '/user';

export const register = async (data) => {
  try {
    const response = await axios.post(USER_URL + '/register', data);
    return response;
  } catch (error) {
    // console.log(error);
    return error.response;
  }
};

export const updateMyUser = () => {
  const url = `${USER_URL}`;
  return url;
};

export const updateUser = (userId) => {
  const url = `${USER_URL}/${userId}`;
  return url;
};
