import { useContext, useEffect, useState } from 'react';

import _ from 'lodash';

import Autocomplete from '../../../../Shared/components/FormElements/Autocomplete';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { getAddressesByBusinessId } from '../../../api/services/AddressService';
import { DeliveryContext } from '../../../contexts/DeliveryContext';

const DeliveryForm = () => {
  const [receiver, setReceiver] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const { submitted, setSubmitted, setDeliveryDetails, refetch } = useContext(DeliveryContext);

  const id = user?.businessId;
  const addressesUrl = getAddressesByBusinessId(id);

  useEffect(() => {
    setError('');
    getAddresses();
  }, [refetch]);

  useEffect(() => {
    if (submitted) clearFields();
  }, [submitted]);

  useEffect(() => {
    setSubmitted(false);
    if (receiver !== '') {
      setDeliveryDetails({ receiver });
    } else {
      setDeliveryDetails({});
    }
  }, [receiver]);

  const clearFields = () => {
    setReceiver('');
  };

  const getAddresses = async () => {
    try {
      const response = await axiosPrivate.get(addressesUrl);
      // console.log('addresses', response.data);
      setLoading(false);
      if (response.data.length === 0) {
        setError('No addresses have been created');
      } else {
        const filteredAddresses = _.filter(response.data, { active: 1 });
        setAddresses(filteredAddresses);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const handleSelect = (value) => {
    // console.log(value);
    if (value !== '') {
      const selected = addresses?.find((item) => item?.id == value);
      setReceiver(selected);
      console.log(selected);
    } else {
      setReceiver('');
    }
  };

  return (
    <div className="mt-0 w-fll">
      <form>
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-4 gap-6">
              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="business" className="block w-2/5 text-sm font-bold text-gray-700 min-w-fit">
                  Receiver Name*
                </label>
                <Autocomplete values={addresses} handleSelect={(e) => handleSelect(e)} />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="business" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Business Name
                </label>
                <input
                  type="text"
                  name="business"
                  id="business"
                  readOnly
                  disabled
                  value={receiver ? (receiver.businessName ? receiver.businessName : '-') : ''}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="phoneNumber" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Phone number
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  id="phoneNumber"
                  readOnly
                  disabled
                  value={receiver ? (receiver.phoneNumber ? receiver.phoneNumber : '-') : ''}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="email-address" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  readOnly
                  disabled
                  value={receiver ? (receiver.email ? receiver.email : '-') : ''}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="address" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Address (Street)
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  readOnly
                  disabled
                  value={receiver && receiver.address}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="notes" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Notes
                </label>
                <input
                  type="text"
                  name="notes"
                  id="notes"
                  readOnly
                  disabled
                  value={receiver ? (receiver.notes ? receiver.notes : '-') : ''}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="country" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Country
                </label>
                <input
                  type="text"
                  name="country"
                  id="country"
                  readOnly
                  disabled
                  value={receiver && receiver.country}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="city" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  readOnly
                  disabled
                  value={receiver && receiver.city}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="postCode" className="block w-2/5 text-sm font-medium text-gray-400 min-w-fit">
                  Postal code
                </label>
                <input
                  type="number"
                  name="postCode"
                  id="postCode"
                  readOnly
                  disabled
                  value={receiver && receiver.postCode}
                  className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DeliveryForm;
