import React from 'react';

import Divider from '../../../../Shared/components/Divider';
import Loader from '../../../../Shared/components/Loader';

const QuickAnalytics = ({ loading, error, today, lastWeek, lastMonth }) => {
  return (
    <div className="p-5 bg-gray-50 rounded-2xl">
      {loading ? (
        <div className="flex items-center justify-center w-full col-span-12 p-5 mx-auto mt-10">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <div className="flex flex-col justify-center space-y-5 text-center sm:space-y-0 sm:space-x-20 sm:flex-row">
          <InfoCard title="Deliveries today" today={today?.orders} lastWeek={lastWeek?.orders} lastMonth={lastMonth?.orders} />
          <div className="sm:hidden">
            <Divider />
          </div>
          <InfoCard title="Sales today (credits)" today={today?.sales} lastWeek={lastWeek?.sales} lastMonth={lastMonth?.sales} />
        </div>
      )}
    </div>
  );
};

const InfoCard = ({ title, today, lastWeek, lastMonth }) => {
  const getPercentageDiff = (current, previous) => {
    let percent = ((current - previous) / previous) * 100;
    percent = Math.round((percent + Number.EPSILON) * 100) / 100;
    if (percent.toString() === 'NaN' || percent.toString() === 'Infinity') {
      return <span> ( - ) </span>;
    } else {
      return <span className={`${current > previous ? 'text-green-500' : 'text-red-400'}`}> ({percent}%) </span>;
    }
  };

  return (
    <div className="flex flex-col items-center">
      <p className="my-2 uppercase">{title}</p>
      <div className="flex items-center justify-center w-24 h-24 p-3 text-3xl font-bold bg-white border-2 border-gray-300 rounded-full min-w-fit min-h-fit text-blue">
        {today}
      </div>
      <div className="flex mt-2 space-x-5 sm:space-x-0 sm:space-y-10 sm:flex-col">
        <div className="flex flex-col space-x-5 sm:flex-row">
          <p className="uppercase">Last week:</p>
          <p>
            {lastWeek} <> {getPercentageDiff(today, lastWeek)} </>
          </p>
        </div>
        <div className="flex flex-col space-x-5 sm:flex-row">
          <p className="uppercase">Last month:</p>
          <p>
            {lastMonth} <> {getPercentageDiff(today, lastMonth)} </>
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuickAnalytics;
