const CITY_URL = '/city';

export const getCitiesByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${CITY_URL}/${fleetId}${filters}`;
  } else {
    return `${CITY_URL}/${fleetId}`;
  }
};

export const createCity = () => {
  const url = `${CITY_URL}`;
  return url;
};

export const updateCity = (id) => {
  const url = `${CITY_URL}/${id}`;
  return url;
};
