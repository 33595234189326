import { useEffect, useState } from 'react';

import Loader from '../../../../Shared/components/Loader';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { getShippingPriceByBranch, getShippingPriceByCity } from '../../../api/services/PricingService';
import BaseFee from './PricingCriterias/BaseFee';
import DistanceFee from './PricingCriterias/DistanceFee';
import WeightFee from './PricingCriterias/WeightFee';

const PricingCriteriaList = ({ cityId, pricingType, customer }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [basePricing, setBasePricing] = useState(null);
  const [distancePricing, setDistancePricing] = useState(null);
  const [weightPricing, setWeightPricing] = useState(null);

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;

  useEffect(() => {
    getPricing('base', setBasePricing);
    getPricing('distance', setDistancePricing);
    getPricing('weight', setWeightPricing);
  }, []);

  const getPricing = async (type, setter) => {
    setLoading(true);
    let pricingUrl;

    if (pricingType === 'CustomerPricing' && customer) {
      pricingUrl = getShippingPriceByBranch(fleetId, customer.id);
      console.log(customer.id);
    } else {
      pricingUrl = getShippingPriceByCity(fleetId, cityId);
    }
    try {
      const response = await axiosPrivate.get(pricingUrl, { params: { type } });
      setter(response.data);
      // setBasePricing(response.data);
      console.log(`${type} PRICING`, response.data);
      if (response.data.length === 0) {
        setError(`No criteria found for ${type} pricing`);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center my-5 space-y-10 md:my-14">
      {loading ? (
        <Loader />
      ) : (
        <div className="flex flex-col items-center justify-between md:items-baseline md:flex-row space-y-14 md:space-y-0 md:space-x-14">
          <BaseFee basePricing={basePricing} branchId={customer?.id} getPricing={() => getPricing('base', setBasePricing)} cityId={cityId} />
          <DistanceFee
            distancePricing={distancePricing}
            branchId={customer?.id}
            getPricing={() => getPricing('distance', setDistancePricing)}
            cityId={cityId}
          />
          <WeightFee weightPricing={weightPricing} branchId={customer?.id} getPricing={() => getPricing('weight', setWeightPricing)} cityId={cityId} />
        </div>
      )}
    </div>
  );
};

export default PricingCriteriaList;
