import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import ContractTypeFilter from '../../../Shared/components/Filters/ContractTypeFilter';
import StatusFilter from '../../../Shared/components/Filters/StatusFilter';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getContractsByFleetId } from '../../api/services/ContractService';
import ContractsForm from '../../components/Drivers/Contracts/ContractsForm';
import ContractsTable from '../../components/Drivers/Contracts/ContractsTable';

const Contracts = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const contractsUrl = getContractsByFleetId(fleetId, search);

  useEffect(() => {
    setError('');
    getContracts();
  }, [search]);

  const getContracts = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(contractsUrl);
      setLoading(false);
      console.log(response.data);
      if (response.data.length === 0) {
        setError('No contracts have been found');
      } else {
        setContracts(response.data);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full m-auto lg:max-w-5xl">
      <div className="flex justify-center w-full sm:justify-end">
        <Button text="Add Contract" onClick={toggleModal} rounded>
          <DynamicIcon icon="PlusCircleIcon" />
        </Button>
      </div>
      <div className="flex flex-col items-baseline justify-between mt-5 sm:flex-row sm:items-center">
        <Heading text="Created Contracts" />
        {contracts.length > 0 && (
          <div className="flex flex-col mt-2 space-y-2 sm:mt-0 sm:space-y-0 sm:space-x-4 sm:flex-row">
            <div className="z-30 flex items-center space-x-2">
              <ContractTypeFilter />
            </div>
            <div className="z-10 flex items-center space-x-2">
              <StatusFilter />
            </div>
          </div>
        )}
      </div>
      <ContractsTable contracts={contracts} loading={loading} error={error} getContracts={getContracts} />

      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Add Contract"
          body={<ContractsForm closeModal={toggleModal} getContracts={getContracts} type="Add" />}
        />
      )}
    </div>
  );
};

export default Contracts;
