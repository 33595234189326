import { Fragment } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const PeriodFilter = ({ period, setPeriod }) => {
  const periodFilterList = ['Today', 'Custom Period'];

  return (
    <div className="flex items-center space-x-2 z-[49]">
      <h2 className="whitespace-nowrap">Select period:</h2>
      <div className="relative w-20 min-w-fit z-10">
        <Listbox value={period} onChange={setPeriod}>
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-pointer rounded-3xl bg-gray-500 text-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
              <span className="block truncate">{period}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {periodFilterList.map((period, periodIdx) => (
                  <Listbox.Option
                    key={periodIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 border-b border-gray-200 pr-10 ${
                        active ? 'bg-yellow-400 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={period}>
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{period}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-amber-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export default PeriodFilter;
