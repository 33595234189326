import { useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import CopyText from '../../../../Shared/components/CopyText';
import Loader from '../../../../Shared/components/Loader';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { updateFleet } from '../../../api/services/FleetService';

const FleetCodePanel = ({ code, id, getFleet, loading }) => {
  const [open, setOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const updateDriverUrl = updateFleet(id);

  const toggleWarning = () => {
    setOpen(!open);
  };

  const generateCode = async () => {
    setSubmitting(true);
    let randomString = (Math.random() + 1).toString(36).substring(2);

    try {
      await axiosPrivate.put(updateDriverUrl, { code: randomString });
      getFleet();
      toggleWarning();
      toast.success('Code generated!');
    } catch (error) {
      toast.error('Code generation failed, try again later!');
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <div className="my-2 overflow-hidden bg-white shadow md:rounded-full rounded-2xl">
      <div className="flex flex-col items-center p-4 justify-items-center">
        <div className="flex flex-col items-center mb-2 space-x-2 md:flex-row">
          <span> Fleet connection code: </span>
          {loading ? (
            <div className="flex items-center">
              <Loader small={true} />
            </div>
          ) : code ? (
            <div className="flex items-center space-x-2">
              <div className="text-xl font-bold text-blue">{code}</div>
              <CopyText text={code} />
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <div className="text-xl font-bold text-blue">---</div>
            </div>
          )}
        </div>
        <div className="flex justify-center">
          <Button text="Generate new code" rounded onClick={toggleWarning} disabled={false} />
        </div>
      </div>
      {open && <ConfirmDeleteModal cancel={toggleWarning} isOpen={true} remove={generateCode} codeGeneration={true} disabled={submitting} />}
    </div>
  );
};

export default FleetCodePanel;
