import { useEffect, useContext } from 'react';

import { Tab } from '@headlessui/react';

import { DeliveryContext } from '../../../contexts/DeliveryContext';
import BasicPickupForm from './BasicPickup/BasicPickupForm';
import CustomPickupForm from './CustomPickup/CustomPickupForm';

const Pickup = () => {
  const { setPickupType } = useContext(DeliveryContext);

  useEffect(() => {
    setPickupType('basic');
  }, []);

  const tabs = [
    {
      name: 'Basic Pickup',
      content: <BasicPickupForm />,
      type: 'basic'
    },
    {
      name: 'Custom Pickup',
      content: <CustomPickupForm />,
      type: 'custom'
    }
  ];

  return (
    <div className="w-full mt-2">
      <TabSwitcher tabs={tabs} />
    </div>
  );
};

export default Pickup;

const TabSwitcher = ({ tabs }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const { setPickupType } = useContext(DeliveryContext);

  return (
    <div className="w-full px-2">
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl">
          {tabs.map((tab) => (
            <Tab
              onClick={() => setPickupType(tab.type)}
              key={tab.name}
              className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1.5 text-sm font-medium leading-5',
                  'ring-white focus:outline-none',
                  selected ? 'bg-blue text-white shadow' : 'text-blue border-2 border-blue hover:bg-blue hover:text-white'
                )
              }>
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="">
          {tabs.map((tab) => (
            <Tab.Panel key={tab.name} className={classNames('rounded-xl bg-white', 'ring-white focus:outline-none')}>
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
