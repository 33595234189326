const showDeliveryStatus = (status) => {
  switch (status) {
    case 0:
      return <p className="font-bold text-blue">Created</p>;
    case 1:
      return <p className="font-bold text-yellow-500">Arrived</p>;
    case 2:
      return <p className="font-bold text-orange-400">Picked up</p>;
    case 3:
      return <p className="font-bold text-green-500">Delivered</p>;
    case -1:
      return <p className="font-bold text-red-500">Cancelled</p>;
    default:
      return <p className="font-bold text-stone-500">Unknown</p>;
  }
};

export default showDeliveryStatus;
