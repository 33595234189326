const STATISTICS_URL = '/statistics';

export const getDeliveryStatsByBusinessId = (businessId) => {
  const url = `${STATISTICS_URL}/deliveries/business/${businessId}`;
  return url;
};

export const getUserDeliveryStatsByBusinessId = (businessId) => {
  const url = `${STATISTICS_URL}/deliveries/user/business/${businessId}`;
  return url;
};

export const getStatsByBusinessId = (businessId) => {
  const url = `${STATISTICS_URL}/business/${businessId}`;
  return url;
};

export const getDeliveryHistoryByBusinessId = (businessId) => {
  const url = `${STATISTICS_URL}/deliveries/history/business/${businessId}`;
  return url;
};
