import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import Calendar from '../../../../../Shared/components/Calendar.jsx';

const RepeatOrder = ({ minDate, mainOrder, additionalOrders, setAdditionalOrders }) => {
  const [selectedDates, setSelectedDates] = useState([]);

  useEffect(() => {
    const selected = additionalOrders.map((order) => dayjs(order.pickupTime).format('YYYY-MM-DD'));
    setSelectedDates(selected);
  }, [additionalOrders]);

  const handleDateSelect = (dateStr) => {
    const isDateSelected = selectedDates.includes(dateStr);
    setSelectedDates((prevSelectedDates) => (isDateSelected ? prevSelectedDates.filter((date) => date !== dateStr) : [...prevSelectedDates, dateStr]));

    if (isDateSelected) {
      setAdditionalOrders((prevOrders) => prevOrders.filter((order) => !dayjs(order.pickupTime).isSame(dateStr, 'day')));
    } else {
      const newOrder = {
        ...mainOrder,
        pickupTime: dayjs(mainOrder.pickupTime)
          .set('year', dayjs(dateStr).year())
          .set('month', dayjs(dateStr).month())
          .set('date', dayjs(dateStr).date())
          .format(),
        deliveryTime: dayjs(mainOrder.deliveryTime)
          .set('year', dayjs(dateStr).year())
          .set('month', dayjs(dateStr).month())
          .set('date', dayjs(dateStr).date())
          .format()
      };
      setAdditionalOrders((prevOrders) => [...prevOrders, newOrder]);
    }
  };

  return (
    <div className="flex flex-col my-8 space-y-8">
      <h1 className="text-2xl text-center text-slate-700">Do you want to repeat the order?</h1>
      <Calendar minDate={minDate} selectedDates={selectedDates} onDateSelect={handleDateSelect} />
    </div>
  );
};

export default RepeatOrder;
