import { useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import Button from '../../../Shared/components/Button';
import Loader from '../../../Shared/components/Loader';
import ConfirmDeleteModal from '../../../Shared/components/Modals/ConfirmDeleteModal';
import ConnectionPill from '../../../Shared/components/Pills/ConnectionPill';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { deleteApiKey } from '../../api/services/ApiKeyService';

const ApiControlTable = ({ loading, error, apiKeys, getApiKeys }) => {
  const [revokeKeyModal, setRevokeKeyModal] = useState(false);
  const [keyId, setKeyId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const headers = [
    { label: 'Name', key: 1 },
    { label: 'Created at', key: 2 },
    { label: 'Key', key: 3 },
    { label: 'Type', key: 4 },
    { label: 'Revoke', key: 5 }
  ];

  const toggleRevokeKeyModal = () => {
    setRevokeKeyModal(!revokeKeyModal);
  };

  const handleRevoke = async () => {
    setSubmitting(true);
    const deleteApiKeyUrl = deleteApiKey(keyId);
    try {
      // console.log(keyId);
      const response = await axiosPrivate.delete(deleteApiKeyUrl);
      console.log(response.data);
      getApiKeys();
      toggleRevokeKeyModal();
      toast.info('API key revoked!');
    } catch (error) {
      toast.error('Something went wrong...');
    }
    setSubmitting(false);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr className="whitespace-nowrap">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {apiKeys && (
            <tbody>
              {apiKeys.map((key) => (
                <tr key={key.id} className="bg-white border-b whitespace-nowrap">
                  <td className="px-6 py-4">{key.name}</td>
                  <td className="px-6 py-4">{dayjs(key.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4">{key.firstEight}...</td>
                  <td className="px-2 py-4">
                    <ConnectionPill type={key.testKey} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Revoke"
                        bg="bg-red-500"
                        border="border-red-500"
                        textColor="text-white"
                        rounded
                        onClick={() => {
                          toggleRevokeKeyModal();
                          setKeyId(key.id);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {revokeKeyModal && keyId !== null && (
        <ConfirmDeleteModal
          disabled={submitting}
          cancel={toggleRevokeKeyModal}
          isOpen={revokeKeyModal}
          remove={() => handleRevoke()}
          id={keyId}
          action={'revoke'}
          name={`API key`}
        />
      )}
    </div>
  );
};

export default ApiControlTable;
