import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import ShiftForm from './ShiftForm';

const ShiftModal = ({ isOpen, closeModal, getShifts, type, shift }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-2/5 max-w-4xl p-3 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl min-w-fit rounded-2xl">
                  <Dialog.Title as="h3" className="mb-4 text-lg font-medium leading-6 text-center text-gray-900 uppercase">
                    Edit Shift
                  </Dialog.Title>

                  {/* FORM */}
                  <ShiftForm closeModal={closeModal} getShifts={getShifts} shift={shift} type={type} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ShiftModal;
