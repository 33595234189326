import React, { useState } from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const DAYS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const Calendar = ({ minDate, selectedDates, onDateSelect }) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs());

  const minDay = minDate ? dayjs(minDate).add(1, 'day').startOf('day') : null;

  const startDay = currentMonth.startOf('month').isoWeekday(1);
  const endDay = currentMonth.endOf('month').isoWeekday(7);

  const daysInMonth = [];
  let day = startDay;

  while (day.isSameOrBefore(endDay)) {
    daysInMonth.push(day);
    day = day.add(1, 'day');
  }

  const handleDateClick = (day) => {
    if (minDay && day.isBefore(minDay, 'day')) return;

    const dateStr = day.format('YYYY-MM-DD');
    onDateSelect(dateStr);
  };

  const nextMonth = () => {
    setCurrentMonth(currentMonth.add(1, 'month'));
  };

  const prevMonth = () => {
    setCurrentMonth(currentMonth.subtract(1, 'month'));
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-4">
        <button onClick={prevMonth} className="text-blue hover:text-blue-700">
          <ChevronLeftIcon className="w-6 h-6" />
        </button>
        <h2 className="text-lg font-semibold">{currentMonth.format('MMMM YYYY')}</h2>
        <button onClick={nextMonth} className="text-blue hover:text-blue-700">
          <ChevronRightIcon className="w-6 h-6" />
        </button>
      </div>
      <div className="grid grid-cols-7 gap-2 mb-4 font-bold text-center">
        {DAYS.map((day, index) => (
          <div key={index} className="text-gray-500">
            {day}
          </div>
        ))}
      </div>
      <div className="grid grid-cols-7 gap-2">
        {daysInMonth.map((day) => {
          const dateStr = day.format('YYYY-MM-DD');
          const isSelected = selectedDates.includes(dateStr);
          const isDisabled = minDay && day.isBefore(minDay, 'day');

          return (
            <div
              key={dateStr}
              className={`p-2 m-1 text-center rounded-lg transition-colors duration-200 ${
                isDisabled
                  ? 'bg-gray-100 text-gray-300 cursor-not-allowed'
                  : isSelected
                  ? 'bg-blue text-white cursor-pointer'
                  : 'bg-slate-300 text-slate-900 cursor-pointer hover:bg-slate-200'
              }`}
              onClick={() => !isDisabled && handleDateClick(day)}>
              {day.date()}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Calendar;
