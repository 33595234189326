import { createContext } from 'react';

import { io } from 'socket.io-client';

// const socket = io('http://localhost:8000/');
// const socket = io('https://api.lastlap.delivery/');

const socket = io(process.env.REACT_APP_API_URL);
const SocketContext = createContext({});

export const SocketProvider = ({ children }) => {
  return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};

export default SocketContext;
