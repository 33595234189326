import React, { useState } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';

import DeliveryDetails from '../../../Shared/components/DeliveryDetails';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import TextModal from '../../../Shared/components/Modals/TextModal';
import NoteIcon from '../../../Shared/components/NoteIcon';
import SmallHeading from '../../../Shared/components/SmallHeading';
import showDeliveryStatus from '../../../Shared/helpers/showDeliveryStatus';
import showNoteCount from '../../../Shared/helpers/showNoteCount';
import NoteFormFleet from './NoteFormFleet';

const ExpandableTable = ({ loading, error, deliveries, headingText, type, drivers, getDeliveries }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  const [delivery, setDelivery] = useState(null);
  const [listState, setListState] = useState(false);

  const toggleListState = () => {
    setListState(!listState);
  };

  const toggleNoteModal = () => {
    setNoteModal(!noteModal);
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  const todayHeaders = [
    { label: 'Order ID', key: 1 },
    { label: 'External Order ID', key: 2 },
    { label: 'Notes', key: 3 },
    { label: 'External Order Price', key: 4 },
    { label: 'Business', key: 5 },
    { label: 'Branch pickup', key: 6 },
    { label: 'Customer address', key: 7 },
    { label: 'Company name', key: 8 },
    { label: 'Distance (km)', key: 9 },
    { label: 'Pickup', key: 10 },
    { label: 'Delivery', key: 11 },
    { label: 'Driver', key: 12 },
    { label: 'Status', key: 13 },
    { label: 'Assigned', key: 14 }
  ];

  const preorderHeaders = [
    { label: 'Order ID', key: 1 },
    { label: 'External Order ID', key: 10 },
    { label: 'Notes', key: 13 },
    { label: 'External Order Price', key: 11 },
    { label: 'Business', key: 2 },
    { label: 'Branch pickup', key: 3 },
    { label: 'Customer address', key: 9 },
    { label: 'Company name', key: 12 },
    { label: 'Distance (km)', key: 4 },
    { label: 'Pickup', key: 5 },
    { label: 'Delivery', key: 6 },
    { label: 'Status', key: 7 }
  ];

  const renderHeaders = () => {
    if (type === 'preorder') {
      return preorderHeaders;
    }
    return todayHeaders;
  };

  const headers = renderHeaders();

  const generateColor = (delivery) => {
    if (delivery.skipForAssigner && delivery.deliveryId === null && delivery.status !== -1 && (delivery.openApi === 1 || delivery.openApi === 0)) {
      return 'bg-red-200';
    }
    if (delivery.openApi === 1 && !(delivery.skipForAssigner && delivery.deliveryId === null && delivery.status)) {
      return 'bg-orange-50';
    }
    return 'bg-white';
  };

  return (
    <>
      <div className="w-full h-full bg-white rounded-2xl">
        <div className="w-full overflow-x-auto rounded-2xl">
          <Disclosure as="div" className="p-1" defaultOpen={false}>
            <>
              <div className="flex flex-col items-center justify-between w-full space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
                <Disclosure.Button
                  onClick={() => toggleListState()}
                  className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left bg-white border-2 border-gray-200 rounded-full hover:bg-gray-50 outline-4">
                  <div className="flex items-center">
                    <SmallHeading text={headingText} />
                  </div>
                  <ChevronUpIcon className={`${listState === true ? 'rotate-180 transform' : ''} h-5 w-5 text-blue`} />
                </Disclosure.Button>
              </div>

              <Disclosure.Panel open={listState} className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl" as="div">
                {loading ? (
                  <div className="flex justify-center w-full p-5">
                    <Loader small />
                  </div>
                ) : deliveries.length > 0 ? (
                  <table className="w-full overflow-scroll text-sm text-left text-gray-500 rounded-3xl">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                      <tr>
                        {headers?.map((header) => (
                          <th key={header.key} scope="col" className="px-4 py-3 whitespace-nowrap">
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    {deliveries && (
                      <tbody>
                        {deliveries.map((delivery) => (
                          <tr key={delivery.id} className={`${generateColor(delivery)} border-b`}>
                            <td
                              className="px-4 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80 whitespace-nowrap"
                              onClick={() => {
                                toggleInfoModal(), setDelivery(delivery);
                              }}>
                              {delivery.orderReference}
                            </td>
                            <td className="px-4 py-4 text-orange-500 whitespace-nowrap">
                              {delivery?.externalOrderReference ? `${delivery?.externalOrderReference}` : '---'}{' '}
                            </td>
                            <td className="px-4 py-4 whitespace-nowrap">
                              <NoteIcon
                                counter={showNoteCount(delivery, 'fleet')}
                                onClick={() => {
                                  toggleNoteModal(), setDelivery(delivery);
                                }}
                              />
                            </td>
                            {/* TODO: change to user/account currency */}
                            <td className="px-4 py-4 whitespace-nowrap">{delivery?.externalOrderPrice ? `${delivery?.externalOrderPrice} kr.` : '---'} </td>
                            <td className="px-4 py-4">{delivery.businessName}</td>
                            <td className="px-4 py-4">{delivery.branchName}</td>
                            <td className="px-4 py-4">
                              {delivery.deliveryAddress}, {delivery.deliveryCity}, {delivery.deliveryPostCode}
                            </td>
                            <td className="px-4 py-4">{delivery.customerBusinessName || '---'}</td>
                            <td className="px-4 py-4 whitespace-nowrap">{delivery.distance}</td>
                            {type === 'preorder' ? (
                              <td className="px-4 py-4 whitespace-nowrap">{dayjs(delivery.pickupTime).format('MMM DD, HH:mm')}</td>
                            ) : (
                              <td className="px-4 py-4">{dayjs(delivery.pickupTime).format('HH:mm')}</td>
                            )}
                            {type === 'preorder' ? (
                              <td className="px-4 py-4 whitespace-nowrap">{dayjs(delivery.deliveryTime).format('MMM DD, HH:mm')}</td>
                            ) : (
                              <td className="px-4 py-4">{dayjs(delivery.deliveryTime).format('HH:mm')}</td>
                            )}
                            {type !== 'preorder' && <td className="px-4 py-4 whitespace-nowrap">{delivery.driverName ? delivery.driverName : '---'}</td>}
                            <td className="px-4 py-4 whitespace-nowrap">{showDeliveryStatus(delivery.status)}</td>
                            {type !== 'preorder' && (
                              <td className="px-4 py-2 whitespace-nowrap">
                                {delivery.driverId === null ? (delivery.skipForAssigner ? '❗️❗️❗️' : '❌') : '✅'}
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    )}
                  </table>
                ) : (
                  <div className="flex justify-center w-full p-5">
                    <p className="text-xl text-gray-500">{error}</p>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          </Disclosure>
        </div>
      </div>
      {infoModal && delivery && (
        <BaseModal
          closeModal={toggleInfoModal}
          isOpen={infoModal}
          title="Delivery Details"
          body={<DeliveryDetails closeModal={toggleInfoModal} delivery={delivery} manualAssign={true} type={type} drivers={drivers} />}
        />
      )}

      {noteModal && delivery && (
        <TextModal
          closeModal={toggleNoteModal}
          isOpen={noteModal}
          type={delivery?.fleetNote?.length ? 'edit' : 'add'}
          title={delivery?.fleetNote?.length ? 'Edit Note' : 'Add Note'}
          body={
            <NoteFormFleet
              closeModal={toggleNoteModal}
              delivery={delivery}
              getDeliveries={getDeliveries}
              toggleNoteModal={toggleNoteModal}
              labelText="Fleet note:"
              businessNote={delivery?.businessNote ?? ''}
            />
          }
        />
      )}
    </>
  );
};

export default ExpandableTable;
