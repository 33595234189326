const showOrderRef = (orderReference, status) => {
  switch (status) {
    case 0:
      return <p className="font-bold text-blue">{orderReference}</p>;
    case 1:
      return <p className="font-bold text-yellow-500">{orderReference}</p>;
    case 2:
      return <p className="font-bold text-orange-400">{orderReference}</p>;
    case 3:
      return <p className="font-bold text-green-500">{orderReference}</p>;
    default:
      return <p className="font-bold text-gray-500">{orderReference}</p>;
  }
};

export default showOrderRef;
