import React from 'react';

import ContactForm from '../../../Shared/components/Contact/ContactForm';
import ContactList from '../../../Shared/components/Contact/ContactList';
import Heading from '../../../Shared/components/Heading';

const Contact = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <Heading text="Contact us" />
      <div className="mt-4 flex flex-col lg:flex-row w-full max-w-7xl gap-x-10">
        <div className="lg:w-1/2 w-full">
          <ContactForm />
        </div>
        <div className="lg:w-1/2 w-full mt-5 lg:mt-0">
          <ContactList />
        </div>
      </div>
    </div>
  );
};

export default Contact;
