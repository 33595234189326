import React from 'react';

import { Switch } from '@headlessui/react';

const CustomSwitch = ({ enabled, onChange, small, enabledColor, disabledColor }) => {
  const switchSize = small ? 'h-[18px] w-[38px]' : 'h-[28px] w-[64px]';
  const thumbSize = small ? 'h-[14px] w-[14px]' : 'h-[24px] w-[24px]';

  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className={`
          ${enabled ? enabledColor : disabledColor}
          relative inline-flex ${switchSize} shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75
        `}>
      <span className="sr-only">Switch setting</span>
      <span
        aria-hidden="true"
        className={`
            ${enabled && small ? 'translate-x-5' : enabled && !small ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block ${thumbSize} transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out
          `}
      />
    </Switch>
  );
};

CustomSwitch.defaultProps = {
  enabled: false,
  small: false,
  enabledColor: 'bg-green-400',
  disabledColor: 'bg-gray-200'
};

export default CustomSwitch;
