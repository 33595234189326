import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../Shared/components/Button';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../Shared/components/Pills/StatusPill';
import BranchesForm from './BranchesForm';

const BranchesTable = ({ branches, loading, error, getBranches }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [branch, setBranch] = useState(null);

  const headers = [
    { label: 'Branch Name', key: 1 },
    { label: 'Email', key: 2 },
    { label: 'Phone number', key: 3 },
    { label: 'Created at', key: 4 },
    { label: 'Address', key: 5 },
    { label: 'Status', key: 6 },
    { label: 'Manage', key: 7 }
  ];

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {/* {error && <p className="mt-3 text-xl text-red-500">{error}</p>} */}
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {branches &&
              branches.map((branch) => (
                <tr key={branch.id} className="bg-white border-b whitespace-nowrap">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {branch.name}
                  </th>
                  <td className="px-6 py-4">{branch.email?.length ? branch.email : '---'}</td>
                  <td className="px-6 py-4">{branch.phoneNumber?.length ? branch.phoneNumber : '---'}</td>
                  <td className="px-6 py-4">{dayjs(branch.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4">
                    {branch.address}, {branch.postCode}, {branch.city}
                  </td>
                  <td className="px-2 py-4">
                    <StatusPill status={branch.active} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        bg="bg-blue"
                        rounded
                        border="border-blue"
                        onClick={() => {
                          toggleIsOpen(), setBranch(branch);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleIsOpen}
          isOpen={isOpen}
          title="Edit Branch"
          body={<BranchesForm type="Edit" getBranches={getBranches} branch={branch} closeModal={toggleIsOpen} />}
        />
      )}
    </div>
  );
};

export default BranchesTable;
