import React from 'react';

import { addThousandSeparators } from '../../../../Shared/helpers/numberFormatter';

const SalesNumberCards = ({ total, base, distance, weight }) => {
  return (
    <div className="grid grid-cols-12 gap-4 mt-5">
      <div className="flex flex-col justify-center w-full col-span-12 p-5 bg-white md:col-span-6 xl:col-span-12 standard-border">
        <p className="text-gray-700">
          Total Sales <small className="italic"> (credits) </small>
        </p>
        <p className="text-2xl font-medium">{total ? addThousandSeparators(total) : 0}</p>
      </div>
      <div className="flex flex-col justify-center w-full col-span-12 p-5 bg-white md:col-span-6 xl:col-span-12 standard-border">
        <p className="text-gray-700">
          Base <small className="italic"> (credits) </small>
        </p>
        <p className="text-2xl font-medium">{base ? addThousandSeparators(base) : 0}</p>
      </div>
      <div className="flex flex-col justify-center w-full col-span-12 p-5 bg-white md:col-span-6 xl:col-span-12 standard-border">
        <p className="text-gray-700">
          Distance <small className="italic"> (credits) </small>
        </p>
        <p className="text-2xl font-medium">{distance ? addThousandSeparators(distance) : 0}</p>
      </div>
      <div className="flex flex-col justify-center w-full col-span-12 p-5 bg-white md:col-span-6 xl:col-span-12 standard-border">
        <p className="text-gray-700">
          Weight <small className="italic"> (credits) </small>
        </p>
        <p className="text-2xl font-medium">{weight ? addThousandSeparators(weight) : 0}</p>
      </div>
    </div>
  );
};

export default SalesNumberCards;
