import { useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { deleteDriverShift } from '../../../api/services/DriverShiftService';

const ShiftDriverTable = ({ loading, error, driverShifts, shiftId, getDriverShifts }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [driverId, setDriverId] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const headers = [
    { label: `Driver's name`, key: 1 },
    { label: 'Phone', key: 2 },
    { label: 'Clocked In', key: 3 },
    { label: 'Remove', key: 4 }
  ];

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDriverRemove = async () => {
    setSubmitting(true);
    setRefresh(true);
    const removeDriverUrl = deleteDriverShift(driverId, shiftId);
    try {
      const response = await axiosPrivate.delete(removeDriverUrl);
      console.log(response.data);
      setDeleteModal(false);
      toast.success('Driver removed from shift!');
      getDriverShifts();
      setRefresh(false);
    } catch (error) {
      console.log(error);
      setRefresh(false);
      toast.error('Error encountered while removing driver');
    }
    setSubmitting(false);
  };

  return (
    <div className="relative mt-5 overflow-scroll rounded-xl h-2/3">
      {loading || refresh ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {driverShifts && (
            <tbody>
              {driverShifts.map((driver) => (
                <tr key={driver.driverId} className="bg-white border-b ">
                  <td className="px-6 py-4">
                    {driver.firstName} {driver.lastName}
                  </td>
                  <td className="px-6 py-4">{driver.phoneNumber}</td>
                  <td className="px-6 py-4">{driver.clockedIn === 0 ? 'No' : 'Yes'}</td>
                  <td className="px-6 py-4">
                    {driver.clockedIn === 0 ? (
                      <div className="flex space-x-2">
                        <Button
                          text="Remove"
                          rounded
                          disabled={submitting}
                          bg="bg-white"
                          border="border-red-500"
                          textColor="text-red-500"
                          onClick={() => {
                            toggleDeleteModal(), setDriverId(driver.driverId);
                          }}
                        />
                      </div>
                    ) : (
                      'Clocked-in'
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {deleteModal && (
        <ConfirmDeleteModal
          disabled={submitting}
          cancel={toggleDeleteModal}
          isOpen={deleteModal}
          remove={handleDriverRemove}
          id={driverId}
          action="remove"
          name="driver"
        />
      )}
    </div>
  );
};

export default ShiftDriverTable;
