const CONTRACT_URL = '/contract';

export const getContractsByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${CONTRACT_URL}/${fleetId}${filters}`;
  } else {
    return `${CONTRACT_URL}/${fleetId}`;
  }
};

export const createContract = () => {
  const url = `${CONTRACT_URL}`;
  return url;
};

export const updateContract = (id) => {
  const url = `${CONTRACT_URL}/${id}`;
  return url;
};

export const getContractByFleetId = () => {
  const url = `${CONTRACT_URL}`;
  return url;
};
