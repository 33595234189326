import { useContext, useEffect, useRef, useState } from 'react';

import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../Shared/api/axios';
import Button from '../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../Shared/hooks/useAuth';
import 'react-phone-number-input/style.css';
import { createAddress, updateAddress } from '../../api/services/AddressService';
import { DeliveryContext } from '../../contexts/DeliveryContext';

const AddressForm = ({ closeModal, getAddresses, type, address, withRefetch }) => {
  const [addressId, setAddressId] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [street, setStreet] = useState('');
  const [notes, setNotes] = useState('');
  const [postCode, setPostCode] = useState('');
  const [city, setCity] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(true);

  const [deleteModal, setDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const { setRefetch } = useContext(DeliveryContext);
  const { user } = useAuth();
  const errRef = useRef();
  const businessId = user?.businessId;

  const createAddressUrl = createAddress();
  const updateAddressUrl = updateAddress(addressId);

  // AUTOCOMPLETE INTEGRATION:

  // const { ref } = usePlacesWidget({
  //   apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  //   onPlaceSelected: (place) => {
  //     console.log(place);
  //     setPlace(place);
  //     setAddressInput(place.formatted_address);
  //   },
  //   options: {
  //     types: ['address'],
  //     componentRestrictions: { country: 'dk' },
  //     fields: ['formatted_address', 'address_components', 'geometry']
  //   }
  // });

  useEffect(() => {
    if (address) {
      setAddressId(address.id);
      setReceiverName(address.fullName);
      setBusinessName(address.businessName);
      setStreet(address.address);
      setNotes(address.notes);
      setPostCode(address.postCode);
      setCity(address.city);
      setPhoneNumber(address.phoneNumber);
      setEmail(address.email);
      setActive(address.active);
    }
  }, [address]);

  const clearFields = () => {
    setReceiverName('');
    setBusinessName('');
    setStreet('');
    // setApartment('');
    // setFloor('');
    setNotes('');
    setPostCode('');
    setCity('');
    setPhoneNumber('');
    setEmail('');
    setErrorMsg('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateAddressUrl, { active: !active });
      getAddresses();
      closeModal();
      toast.success('Status changed!');
    } catch (error) {
      toast.error('Something went wrong...');
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      businessId,
      fullName: receiverName,
      businessName,
      address: street,
      country: user?.countryName,
      countryCode: user?.countryCode,
      notes,
      postCode,
      city,
      phoneNumber,
      email
    };
    try {
      if (type === 'Edit') {
        const response = await axiosPrivate.put(updateAddressUrl, data);
        console.log(response);
        toast.success('Edited!');
      } else {
        const response = await axiosPrivate.post(createAddressUrl, data);
        console.log(response);
        toast.success('Created!');
      }
      getAddresses && getAddresses();
      clearFields();
      closeModal();
      if (withRefetch) {
        setRefetch(Math.random());
      }
    } catch (err) {
      // console.log(err);
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 422) {
        setErrorMsg('Could not geocode the address. Make sure it is valid.');
      } else {
        setErrorMsg('Failed');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-8 gap-6">
                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="receiver" className="block text-sm font-medium text-gray-700">
                    Receiver Name*
                  </label>
                  <input
                    type="text"
                    name="receiver"
                    id="receiver"
                    onChange={(e) => setReceiverName(e.target.value)}
                    value={receiverName}
                    autoComplete="receiver"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="business" className="block text-sm font-medium text-gray-700">
                    Business Name (if any)
                  </label>
                  <input
                    type="text"
                    name="business"
                    id="business"
                    onChange={(e) => setBusinessName(e.target.value)}
                    value={businessName}
                    autoComplete="business"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Phone number*
                  </label>
                  <div className="mt-1">
                    <PhoneInput international defaultCountry={user.countryCode ?? 'DK'} value={phoneNumber} onChange={setPhoneNumber} />
                  </div>
                </div>

                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address (Street)*
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={(e) => setStreet(e.target.value)}
                    value={street}
                    autoComplete="address"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-2">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City*
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    autoComplete="city"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-2">
                  <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
                    Postal code*
                  </label>
                  <input
                    type="number"
                    name="postCode"
                    id="postCode"
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postCode}
                    autoComplete="postCode"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-4">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                    Notes
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    id="notes"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    autoComplete="notes"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-8 sm:col-span-2">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-400">
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    disabled
                    // onChange={(e) => setCountry(e.target.value)}
                    value={user?.countryName}
                    autoComplete="country"
                    className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  disabled={submitting}
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                />
                <Button
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={!receiverName.length || !phoneNumber || !street.length || !city.length || !postCode.length || submitting}
                />
                {type === 'Edit' && (
                  <Button
                    disabled={submitting}
                    text={active ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && addressId !== null && (
          <ConfirmDeleteModal
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={addressId}
            disabled={submitting}
            action={active ? 'deactivate' : 'activate'}
            name="address"
          />
        )}
      </div>
    </>
  );
};

export default AddressForm;
