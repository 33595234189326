import { useEffect, useRef, useState } from 'react';

import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../Shared/api/axios';
import Button from '../../../Shared/components/Button';
import 'react-phone-number-input/style.css';
import ConfirmDeleteModal from '../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../Shared/hooks/useAuth';
import { createBranch, updateBranch } from '../../api/services/BranchService';

const BranchesForm = ({ closeModal, getBranches, type, branch }) => {
  const [branchId, setBranchId] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [notes, setNotes] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [active, setActive] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');

  const { user } = useAuth();
  const errRef = useRef();
  const businessId = user?.businessId;

  const createBranchUrl = createBranch();
  const updateBranchUrl = updateBranch(branchId);

  useEffect(() => {
    if (branch) {
      setBranchId(branch.id);
      setName(branch.name);
      setAddress(branch.address);
      setCity(branch.city);
      setPostCode(branch.postCode);
      setNotes(branch.notes);
      setPhoneNumber(branch.phoneNumber);
      setEmail(branch.email);
      setActive(branch.active);
    }
  }, [branch]);

  const clearFields = () => {
    setName('');
    setAddress('');
    setCity('');
    setPostCode('');
    setNotes('');
    setPhoneNumber('');
    setEmail('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateBranchUrl, { active: !active });
      getBranches();
      closeModal();
      toast.success('Status changed!');
    } catch (error) {
      toast.error('Something went wrong...');
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = { name, address, city, postCode, country: user.countryName, notes, phoneNumber, email, businessId, countryCode: user?.countryCode };
    try {
      if (type === 'Edit') {
        const response = await axiosPrivate.put(updateBranchUrl, data);
        console.log(response);
        toast.success('Edited!');
        getBranches();
      } else {
        const response = await axiosPrivate.post(createBranchUrl, data);
        console.log(response);
        toast.success('Created!');
        getBranches();
      }
      clearFields();
      closeModal();
    } catch (err) {
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 422) {
        setErrorMsg('Could not geocode the address. Make sure it is valid.');
      } else {
        setErrorMsg('Failed');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-3 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center mb-1' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="branch-name" className="block text-sm font-medium text-gray-700">
                    Branch Name*
                  </label>
                  <input
                    type="text"
                    name="branch-name"
                    id="branch-name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    autoComplete="branch-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                    Phone number*
                  </label>
                  <div className="mt-1">
                    <PhoneInput international defaultCountry={user.countryCode ?? 'DK'} value={phoneNumber} onChange={setPhoneNumber} />
                  </div>
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete="email"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address*
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={(e) => {
                      setAddress(e.target.value), setErrorMsg('');
                    }}
                    value={address}
                    autoComplete="address"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City*
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    autoComplete="city"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
                    Postal code*
                  </label>
                  <input
                    type="number"
                    name="postCode"
                    id="postCode"
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postCode}
                    autoComplete="postCode"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="country" className="block text-sm font-medium text-gray-400">
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    id="country"
                    disabled
                    value={user?.countryName}
                    autoComplete="country"
                    className="block w-full mt-1 text-gray-400 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                    Notes
                  </label>
                  <input
                    type="text"
                    name="notes"
                    id="notes"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    autoComplete="notes"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  disabled={submitting}
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                />
                <Button
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                  disabled={submitting || !name.length || !address.length || !city.length || !postCode.length || !phoneNumber?.length}
                />
                {type === 'Edit' && (
                  <Button
                    text={active ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    disabled={submitting}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && branchId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={branchId}
            action={active ? 'deactivate' : 'activate'}
            name="branch"
          />
        )}
      </div>
    </>
  );
};

export default BranchesForm;
