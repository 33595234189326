import { useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../Shared/components/Button';
import InfoModal from '../../../Shared/components/Modals/InfoModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { createApiKey } from '../../api/services/ApiKeyService';

const ApiControlForm = ({ closeModal, getApiKeys }) => {
  const [testKey, setTestKey] = useState(false);
  const [keyName, setKeyName] = useState('');
  const [createdKey, setCreatedKey] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const createApiKeyUrl = createApiKey();
  const { user } = useAuth();

  const businessId = user?.businessId;

  const clearFields = () => {
    setKeyName('');
    setTestKey(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      testKey,
      name: keyName,
      businessId
    };
    try {
      const response = await axiosPrivate.post(createApiKeyUrl, data);
      console.log('KEY', response.data);
      setCreatedKey(response.data?.apiKey);
      toast.success('API key created!');
      toggleInfoModal();
      getApiKeys();
      // clearFields();
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong...');
    }
    setSubmitting(false);
  };

  const toggleFlexibleShift = () => {
    setTestKey(!testKey);
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  return (
    <div className="w-full mt-2">
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-4 gap-6">
              <div className="flex items-center justify-center col-span-8 lg:justify-start lg:col-span-2">
                <input
                  name="Test Key"
                  type="checkbox"
                  id="testKey"
                  onChange={() => toggleFlexibleShift()}
                  checked={testKey}
                  className="w-4 h-4 border-gray-300 rounded text-blue focus:ring-0 hover:cursor-pointer"
                />
                <div className="flex items-center space-x-1">
                  <label htmlFor="flexibleShift" className="block ml-2 text-sm text-gray-900 hover:cursor-pointer">
                    Test Key
                  </label>
                </div>
              </div>
              <div className="col-span-4 sm:col-span-4">
                <label htmlFor="keyName" className="block text-sm font-medium text-gray-700">
                  Key name*
                </label>
                <input
                  type="text"
                  name="keyName"
                  id="keyName"
                  value={keyName}
                  onChange={(e) => setKeyName(e.target.value)}
                  autoComplete="keyName"
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                />
              </div>
            </div>
            <div className="flex justify-center mt-5">
              <Button text="Create Key" type="submit" rounded disabled={!keyName.length || submitting} />
            </div>
          </div>
        </div>
      </form>
      {infoModal && (
        <InfoModal
          isOpen={infoModal}
          close={() => {
            toggleInfoModal();
            clearFields();
            closeModal();
          }}
          text={`The following information is only going to be visible now. Please take note of this information as you will not be able to get this data again.`}
          secondaryText="Your newly created API key:"
          importantText={createdKey}
        />
      )}
    </div>
  );
};

export default ApiControlForm;
