import React from 'react';

import { STEPS } from '../../../../utils/constants/delivery-steps.js';

const DeliveryStepper = ({ activeStep }) => {
  return (
    <ol className="flex items-center justify-between w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white border border-gray-200 rounded-lg shadow-sm sm:text-base sm:p-4 sm:space-x-4 rtl:space-x-reverse">
      {STEPS.map((step, index) => (
        <React.Fragment key={step.id}>
          <li className="flex items-center">
            <span
              className={`flex items-center justify-center w-5 h-5 mr-2 text-xs border rounded-full me-2 shrink-0 ${
                activeStep > step.id
                  ? 'bg-blue text-white border-blue'
                  : activeStep === step.id
                  ? 'border-blue text-blue'
                  : 'bg-gray-400 text-white border-gray-300'
              }`}>
              {step.id}
            </span>
            {step.name}
          </li>
          {index < STEPS.length - 1 && <div className={`flex-grow h-1 ${activeStep > step.id ? 'bg-blue' : 'bg-gray-200'}`} />}
        </React.Fragment>
      ))}
    </ol>
  );
};

export default DeliveryStepper;
