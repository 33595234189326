import { useState } from 'react';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../../Shared/components/Pills/StatusPill';
import AdvancedBonusForm from './Advanced/AdvancedBonusForm';
import BonusForm from './BonusForm';

const BonusTable = ({ bonuses, loading, error, getBonuses, advanced = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bonus, setBonus] = useState({});

  const basicHeaders = [
    { label: 'Bonus Name', key: 1 },
    { label: 'Criteria', key: 2 },
    { label: 'Goal', key: 3 },
    { label: 'Bonus Amount', key: 4 },
    { label: 'Drivers assigned', key: 5 },
    { label: 'Active', key: 6 },
    { label: 'Manage', key: 7 }
  ];

  const advancedHeaders = [
    { label: 'Bonus Name', key: 1 },
    { label: 'Criteria', key: 2 },
    { label: 'Drivers assigned', key: 3 },
    { label: 'Active', key: 4 },
    { label: 'Manage', key: 5 }
  ];

  const renderHeaders = () => {
    if (advanced) {
      return advancedHeaders;
    }
    return basicHeaders;
  };

  const headers = renderHeaders();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-4 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {bonuses &&
              bonuses.map((bonus) => (
                <tr key={bonus.id} className="bg-white border-b ">
                  <th scope="row" className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {bonus.name}
                  </th>
                  <td className="px-4 py-4">{bonus.criteria}</td>
                  {!advanced && <td className="px-4 py-4">{bonus.goal}</td>}
                  {!advanced && <td className="px-4 py-4">{bonus.amount}</td>}
                  <td className="px-4 py-4">{bonus.driverCount}</td>
                  <td className="px-2 py-4">
                    <StatusPill status={bonus.active} />
                  </td>
                  <td className="px-4 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        rounded
                        bg="bg-blue"
                        border="border-blue"
                        onClick={() => {
                          setBonus(bonus), toggleModal();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title={advanced ? 'Edit Advanced Bonus' : 'Edit Bonus'}
          body={
            advanced ? (
              <AdvancedBonusForm closeModal={toggleModal} getBonuses={getBonuses} type="Edit" bonus={bonus} advanced={advanced} />
            ) : (
              <BonusForm closeModal={toggleModal} getBonuses={getBonuses} type="Edit" bonus={bonus} advanced={advanced} />
            )
          }
        />
      )}
    </div>
  );
};

export default BonusTable;
