import React, { Fragment, useState, useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Loader from '../../../../Shared/components/Loader';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { createPaymentIntent } from '../../../api/services/PaymentService';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PurchaseDetails = ({ purchase, priceWithoutVat }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState('');

  const createPaymentIntentUrl = createPaymentIntent();

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    createNewPaymentIntent();
  }, []);

  const createNewPaymentIntent = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.post(createPaymentIntentUrl, purchase);
      setClientSecret(response.data.clientSecret);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="flex justify-between w-full">
          <p>Base Fee:</p>
          <p>
            {formatNumber(priceWithoutVat)} {purchase.currency}
          </p>
        </div>
        <div className="flex justify-between w-full">
          <p>VAT (25%):</p>
          <p>
            {formatNumber(priceWithoutVat * 0.25)} {purchase.currency}
          </p>
        </div>
        <div className="flex justify-between w-full font-bold text-md">
          <p className="">Total:</p>
          <p>
            {purchase?.price} {purchase.currency}
          </p>
        </div>
      </div>
      <hr className="w-full mx-auto my-5 bg-gray-200 border border-gray-200" />
      {loading ? (
        <div className="flex justify-center my-20">
          <Loader />
        </div>
      ) : (
        clientSecret &&
        purchase && (
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm purchase={purchase} totalPrice={purchase?.price} currency={purchase.currency} />
          </Elements>
        )
      )}
    </>
  );
};

export default PurchaseDetails;
