import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import DeliveryHistoryTable from '../../../Business/components/Deliveries/DeliveryHistory.jsx/DeliveryHistoryTable';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import CityFilter from '../../../Shared/components/Filters/CityFilter';
import DateSelectorFilter from '../../../Shared/components/Filters/DateSelectorFilter';
import Heading from '../../../Shared/components/Heading';
import Tooltip from '../../../Shared/components/Tooltip';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDeliveryHistoryByFleetId } from '../../api/services/StatisticsService';

const FleetDeliveryHistory = () => {
  // const lastMonth = dayjs().subtract(1, 'month').startOf('month').toDate();
  // const today = dayjs().endOf('month').toDate();
  const lastMonth = dayjs().startOf('month').toDate();
  const today = dayjs().endOf('month').toDate();
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState('');
  const [deliveryHistory, setDeliveryHistory] = useState([]);

  const [from, setFrom] = useState(lastMonth);
  const [to, setTo] = useState(today);

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const deliveryHistoryUrl = getDeliveryHistoryByFleetId(fleetId, search);

  useEffect(() => {
    setHistoryError('');
    console.log(search);
  }, [search, from, to]);

  useEffect(() => {
    getFleetDeliveryHistory();
  }, [search, from, to]);

  const getFleetDeliveryHistory = async () => {
    setHistoryLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(deliveryHistoryUrl, { params: { startDate, endDate } });
      if (response.data.length === 0) {
        setHistoryError('No orders found with this criteria');
      }
      console.log('FLEET DELIVERY HISTORY', response.data);
      setDeliveryHistory(response.data);
      setHistoryLoading(false);
    } catch (error) {
      console.log(error);
      setHistoryLoading(false);
      setHistoryError('Something went wrong while retrieving statistics');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="mt-10 mb-2">
        <div className="flex flex-col md:items-center md:flex-row md:justify-between">
          <div className="flex items-center space-x-2">
            <Heading text={`Delivery History (${deliveryHistory?.length})`} />
            <Tooltip message={`History of deliveries & performance data`}>
              <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
            </Tooltip>
          </div>
          <div className="flex flex-col space-y-2 md:items-center md:flex-row md:space-y-0 md:space-x-5">
            <CityFilter fleetId={fleetId} withCityId={true} />
            <DateSelectorFilter startDate={from} endDate={to} setStartDate={setFrom} setEndDate={setTo} withSearch={false} withLabels={true} />
          </div>
        </div>
      </div>
      <div className="">
        <DeliveryHistoryTable loading={historyLoading} error={historyError} deliveries={deliveryHistory} />
      </div>
    </div>
  );
};

export default FleetDeliveryHistory;
