import { useContext, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import ReactDatePicker from 'react-datepicker';

import { getCitiesByFleetId } from '../../../../../Fleet/api/services/CityService';
import DynamicIcon from '../../../../../Shared/components/DynamicIcon';
import RadioElement from '../../../../../Shared/components/FormElements/RadioElement';
import Tooltip from '../../../../../Shared/components/Tooltip';
import { endOfDay, startOfDay } from '../../../../../Shared/helpers/date.js';
import useAuth from '../../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../../Shared/hooks/useAxiosPrivate';
import { getConnectedBranchesByBusinessId } from '../../../../api/services/BranchService';
import { DeliveryContext } from '../../../../contexts/DeliveryContext';

const BasicPickupForm = () => {
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState('');
  const [pickupTime, setPickupTime] = useState('');
  const [deliveryTime, setDeliveryTime] = useState('');
  const [weight, setWeight] = useState('');
  const [notes, setNotes] = useState('');
  const [packageCount, setPackageCount] = useState('');
  const [fleetId, setFleetId] = useState('');
  const [cityFleet, setCityFleet] = useState('');
  const [fleetCities, setFleetCities] = useState([]);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState('ASAP');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { submitted, setSubmitted, setBasicPickupDetails, pickupType } = useContext(DeliveryContext);

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const rightNow = new Date();

  const id = user?.businessId;
  const connectedBranchUrl = getConnectedBranchesByBusinessId(id);
  const fleetCitiesUrl = getCitiesByFleetId(fleetId, '?active=true');

  useEffect(() => {
    setError('');
    getConnectedBranches();
  }, []);

  useEffect(() => {
    if (fleetId) {
      getFleetCities();
    }
  }, [fleetId]);

  useEffect(() => {
    if (submitted) clearFields();
  }, [submitted]);

  useEffect(() => {
    setSubmitted(false);

    if (
      branch !== '' &&
      cityFleet !== '' &&
      pickupTime !== '' &&
      weight !== '' &&
      packageCount !== '' &&
      ((selectedDeliveryOption === 'Later' && deliveryTime !== '') || selectedDeliveryOption === 'ASAP') &&
      !dayjs(pickupTime).isAfter(deliveryTime) &&
      dayjs(pickupTime).isAfter(dayjs())
    ) {
      setBasicPickupDetails({
        branch,
        cityFleetId: cityFleet?.id,
        pickupTime,
        deliveryTime: selectedDeliveryOption === 'Later' ? deliveryTime : dayjs(pickupTime).add(15, 'minutes').toDate(),
        weight,
        notes: notes,
        packageCount
      });
    } else {
      setBasicPickupDetails({});
    }
  }, [branch, cityFleet, pickupTime, deliveryTime, weight, packageCount, selectedDeliveryOption, notes]);

  const clearFields = () => {
    setBranch('');
    setCityFleet('');
    setPickupTime('');
    setDeliveryTime('');
    setWeight('');
    setNotes('');
    setPackageCount('');
  };

  const getFleetCities = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(fleetCitiesUrl);
      setLoading(false);
      setFleetCities(response.data);
      if (response.data.length === 0) {
        setError('No cities found');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getConnectedBranches = async () => {
    try {
      const response = await axiosPrivate.get(connectedBranchUrl);
      if (response.data.length === 0) {
        setError('No Branches have been created');
      } else {
        setBranches(response.data);
        setError('');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const handleBranchSelect = (value) => {
    if (value === '') {
      setBranch('');
      setFleetId('');
    } else {
      const selected = branches?.find((item) => item?.id == value);

      setBranch(selected);
      setFleetId(selected?.fleetId);
    }
  };

  const handleFleetCitySelect = (value) => {
    if (value === '') {
      setCityFleet('');
    } else {
      const selected = fleetCities?.find((item) => item?.id == value);
      setCityFleet(selected);
    }
  };

  const filterStartTime = (time) => {
    const currentDate = dayjs().add(25, 'minute');
    const selectedDate = dayjs(time);

    return currentDate.isBefore(selectedDate);
  };

  const filterEndTime = (time) => {
    const currentDate = pickupTime ? dayjs(pickupTime).add(15, 'minute') : dayjs();
    const selectedDate = dayjs(time);

    return currentDate.isBefore(selectedDate);
  };

  return (
    <div className="w-full">
      <form>
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 sm:p-6">
            <div className="grid grid-cols-4 gap-6">
              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <div className="flex w-2/5 space-x-1 whitespace-nowrap ">
                  <label htmlFor="branch" className={`text-sm font-bold ${!branch.name ? 'text-red-500' : 'text-gray-700'} min-w-fit`}>
                    {pickupType === 'custom' ? 'Select branch*' : 'Pick-up branch*'}
                  </label>
                  {pickupType === 'custom' && (
                    <Tooltip message={`Select a branch that is connected to fleet`}>
                      <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
                    </Tooltip>
                  )}
                </div>
                <select
                  name="branch"
                  id="branch"
                  autoComplete="branch"
                  onChange={(e) => handleBranchSelect(e.target.value)}
                  value={branch?.id || ''}
                  className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm cursor-pointer focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                  <option value={''}>Select Branch</option>
                  {branches &&
                    branches.map((branch) => (
                      <option value={branch.id} key={branch.id}>
                        {branch.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="cityFleet" className={`block w-2/5 text-sm font-bold ${!cityFleet.id ? 'text-red-500' : 'text-gray-700'} min-w-fit`}>
                  Fleet city*
                </label>
                <select
                  name="cityFleet"
                  id="cityFleet"
                  autoComplete="cityFleet"
                  disabled={fleetCities.length === 0 || fleetId === ''}
                  onChange={(e) => handleFleetCitySelect(e.target.value)}
                  value={cityFleet?.id || ''}
                  className={`${
                    fleetId === '' ? 'text-gray-400 cursor-not-allowed' : 'cursor-pointer'
                  } mt-1 block w-full py-2 px-3 border cursor-pointer border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm`}>
                  <option value={''}>{fleetId === '' ? 'Select Branch First' : 'Select Fleet City'}</option>
                  {fleetCities &&
                    fleetCities.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="date-picker" className={`block w-2/5 text-sm font-bold ${!pickupTime ? 'text-red-500' : 'text-gray-700'} min-w-fit`}>
                  Pick-up date/time*
                </label>
                <ReactDatePicker
                  wrapperClassName="datePicker"
                  className="date-picker"
                  required
                  minDate={rightNow}
                  filterTime={filterStartTime}
                  selected={pickupTime}
                  onChange={(date) => setPickupTime(date)}
                  placeholderText="Select date & time"
                  timeIntervals={5}
                  timeFormat="HH:mm"
                  showTimeSelect
                  dateFormat="MMMM d, yyyy HH:mm"
                />
              </div>

              <div className="flex col-span-4 space-x-3 text-left sm:col-span-4">
                <label
                  htmlFor="email-address"
                  className={`block w-2/5 text-sm font-bold ${
                    !deliveryTime && selectedDeliveryOption === 'Later' ? 'text-red-500' : 'text-gray-700'
                  } min-w-fit`}>
                  Delivery date/time*
                </label>
                <div className="flex flex-col justify-start w-full">
                  <RadioElement
                    labelText="ASAP"
                    value="ASAP"
                    checked={selectedDeliveryOption === 'ASAP'}
                    onChange={(e) => {
                      setSelectedDeliveryOption(e.target.value);
                      setDeliveryTime('');
                    }}
                    id="asap"
                    name="asap"
                    big
                  />
                  <div className="flex w-full space-x-2">
                    <RadioElement
                      labelText="Choose time"
                      value="Later"
                      checked={selectedDeliveryOption === 'Later'}
                      onChange={(e) => setSelectedDeliveryOption(e.target.value)}
                      id="asap"
                      name="asap"
                      big
                    />
                    <ReactDatePicker
                      wrapperClassName="datePicker"
                      className="date-picker"
                      required
                      minDate={pickupTime}
                      minTime={startOfDay(rightNow)}
                      maxTime={endOfDay(rightNow)}
                      disabled={selectedDeliveryOption !== 'Later'}
                      placeholderText={selectedDeliveryOption === 'Later' ? 'Select date & time' : ''}
                      selected={deliveryTime}
                      onChange={(date) => setDeliveryTime(date)}
                      filterTime={filterEndTime}
                      timeIntervals={5}
                      timeFormat="HH:mm"
                      showTimeSelect
                      dateFormat="MMMM d, yyyy HH:mm"
                    />
                  </div>
                </div>
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="weight" className={`block w-2/5 text-sm font-bold ${!weight ? 'text-red-500' : 'text-gray-700'} min-w-fit`}>
                  Approx weight. (kg)*
                </label>
                <input
                  type="number"
                  name="weight"
                  id="weight"
                  min={0}
                  autoComplete="weight"
                  placeholder='e.g. "1.5"'
                  onChange={(e) => setWeight(e.target.value)}
                  value={weight}
                  className="block w-full mt-1 placeholder-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-blue focus:border-blue"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="packageCount" className={`block w-2/5 text-sm font-bold ${!packageCount ? 'text-red-500' : 'text-gray-700'} min-w-fit`}>
                  Nr. of packages*
                </label>
                <input
                  type="number"
                  name="packageCount"
                  id="packageCount"
                  autoComplete="packageCount"
                  placeholder='e.g. "1"'
                  min={1}
                  onChange={(e) => setPackageCount(e.target.value)}
                  value={packageCount}
                  className="block w-full mt-1 placeholder-gray-400 border-gray-300 rounded-md shadow-sm sm:text-sm focus:ring-blue focus:border-blue"
                />
              </div>

              <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                <label htmlFor="notes" className="block w-2/5 text-sm font-bold text-gray-700 min-w-fit">
                  Notes
                </label>
                <textarea
                  type="text"
                  name="notes"
                  id="notes"
                  placeholder='e.g. "Call me when you arrive"'
                  onChange={(e) => setNotes(e.target.value)}
                  value={notes}
                  autoComplete="notes"
                  className="block w-full mt-1 placeholder-gray-400 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="flex items-center px-4 py-5 space-x-2 sm:p-6">
            <div className="w-8 h-2 bg-red-500 rounded-full mt-0.5"></div>
            <p className="text-sm text-gray-700"> - incomplete required field</p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default BasicPickupForm;
