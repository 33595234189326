import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import Heading from '../../../Shared/components/Heading';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getSalaries } from '../../api/services/StatisticsService';
import SalaryTable from '../../components/Drivers/Salaries/SalaryTable';

const Salaries = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const lastMonth = dayjs().subtract(1, 'month').startOf('day').toDate();
  const today = dayjs().endOf('day').toDate();

  const [from, setFrom] = useState(lastMonth);
  const [to, setTo] = useState(today);
  const [salaryData, setSalaryData] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const salariesUrl = getSalaries(fleetId);

  useEffect(() => {
    setError('');
    getSalaryForDrivers();
  }, [from, to]);

  const getSalaryForDrivers = async () => {
    setLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(salariesUrl, { params: { startDate, endDate } });
      if (response.data.length === 0) {
        setError('No data found');
      } else {
        setSalaryData(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong');
      setError('Something went wrong');
    }
  };

  return (
    <>
      <div className="flex flex-col items-baseline mt-5 sm:items-center sm:flex-row sm:justify-between">
        <Heading text="Salaries" />
        <div className="flex flex-col mt-2 space-y-2 sm:mt-0 sm:space-y-0 sm:space-x-8 sm:flex-row">
          <div className="flex items-baseline space-x-2 sm:items-center">
            <h2 className="mr-2">From:</h2>
            <DatePicker
              wrapperClassName="datePicker"
              className="calendar"
              required
              placeholderText="Select date"
              selected={from}
              onChange={(date) => {
                setFrom(date);
                console.log(date);
              }}
              dateFormat="MMMM d, yyyy"
            />
          </div>
          <div className="flex items-baseline space-x-2 sm:items-center">
            <h2 className="mr-2">To:</h2>
            <DatePicker
              wrapperClassName="datePicker"
              className="calendar"
              required
              placeholderText="Select date"
              selected={to}
              onChange={(date) => setTo(date)}
              dateFormat="MMMM d, yyyy"
            />
          </div>
        </div>
      </div>
      <SalaryTable salaries={salaryData} loading={loading} error={error} />
    </>
  );
};

export default Salaries;
