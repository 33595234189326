import { useState } from 'react';

import Button from '../../../../../Shared/components/Button';
import BaseModal from '../../../../../Shared/components/Modals/BaseModal';
import PricingCriteriaFormEdit from '../PricingCriteriaFormEdit';

const BaseFee = ({ basePricing, getPricing, cityId, branchId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fee, setFee] = useState('');

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2 className="font-bold text-gray-500 text-md">Criteria 1</h2>
        <div className="px-5 py-2 my-2 text-center border border-gray-300 rounded-full">Base fee</div>
        {basePricing?.length > 0 ? (
          <div className="flex flex-col items-center min-h-max">
            {basePricing.map((item) => (
              <small className="mt-2" key={item.id}>
                {item.price} Credits
              </small>
            ))}
          </div>
        ) : (
          <small className="mt-2 text-blue">not registered</small>
        )}
        <div className="mt-2">
          <Button
            text="Edit"
            small
            rounded
            bg="bg-gray-500"
            border="border-gray-500"
            onClick={() => {
              setFee(basePricing[0]?.price);
              toggleModal();
            }}
          />
        </div>
      </div>
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Base Fee"
          body={
            <PricingCriteriaFormEdit
              type="Base"
              closeModal={toggleModal}
              fee={fee}
              pricingItems={basePricing}
              getPricing={getPricing}
              basePricing={basePricing}
              cityId={cityId}
              branchId={branchId}
            />
          }
        />
      )}
    </>
  );
};

export default BaseFee;
