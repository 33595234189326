import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import DeliveryChart from '../../../Shared/components/Charts/DeliveryChart';
import SalesChart from '../../../Shared/components/Charts/SalesChart';
import CityFilter from '../../../Shared/components/Filters/CityFilter';
import DateSelectorFilter from '../../../Shared/components/Filters/DateSelectorFilter';
import Heading from '../../../Shared/components/Heading';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getComparedStatsByFleetId, getStatsByFleetId } from '../../api/services/StatisticsService';
import QuickAnalytics from '../../components/Sales/SalesAnalytics/QuickAnalytics';
import SalesNumberCards from '../../components/Sales/SalesAnalytics/SalesNumberCards';

const SalesAnalytics = () => {
  const lastMonth = dayjs().subtract(1, 'month').startOf('day').toDate();
  const today = dayjs().endOf('day').toDate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [statisticsError, setStatisticsError] = useState('');

  const [statistics, setStatistics] = useState([]);
  const [comparedStatistics, setComparedStatistics] = useState([]);
  const [from, setFrom] = useState(lastMonth);
  const [to, setTo] = useState(today);

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const statisticsUrl = getStatsByFleetId(fleetId, search);
  const comparedStatsUrl = getComparedStatsByFleetId(fleetId, search);

  useEffect(() => {
    setError('');
    setStatisticsError('');
    getComparedStatistics();
  }, [search]);

  useEffect(() => {
    setStatisticsError('');
    getStatistics();
  }, [search, from, to]);

  const getStatistics = async () => {
    setStatisticsLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(statisticsUrl, { params: { startDate, endDate } });
      // console.log('STATISTICS', response.data);
      setStatistics(response.data);
      setStatisticsLoading(false);
    } catch (error) {
      console.log(error);
      setStatisticsLoading(false);
      setStatisticsError('Something went wrong while retrieving statistics');
    }
  };

  const getComparedStatistics = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(comparedStatsUrl);
      console.log('COMPARISON STATS', response.data);
      if (response.data.length === 0) {
        setError('No Stats found');
      } else if (response.data.todayStats.orders === 0) {
        setError('No orders today');
      } else {
        setComparedStatistics(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong');
    }
  };

  return (
    <div>
      <QuickAnalytics
        loading={loading}
        error={error}
        today={comparedStatistics.todayStats}
        lastWeek={comparedStatistics.lastWeekStats}
        lastMonth={comparedStatistics.lastMonthStats}
      />
      <div className="grid grid-cols-12 gap-4 p-5 mt-10 rounded-2xl bg-gray-50">
        <div className="grid col-span-12 xl:col-span-4">
          <div className="flex flex-col justify-between row-span-1">
            <Heading text="In a glance" />
            <CityFilter fleetId={fleetId} withCityId={true} />
            <div className="mt-2">
              <DateSelectorFilter startDate={from} endDate={to} setStartDate={setFrom} setEndDate={setTo} withSearch={false} />
            </div>
          </div>
          <div className="">
            <SalesNumberCards total={statistics.salesTotal} base={statistics.baseTotal} distance={statistics.distanceTotal} weight={statistics.weightTotal} />
          </div>
        </div>
        <div className="w-full h-full col-span-12 p-2 bg-white lg:col-span-6 xl:col-span-4 standard-border">
          <DeliveryChart title="Total Deliveries" label="Deliveries" data={statistics.countArray} loading={statisticsLoading} error={statisticsError} />
        </div>
        <div className="w-full h-full col-span-12 p-2 bg-white lg:col-span-6 xl:col-span-4 standard-border">
          <SalesChart title="Total Sales" label="Sales" data={statistics.sumArray} loading={statisticsLoading} error={statisticsError} />
        </div>
      </div>
    </div>
  );
};

export default SalesAnalytics;
