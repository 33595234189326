import React, { useEffect, useState } from 'react';

import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';

export default function CheckoutForm({ totalPrice, currency }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setMessage('');

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${process.env.REACT_APP_CLIENT_URL}/business/credits/history`
        // return_url: 'http://localhost:3000/business/credits/history'
      }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
      console.log(error);
    } else {
      setMessage('An unexpected error occurred, please try again...');
    }

    setIsLoading(false);
    setSubmitting(false);
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        {stripe && elements && (
          <div className="w-full mt-3">
            <Button
              width="w-full"
              disabled={isLoading || !stripe || !elements || submitting}
              text={`Pay ${formatNumber(totalPrice)} ${currency}`}
              type="submit">
              {isLoading && <Loader small />}
            </Button>
            <p className="mt-4">
              <strong>Important:</strong> You will be redirected to a secure payment page to complete your purchase.
            </p>
          </div>
        )}
        {/* Show any error or success messages */}
        {message && <div className="mx-2 mt-3 font-bold text-center text-red-400">{message}</div>}
      </form>
    </>
  );
}
