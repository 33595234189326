const WITHDRAWAL_URL = '/withdrawals';

export const getWithdrawableAvailableCredits = (fleetId) => {
  const url = `${WITHDRAWAL_URL}/credits/${fleetId}`;
  return url;
};

export const getWithdrawalsByFleetId = (fleetId) => {
  const url = `${WITHDRAWAL_URL}/history/${fleetId}`;
  return url;
};

export const postWithdrawal = () => {
  const url = `${WITHDRAWAL_URL}/withdraw`;
  return url;
};
