import Loader from '../../../Shared/components/Loader';

const OverviewInNumbers = ({ loading, error, stats }) => {
  console.log(stats);
  return (
    <div className="w-full p-5 bg-white standard-border">
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <div className="flex flex-wrap justify-center text-center gap-x-10 gap-y-4">
          <div className="flex flex-col items-center space-y-3 text-center">
            <p className="text-lg text-gray-600 uppercase">total deliveries</p>
            <div className="flex items-center justify-center w-24 h-24 text-2xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
              {stats?.ordersNumber || 0}
            </div>
          </div>
          <div className="flex flex-col items-center space-y-3 text-center">
            <p className="text-lg text-gray-600 uppercase">AVERAGE CREDITS/ORDER</p>
            <div className="flex items-center justify-center w-24 h-24 text-2xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
              {stats?.avgFee ? stats.avgFee.toFixed(1) : 0}
            </div>
          </div>
          <div className="flex flex-col items-center space-y-3 text-center">
            <p className="text-lg text-gray-600 uppercase">TOTAL CREDITS SPENT</p>
            <div className="flex items-center justify-center w-24 h-24 text-2xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
              {stats?.totalFee ? stats.totalFee.toFixed(1) : 0}
            </div>
          </div>
          <div className="flex flex-col items-center space-y-3 text-center">
            <p className="text-lg text-gray-600 uppercase">AVERAGE DISTANCE (km)</p>
            <div className="flex items-center justify-center w-24 h-24 text-2xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
              {stats?.avgDistance ? stats.avgDistance.toFixed(1) : 0}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverviewInNumbers;
