import React from 'react';

const DeliveryStats = ({ today, lastWeek, lastMonth }) => {
  return (
    <div className="p-5 bg-white border border-gray-200 rounded-2xl">
      <div className="flex flex-wrap justify-center text-center gap-x-10 gap-y-4">
        <div className="flex flex-col items-center">
          <p className="my-2">Deliveries Today</p>
          <div className="flex items-center justify-center w-24 h-24 text-3xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">{today}</div>
        </div>
        <div className="flex flex-col items-center">
          <p className="my-2">Previous Week</p>
          <div className="flex items-center justify-center w-24 h-24 text-3xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
            {lastWeek}
          </div>
        </div>
        <div className="flex flex-col items-center">
          <p className="my-2">Previous Month</p>
          <div className="flex items-center justify-center w-24 h-24 text-3xl font-bold bg-white border-2 border-gray-300 rounded-full text-blue">
            {lastMonth}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryStats;
