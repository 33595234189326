import { useEffect, useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getWithdrawableAvailableCredits, getWithdrawalsByFleetId } from '../../api/services/WithdrawalService';
// import PayoutAccount from '../../components/Sales/BalanceAndWithdrawal/PayoutAccount';
import PreviousWithdrawals from '../../components/Sales/BalanceAndWithdrawal/PreviousWithdrawals';
import WithdrawalRequest from '../../components/Sales/BalanceAndWithdrawal/WithdrawalRequest';

const BalanceAndWithdraw = () => {
  const [loadingBalance, setLoadingBalance] = useState(false);
  const [loadingWithdrawals, setLoadingWithdrawals] = useState(false);
  const [creditBalance, setCreditBalance] = useState('');
  const [previousWithdrawals, setPreviousWithdrawals] = useState([]);
  const [balanceError, setBalanceError] = useState('');
  const [withdrawalError, setWithdrawalError] = useState('');

  const { user } = useAuth();
  const fleetId = user?.fleetId;
  const axiosPrivate = useAxiosPrivate();

  const creditsUrl = getWithdrawableAvailableCredits(fleetId);
  const previousWithdrawalsUrl = getWithdrawalsByFleetId(fleetId);

  useEffect(() => {
    getWithdrawableCredits();
    getPreviousWithdrawals();
  }, []);

  const getWithdrawableCredits = async () => {
    setLoadingBalance(true);
    try {
      const response = await axiosPrivate.get(creditsUrl);
      console.log('AV CREDS', response.data);
      setCreditBalance(response.data);
      setLoadingBalance(false);
    } catch (error) {
      console.log(error);
      setBalanceError('Something went wrong');
      setLoadingBalance(false);
    }
  };

  const getPreviousWithdrawals = async () => {
    setLoadingWithdrawals(true);
    try {
      const response = await axiosPrivate.get(previousWithdrawalsUrl);
      // console.log(response.data);
      console.log('PREVIOUS WITHDRAWALS', response.data);
      if (response.data.length === 0) {
        setWithdrawalError('No withdrawal history');
      } else {
        setWithdrawalError('');
        setPreviousWithdrawals(response.data);
      }
      setLoadingWithdrawals(false);
    } catch (error) {
      console.log(error);
      setWithdrawalError('Something went wrong');
      setLoadingWithdrawals(false);
    }
  };

  return (
    <div className="grid justify-center w-full max-w-2xl grid-cols-12 gap-8 m-auto lg:max-w-6xl lg:grid-rows-6">
      <div className="col-span-12 lg:col-span-3 lg:row-span-6">
        {/* <Heading text="Balance" /> */}
        <WithdrawalRequest
          loadingBalance={loadingBalance}
          creditBalance={creditBalance}
          error={balanceError}
          getPreviousWithdrawal={getPreviousWithdrawals}
          getWithdrawableCredits={getWithdrawableCredits}
        />
      </div>
      {/* <div className="col-span-12 space-y-2 lg:col-span-9 lg:row-span-2">
        <h2>Payout Account</h2>
        <PayoutAccount />
      </div> */}
      <div className="col-span-12 lg:col-span-9 lg:row-span-4">
        <Heading text="Previous Withdrawals" />
        <PreviousWithdrawals previousWithdrawals={previousWithdrawals} loading={loadingWithdrawals} error={withdrawalError} />
      </div>
    </div>
  );
};

export default BalanceAndWithdraw;
