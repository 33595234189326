const STATISTICS_URL = '/statistics';

export const getSalaries = (fleetId) => {
  const url = `${STATISTICS_URL}/salaries/${fleetId}`;
  return url;
};

export const getCustomerStatsByFleetId = (fleetId) => {
  const url = `${STATISTICS_URL}/customers/${fleetId}`;
  return url;
};

export const getStatsByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${STATISTICS_URL}/fleet/${fleetId}${filters}`;
  } else {
    return `${STATISTICS_URL}/fleet/${fleetId}`;
  }
};

export const getComparedStatsByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${STATISTICS_URL}/compared/${fleetId}${filters}`;
  } else {
    return `${STATISTICS_URL}/compared/${fleetId}`;
  }
};

export const getDeliveryHistoryByFleetId = (fleetId, filters) => {
  if (filters) {
    return `${STATISTICS_URL}/deliveries/history/fleet/${fleetId}${filters}`;
  } else {
    return `${STATISTICS_URL}/deliveries/history/fleet/${fleetId}`;
  }
};
