import rules from './rbacRules';

const permissionCheck = (action, userRole, accountType) => {
  // console.log('action:', action, 'user role:', userRole, 'account type:', accountType);

  if (userRole === 'superadmin' || userRole === 'admin') {
    return true;
  }

  const existingRule = rules[userRole];

  if (!existingRule) {
    // role is not present in the rules
    return false;
  }

  const permissions = existingRule[accountType];

  // action = overview:see
  if (permissions && (permissions.includes(action) || permissions.includes(action.split(':')[0] + ':all'))) {
    // static rule not provided for action
    return true;
  }

  return false;
};

export default permissionCheck;
