import PropTypes from 'prop-types';

const Heading = ({ text, uppercase }) => {
  return <div className={`text-2xl text-blue font-bold ${uppercase ? 'uppercase' : ''}`}>{text}</div>;
};

Heading.defaultProps = {
  text: 'Heading Text'
};

Heading.propTypes = {
  text: PropTypes.string.isRequired
};

export default Heading;
