import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import DeliveryChart from '../../../Shared/components/Charts/DeliveryChart';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import DateSelectorFilter from '../../../Shared/components/Filters/DateSelectorFilter';
import Heading from '../../../Shared/components/Heading';
import SmallHeading from '../../../Shared/components/SmallHeading';
import Tooltip from '../../../Shared/components/Tooltip';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDeliveryHistoryByBusinessId, getStatsByBusinessId, getUserDeliveryStatsByBusinessId } from '../../api/services/StatisticsService';
import DeliveriesByUserHistory from '../../components/Deliveries/DeliveryHistory.jsx/DeliveriesByUserHistory';
import DeliveryHistoryTable from '../../components/Deliveries/DeliveryHistory.jsx/DeliveryHistoryTable';

const DeliveryHistory = () => {
  const lastMonth = dayjs().subtract(1, 'month').startOf('day').toDate();
  const today = dayjs().endOf('day').toDate();
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState('');
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [statisticsError, setStatisticsError] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState('');

  const [deliveryHistory, setDeliveryHistory] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [userStatistics, setUserStatistics] = useState([]);
  const [from, setFrom] = useState(lastMonth);
  const [to, setTo] = useState(today);

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const businessId = user?.businessId;
  const statisticsUrl = getStatsByBusinessId(businessId, search);
  const userStatsUrl = getUserDeliveryStatsByBusinessId(businessId, search);
  const deliveryHistoryUrl = getDeliveryHistoryByBusinessId(businessId, search);

  useEffect(() => {
    setUsersError('');
    setStatisticsError('');
    setHistoryError('');
  }, [search, from, to]);

  useEffect(() => {
    setStatisticsError('');
    getStatistics();
    getUserHistoryStatistics();
    getDeliveryHistory();
  }, [search, from, to]);

  const getStatistics = async () => {
    setStatisticsLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(statisticsUrl, { params: { startDate, endDate } });
      console.log('STATISTICS', response.data);
      setStatistics(response.data);
      setStatisticsLoading(false);
    } catch (error) {
      console.log(error);
      setStatisticsLoading(false);
      setStatisticsError('Something went wrong while retrieving statistics');
    }
  };

  const getUserHistoryStatistics = async () => {
    setUsersLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(userStatsUrl, { params: { startDate, endDate } });
      if (response.data.length === 0) {
        setUsersError('No orders found for selected period');
      }
      console.log('USER STATS', response.data);
      setUserStatistics(response.data);
      setUsersLoading(false);
    } catch (error) {
      console.log(error);
      setUsersLoading(false);
      setUsersError('Something went wrong while retrieving statistics');
    }
  };

  const getDeliveryHistory = async () => {
    setHistoryLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(deliveryHistoryUrl, { params: { startDate, endDate } });
      if (response.data.length === 0) {
        setHistoryError('No orders found for selected period');
      }
      console.log('DELIVERY HISTORY', response.data);
      setDeliveryHistory(response.data);
      setHistoryLoading(false);
    } catch (error) {
      console.log(error);
      setHistoryLoading(false);
      setHistoryError('Something went wrong while retrieving statistics');
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col items-baseline justify-between mt-10 mb-2 sm:items-center sm:flex-row">
        <div className="flex items-center space-x-2">
          <Heading text="Overview" />
          <Tooltip message={`Overview of completed deliveries`}>
            <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
          </Tooltip>
        </div>
        <div className="mt-2">
          <DateSelectorFilter startDate={from} endDate={to} setStartDate={setFrom} setEndDate={setTo} withSearch={false} withLabels={true} />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4 p-5 rounded-2xl bg-gray-50">
        <div className="grid col-span-12 xl:col-span-5">
          <div className="row-span-3">
            <SmallHeading text="DELIVERIES BY USER" />
            <DeliveriesByUserHistory loading={usersLoading} error={usersError} users={userStatistics} />
          </div>
        </div>
        <div className="w-full h-full col-span-12 p-2 bg-white lg:col-span-12 xl:col-span-7 standard-border">
          <DeliveryChart title="Total Deliveries" label="Deliveries" data={statistics.countArray} loading={statisticsLoading} error={statisticsError} />
        </div>
        {/* <div className="w-full h-full col-span-12 p-2 bg-white lg:col-span-6 xl:col-span-4 standard-border">
          <SalesChart title="Total Revenue" label="Revenue" />
        </div> */}
      </div>
      <div className="mt-10 mb-2">
        <div className="flex items-center space-x-2">
          <Heading text="History" />
          <Tooltip message={`History of both - completed AND cancelled deliveries`}>
            <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
          </Tooltip>
        </div>
      </div>
      <div className="">
        <DeliveryHistoryTable loading={historyLoading} error={historyError} deliveries={deliveryHistory} type="business" />
      </div>
    </div>
  );
};

export default DeliveryHistory;
