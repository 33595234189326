import { useState } from 'react';

import dayjs from 'dayjs';

import getUserStatus from '../../helpers/getUserStatus';
import useAuth from '../../hooks/useAuth';
import Button from '../Button';
import Loader from '../Loader';
import BaseModal from '../Modals/BaseModal';
import RolePill from '../Pills/RolePill';
import UserStatusPill from '../Pills/UserStatusPill';
import UsersForm from './UsersForm';

const UsersTable = ({ users, loading, error, getUsers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  const { user: authUser } = useAuth();

  const headers = [
    { label: 'User Name', key: 1 },
    { label: 'Email', key: 2 },
    { label: 'Created at', key: 4 },
    { label: 'Role', key: 5 },
    { label: 'Status', key: 6 },
    { label: 'Manage', key: 7 }
  ];

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {/* {error && <p className="mt-3 text-xl text-red-500">{error}</p>} */}
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="py-3 pl-4 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {users &&
              users.map((user) => (
                <tr key={user.id} className="bg-white border-b whitespace-nowrap">
                  <th scope="row" className="px-4 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {user.firstName} {user.lastName}
                  </th>
                  <td className="py-4 pl-4">{user.email?.length ? user.email : '---'}</td>
                  <td className="py-4 pl-4">{dayjs(user.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="py-4 pl-4">
                    <RolePill role={user.role} />
                  </td>
                  <td className="py-4 pl-4">
                    <UserStatusPill status={getUserStatus(user)} />
                  </td>
                  {authUser?.id !== user.id && user.role !== 'superadmin' && (
                    <td className="py-4 pl-4">
                      <div className="flex space-x-2">
                        <Button
                          text="Edit"
                          bg="bg-blue"
                          rounded
                          border="border-blue"
                          onClick={() => {
                            toggleIsOpen(), setUser(user);
                          }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleIsOpen}
          isOpen={isOpen}
          title="Manage User"
          body={<UsersForm type="Edit" getUsers={getUsers} user={user} closeModal={toggleIsOpen} />}
        />
      )}
    </div>
  );
};

export default UsersTable;
