import axios from '../axios';

const COUNTRY_URL = '/countries';

export const getCountries = async () => {
  try {
    const response = await axios.get(COUNTRY_URL);
    return response;
  } catch (error) {
    return error.response;
  }
};
