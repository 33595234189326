import { useEffect, useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getCustomersByFleetId } from '../../api/services/CustomerService';
import { getCustomerStatsByFleetId } from '../../api/services/StatisticsService';
import CustomerAnalytics from '../../components/Customers/CustomerAnalytics';
import CustomerTable from '../../components/Customers/CustomerTable';

const Customers = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [analyticsError, setAnalyticsError] = useState('');
  const [customers, setCustomers] = useState([]);
  const [customerAnalytics, setCustomerAnalytics] = useState({});

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const customersUrl = getCustomersByFleetId(fleetId);
  const customerAnalyticsUrl = getCustomerStatsByFleetId(fleetId);

  useEffect(() => {
    setError('');
    setAnalyticsError('');
    getCustomers();
    getCustomerAnalytics();
  }, []);

  const getCustomers = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(customersUrl);
      console.log('CUSTOMERS', response.data);
      if (response.data.length === 0) {
        setError('No customers found');
      } else {
        setCustomers(response.data);
        setError('');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getCustomerAnalytics = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(customerAnalyticsUrl);
      console.log('CUSTOMER ANALYTICS', response.data);
      if (response.data.length === 0) {
        setAnalyticsError('No data found');
      } else {
        setCustomerAnalytics(response.data);
        setAnalyticsError('');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setAnalyticsError('Something went wrong');
      setLoading(false);
    }
  };

  return (
    <>
      <div className="w-full mx-auto xl:w-4/5">
        <div className="w-full">
          <Heading text="Customer List" />
          <CustomerTable customers={customers} loading={loading} error={error} getCustomers={getCustomers} />
        </div>
        <div className="flex items-center justify-between mt-20">
          <Heading text="Customer Analytics" />
        </div>
        <CustomerAnalytics customers={customerAnalytics} loading={loading} error={analyticsError} />
      </div>
    </>
  );
};

export default Customers;
