const addThousandSeparators = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const formatNumber = (number) => {
  // Parse the input to a number if it's a string
  const parsedNumber = typeof number === 'string' ? parseFloat(number) : number;

  // Format the number to 2 decimal places if it has decimal places and is not 0
  const formattedNumber = parsedNumber !== 0 && parsedNumber % 1 !== 0 ? parsedNumber.toFixed(2) : parsedNumber;

  // Add thousand separators to the formatted number
  return formattedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export { addThousandSeparators, formatNumber };
