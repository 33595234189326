import React from 'react';

import { Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

{
  /* business pages */
}
import Addresses from './Business/pages/Addresses/Addresses.jsx';
import ApiControl from './Business/pages/ApiControl/ApiControl.jsx';
import Branches from './Business/pages/Branches/Branches.jsx';
import Contact from './Business/pages/Contact/Contact.jsx';
import Credits from './Business/pages/Credits';
import BuyCredits from './Business/pages/Credits/BuyCredits.jsx';
import PurchaseHistory from './Business/pages/Credits/PurchaseHistory.jsx';
import Deliveries from './Business/pages/Deliveries';
import DeliveryHistory from './Business/pages/Deliveries/DeliveryHistory.jsx';
import ManageDeliveries from './Business/pages/Deliveries/ManageDeliveries.jsx';
import NewDelivery from './Business/pages/Deliveries/NewDelivery.jsx';
import FleetConnection from './Business/pages/FleetConnection/FleetConnection.jsx';
import Overview from './Business/pages/Overview/Overview.jsx';
import Users from './Business/pages/Users/Users.jsx';
{
  /* fleet pages */
}
import FleetContact from './Fleet/pages/Contact/Contact.jsx';
import Customers from './Fleet/pages/Customers/Customers.jsx';
import AllDrivers from './Fleet/pages/Drivers/AllDrivers.jsx';
import Bonuses from './Fleet/pages/Drivers/Bonuses.jsx';
import CitiesAndFleetCodes from './Fleet/pages/Drivers/CitiesAndFleetCodes.jsx';
import Contracts from './Fleet/pages/Drivers/Contracts.jsx';
import Drivers from './Fleet/pages/Drivers/index.jsx';
import Salaries from './Fleet/pages/Drivers/Salaries.jsx';
import Shifts from './Fleet/pages/Drivers/Shifts.jsx';
import LiveDeliveries from './Fleet/pages/LiveDeliveries/LiveDeliveries.jsx';
import BalanceAndWithdraw from './Fleet/pages/Sales/BalanceAndWithdraw.jsx';
import FleetDeliveryHistory from './Fleet/pages/Sales/FleetDeliveryHistory.jsx';
import Sales from './Fleet/pages/Sales/index.jsx';
import Pricing from './Fleet/pages/Sales/Pricing.jsx';
import SalesAnalytics from './Fleet/pages/Sales/SalesAnalytics.jsx';
import FleetUsers from './Fleet/pages/Users/Users.jsx';
{
  /* shared pages */
}
import AuthenticatedLayout from './Shared/layouts/AuthenticatedLayout.js';
import EmptyLayout from './Shared/layouts/EmptyLayout.js';
import PersistLogin from './Shared/layouts/PersistLogin.js';
import AcceptInvite from './Shared/pages/AcceptInvite/AcceptInvite.jsx';
import LoginPage from './Shared/pages/Login/Login.jsx';
import NotFound from './Shared/pages/NotFound/NotFound.jsx';
import RegisterPage from './Shared/pages/Register/Register.jsx';
import ResetPassword from './Shared/pages/ResetPassword/ResetPassword.jsx';
import Unauthorized from './Shared/pages/Unauthorized.jsx';

function App() {
  return (
    <>
      <ToastContainer autoClose={3000} position="bottom-center" newestOnTop />
      <Routes>
        <Route element={<EmptyLayout />}>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="reset-password/:resetToken" element={<ResetPassword />} />
          <Route path="accept-invite/:inviteToken" element={<AcceptInvite />} />
          <Route path="*" element={<NotFound />} />
          <Route path="unauthorized" element={<Unauthorized />} />

          <Route element={<PersistLogin />}>
            <Route element={<AuthenticatedLayout types={['fleet']} allowedRoles={['admin', 'superadmin', 'user']} />}>
              {/* <Route index path="/" element={<Navigate to="/fleet/live-deliveries/:city" />} /> */}
              <Route index path="/fleet" element={<Navigate to="/fleet/live-deliveries/:city" />} />
              <Route path="fleet/live-deliveries/:city" element={<LiveDeliveries />} />
              <Route path="fleet/drivers" element={<Drivers />}>
                <Route path="all-drivers" element={<AllDrivers />}></Route>
                <Route path="shifts" element={<Navigate to="/fleet/drivers/shifts/:city" />} />
                <Route path="shifts/:city" element={<Shifts />}></Route>
                {/* <Route path="salaries" element={<Salaries />}></Route> */}
                {/* <Route path="contracts" element={<Contracts />}></Route> */}
                {/* <Route path="bonuses" element={<Bonuses />}></Route> */}
                {/* <Route path="cities-and-fleet-codes" element={<CitiesAndFleetCodes />}></Route> */}
              </Route>
              <Route path="fleet/sales" element={<Sales />}>
                {/* <Route path="analytics" element={<SalesAnalytics />}></Route> */}
                {/* <Route path="pricing" element={<Pricing />}></Route> */}
                <Route path="delivery-history" element={<FleetDeliveryHistory />}></Route>
                {/* <Route path="balance-and-withdraw" element={<BalanceAndWithdraw />}></Route> */}
              </Route>
              <Route path="fleet/live-deliveries" element={<LiveDeliveries />} />
              <Route path="fleet/customers" element={<Customers />} />
              <Route path="fleet/contact" element={<FleetContact />} />
            </Route>

            <Route element={<AuthenticatedLayout types={['fleet']} allowedRoles={['admin', 'superadmin']} />}>
              {/* <Route index path="/" element={<Navigate to="/fleet/live-deliveries/:city" />} /> */}
              <Route index path="/fleet" element={<Navigate to="/fleet/live-deliveries/:city" />} />
              <Route path="fleet/live-deliveries/:city" element={<LiveDeliveries />} />
              <Route path="fleet/drivers" element={<Drivers />}>
                <Route path="all-drivers" element={<AllDrivers />}></Route>
                <Route path="shifts" element={<Navigate to="/fleet/drivers/shifts/:city" />} />
                <Route path="shifts/:city" element={<Shifts />}></Route>
                <Route path="salaries" element={<Salaries />}></Route>
                <Route path="contracts" element={<Contracts />}></Route>
                <Route path="bonuses" element={<Bonuses />}></Route>
                <Route path="cities-and-fleet-codes" element={<CitiesAndFleetCodes />}></Route>
              </Route>
              <Route path="fleet/sales" element={<Sales />}>
                <Route path="analytics" element={<SalesAnalytics />}></Route>
                <Route path="pricing" element={<Pricing />}></Route>
                <Route path="delivery-history" element={<FleetDeliveryHistory />}></Route>
                <Route path="balance-and-withdraw" element={<BalanceAndWithdraw />}></Route>
              </Route>
              <Route path="fleet/live-deliveries" element={<LiveDeliveries />} />
              <Route path="fleet/customers" element={<Customers />} />
              <Route path="fleet/users" element={<FleetUsers />} />
              <Route path="fleet/contact" element={<FleetContact />} />
            </Route>

            <Route element={<AuthenticatedLayout types={['business']} allowedRoles={['admin', 'superadmin', 'user']} />}>
              {/* <Route index path="/" element={<Navigate to="/business/overview" />} /> */}
              <Route index path="business" element={<Navigate to="/business/overview" />} />
              <Route path="business/overview" element={<Overview />} />
              <Route path="business/deliveries" element={<Deliveries />}>
                <Route path="new" element={<NewDelivery />}></Route>
                <Route path="history" element={<DeliveryHistory />}></Route>
                <Route path="manage" element={<ManageDeliveries />}></Route>
              </Route>
              <Route path="business/addresses" element={<Addresses />} />
              <Route path="business/contact" element={<Contact />} />
            </Route>

            <Route element={<AuthenticatedLayout types={['business']} allowedRoles={['admin', 'superadmin']} />}>
              <Route index path="business" element={<Navigate to="/business/overview" />} />
              <Route path="business/overview" element={<Overview />} />
              <Route path="business/deliveries" element={<Deliveries />}>
                <Route path="new" element={<NewDelivery />}></Route>
                <Route path="history" element={<DeliveryHistory />}></Route>
                <Route path="manage" element={<ManageDeliveries />}></Route>
              </Route>
              <Route path="business/credits" element={<Credits />}>
                <Route path="buy" element={<BuyCredits />}></Route>
                <Route path="history" element={<PurchaseHistory />}></Route>
              </Route>
              <Route path="business/addresses" element={<Addresses />} />
              <Route path="business/fleet-connection" element={<FleetConnection />} />
              <Route path="business/api-control" element={<ApiControl />} />
              <Route path="business/branches" element={<Branches />} />
              <Route path="business/users" element={<Users />} />
              <Route path="business/contact" element={<Contact />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
