import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import L from 'leaflet';
import _ from 'lodash';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import '../../../index.css';

import Loader from '../../../Shared/components/Loader';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDriverLocation } from '../../api/services/DriverService';

const DriverMap = ({ driver, closeModal, selectedOrder }) => {
  const [cityCoordinates, setCityCoordinates] = useState(null);
  const [driverLocation, setDriverLocation] = useState(null);
  const [loading, setLoading] = useState(false);

  const getDriverLocationUrl = getDriverLocation(driver?.fleetId, driver?.id);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (driver) {
      // console.log('DRIVER DATA', driver);
      setCityCoordinates({ lat: driver?.cityLatitude, lng: driver?.cityLongitude });

      const fetchDriverAndSetInterval = () => {
        fetchDriverLocation(); // Fetch driver location immediately

        const id = setInterval(() => {
          fetchDriverLocation(); // Fetch driver location with interval
        }, 8000);

        return id;
      };

      const intervalId = fetchDriverAndSetInterval();

      // fetchAll();

      return () => {
        clearInterval(intervalId);
      };
    } else {
      closeModal();
    }
  }, [driver]);

  const fetchDriverLocation = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(getDriverLocationUrl);
      if (response.data?.length === 0 || response.data === null) {
        console.log('Driver is not sharing location currently', response);
      } else {
        console.log('locatioooon', response.data);
        setDriverLocation(response.data.position);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const pickupIcon = new L.Icon({
    iconUrl: require('../../../assets/images/pickup2.png'),
    iconRetinaUrl: require('../../../assets/images/pickup2.png'),
    popupAnchor: [0, -10],
    iconSize: new L.Point(40, 40)
  });

  const deliveryIcon = new L.Icon({
    iconUrl: require('../../../assets/images/delivery2.png'),
    iconRetinaUrl: require('../../../assets/images/delivery2.png'),
    popupAnchor: [0, -10],
    iconSize: new L.Point(40, 40)
  });

  const driverIcon = new L.Icon({
    iconUrl: require('../../../assets/images/driver.png'),
    iconRetinaUrl: require('../../../assets/images/driver.png'),
    popupAnchor: [0, -10],
    iconSize: new L.Point(45, 45)
  });

  const activeDeliveries = _.filter(driver?.orders, ({ status }) => status < 3);

  return (
    <>
      <div className="h-full map">
        {/* <div className="h-full min-h-[300px] map"> */}
        {!cityCoordinates ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        ) : (
          <MapContainer center={cityCoordinates} zoom={12} scrollWheelZoom={false}>
            <Recenter
              lat={selectedOrder ? selectedOrder.branchLatitude : driverLocation?.coords?.latitude ? driverLocation?.coords?.latitude : cityCoordinates?.lat}
              lng={selectedOrder ? selectedOrder.branchLongitude : driverLocation?.coords?.longitude ? driverLocation?.coords?.longitude : cityCoordinates?.lng}
            />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {selectedOrder !== null ? (
              <MarkerPopup pickupIcon={pickupIcon} deliveryIcon={deliveryIcon} order={selectedOrder} />
            ) : activeDeliveries.length > 0 ? (
              activeDeliveries.map((order) => {
                return (
                  <div key={order.id}>
                    <MarkerPopup pickupIcon={pickupIcon} deliveryIcon={deliveryIcon} order={order} />
                  </div>
                );
              })
            ) : null}
            {driverLocation && (
              <Marker icon={driverIcon} position={[driverLocation?.coords?.latitude, driverLocation?.coords?.longitude]}>
                <Popup>
                  <p>Driver: {driver?.name}</p>
                  <p>Last updated: {dayjs(location?.position?.timestamp).format('HH:mm')}</p>
                </Popup>
              </Marker>
            )}
          </MapContainer>
        )}
      </div>

      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
        integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
        crossOrigin=""
      />
      <script src="https://unpkg.com/leaflet@1.9.2/dist/leaflet.js" integrity="sha256-o9N1jGDZrf5tS+Ft4gbIK7mYMipq9lqpVJ91xHSyKhg=" crossOrigin=""></script>
    </>
  );
};

export default DriverMap;

const Recenter = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);

  return null;
};

const MarkerPopup = ({ pickupIcon, deliveryIcon, order }) => {
  return (
    <>
      <Marker icon={pickupIcon} key={`${order.id}_PICKUP`} position={[order.branchLatitude, order.branchLongitude]}>
        <Popup>
          <p>#{order.orderReference}</p>
          <p>Name: {order.branchName}</p>
          <p>
            Address: {order.branchAddress} {order.branchCity} {order.branchPostCode}
          </p>
        </Popup>
      </Marker>
      <Marker icon={deliveryIcon} key={`${order.id}_DELIVERY`} position={[order.deliveryLatitude, order.deliveryLongitude]}>
        <Popup>
          <p>#{order.orderReference}</p>
          <p>Name: {order.customerFullName}</p>
          <p>
            Address: {order.deliveryAddress} {order.deliveryCity} {order.deliveryPostCode}
          </p>
          <p>Phone number: {order.customerPhoneNumber}</p>
        </Popup>
      </Marker>
    </>
  );
};
