import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';

import CitySelect from '../../../Shared/components/CitySelect';
import DateSelectorFilter from '../../../Shared/components/Filters/DateSelectorFilter';
import Heading from '../../../Shared/components/Heading';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getCitiesByFleetId } from '../../api/services/CityService';
import { getShiftsByFleetIdAndCity } from '../../api/services/ShiftsService';
import CreateShiftPanel from '../../components/Drivers/Shifts/CreateShiftPanel';
import ShiftTable from '../../components/Drivers/Shifts/ShiftTable';

const Shifts = () => {
  const lastMonth = dayjs().startOf('month').toDate();
  const today = dayjs().endOf('month').toDate();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [shifts, setShifts] = useState([]);
  const [cities, setCities] = useState([]);
  const [shiftCount, setShiftCount] = useState(0);

  const [from, setFrom] = useState(lastMonth);
  const [to, setTo] = useState(today);

  const { user } = useAuth();
  const { city } = useParams();
  const navigate = useNavigate();

  const fleetId = user?.fleetId;
  const axiosPrivate = useAxiosPrivate();
  const shiftsUrl = getShiftsByFleetIdAndCity(fleetId, city);
  const citiesUrl = getCitiesByFleetId(fleetId, '?active=true');

  useEffect(() => {
    setError('');
    getCities();
  }, []);

  useEffect(() => {
    if (cities.length > 0 && (city === ':city' || !city)) {
      navigate(`/fleet/drivers/shifts/${cities[0]?.name}`);
    }
  }, [cities, city]);

  useEffect(() => {
    if (city) {
      getShifts();
    }
  }, [city, from, to]);

  const getShifts = async () => {
    setLoading(true);
    try {
      const startDate = startOfDay(from);
      const endDate = endOfDay(to);

      const response = await axiosPrivate.get(shiftsUrl, { params: { startDate, endDate } });

      setLoading(false);
      if (response.data?.length === 0) {
        setError('No shifts have been created so far');
        setShiftCount(0);
        setLoading(false);
      } else {
        setShifts(response.data);
        setShiftCount(response.data?.length);
        setLoading(false);
        setError('');
      }
    } catch (error) {
      console.log('Error on shifts page', error);
      setLoading(false);
      setError('Something went wrong');
    }
  };

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(citiesUrl);
      // console.log(response.data);
      if (response.data.length === 0) {
        setCities([]);
      } else {
        setCities(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong');
    }
  };

  return (
    <>
      <div className="flex justify-center p-5 mb-10 bg-llYellow rounded-xl">
        <CreateShiftPanel getShifts={getShifts} />
      </div>
      {city && cities.length > 0 && (
        <div className="flex flex-col">
          <CitySelect cities={cities} link="/fleet/drivers/shifts" />
          <div className="mb-2 mt-14">
            <div className="flex flex-col md:items-center md:flex-row md:justify-between">
              <div className="flex items-center space-x-2">
                <Heading text={`Shifts in this period (${shiftCount})`} />
              </div>
              <div className="flex flex-col space-y-2 md:items-center md:flex-row md:space-y-0 md:space-x-5">
                <DateSelectorFilter startDate={from} endDate={to} setStartDate={setFrom} setEndDate={setTo} withSearch={false} withLabels={true} />
              </div>
            </div>
          </div>
          <div className="">
            <ShiftTable shifts={shifts} loading={loading} error={error} getShifts={getShifts} />
          </div>
        </div>
      )}
    </>
  );
};

export default Shifts;
