import React, { useEffect, useState, createContext } from 'react';

const DeliveryContext = createContext();

const DeliveryProvider = (props) => {
  // types: 'basic' | 'custom'
  const [pickupType, setPickupType] = useState('basic');
  const [basicPickupDetails, setBasicPickupDetails] = useState({});
  const [customPickupDetails, setCustomPickupDetails] = useState({});
  const [deliveryDetails, setDeliveryDetails] = useState({});
  const [filled, setFilled] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [refetch, setRefetch] = useState('');

  useEffect(() => {
    if (pickupType === 'basic') {
      if (Object.keys(basicPickupDetails).length && Object.keys(deliveryDetails).length) {
        setFilled(true);
      } else {
        setFilled(false);
      }
    } else if (pickupType === 'custom') {
      if (Object.keys(customPickupDetails).length && Object.keys(basicPickupDetails).length && Object.keys(deliveryDetails).length) {
        setFilled(true);
      } else {
        setFilled(false);
      }
    } else {
      setFilled(false);
    }

    // console.log('TYPE', pickupType);
  }, [pickupType, basicPickupDetails, customPickupDetails, deliveryDetails]);

  const clearDeliveryDetails = () => {
    setDeliveryDetails({});
    setBasicPickupDetails({});
    setCustomPickupDetails({});
  };

  const deliveryProviderValue = {
    setPickupType,
    pickupType,
    basicPickupDetails,
    setBasicPickupDetails,
    customPickupDetails,
    setCustomPickupDetails,
    deliveryDetails,
    setDeliveryDetails,
    clearDeliveryDetails,
    filled,
    submitted,
    refetch,
    setRefetch,
    setSubmitted
  };

  return <DeliveryContext.Provider value={deliveryProviderValue}>{props.children}</DeliveryContext.Provider>;
};

export { DeliveryContext, DeliveryProvider };
