const Tooltip = ({ message, subMessage, children }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute bottom-0 z-50 flex flex-col items-center hidden w-24 mb-3 text-white bg-gray-600 rounded-md shadow-lg group-hover:flex min-w-max">
        <span className="relative z-10 p-3 text-xs leading-none">{message}</span>
        {subMessage && <span className="relative z-10 p-3 text-xs leading-none ">{subMessage}</span>}
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-600"></div>
      </div>
    </div>
  );
};

export default Tooltip;
