import { Chart as ChartJS } from 'chart.js/auto';
import dayjs from 'dayjs';
import { Bar } from 'react-chartjs-2';

import Loader from '../Loader';

const SalesChart = ({ data, label, title, loading }) => {
  const generateChartData = (source, color, label) => {
    let labels = [];
    let _data = [];

    source.forEach((element) => {
      labels.push(dayjs(element.day).format('DD/MMM'));
      _data.push(element.sales);
    });

    const data = {
      labels: labels,
      datasets: [
        {
          label: label,
          data: _data,
          backgroundColor: color
        }
      ]
    };
    return data;
  };

  const salesOptions = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top'
      },
      title: {
        display: true,
        text: title
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex justify-center p-5 mx-auto items-center">
          <Loader />
        </div>
      ) : (
        <Bar data={generateChartData(data || [], '#ffc82c', label)} options={salesOptions} width={100} height={400} />
      )}
    </>
  );
};

export default SalesChart;
