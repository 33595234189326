import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

const ConfirmDeleteModal = ({ isOpen, cancel, remove, name, action, codeGeneration, disabled }) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={cancel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md p-6 overflow-hidden text-center align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Confirm Action
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {codeGeneration
                        ? 'Are you sure you want to generate new fleet connection code? This will NOT disconnect currently connected customers'
                        : `Are you sure you want to ${action} this ${name}?`}
                    </p>
                  </div>

                  <div className="mt-4 space-x-4">
                    <button
                      type="button"
                      className={`${
                        disabled ? 'bg-gray-300 border-gray-300 text-white cursor-not-allowed' : 'border-blue text-blue hover:bg-blue cursor-pointer'
                      } inline-flex justify-center px-4 py-2 text-sm font-medium bg-blue-100 border rounded-md hover:text-white focus:outline-none`}
                      onClick={cancel}
                      disabled={disabled}>
                      Cancel
                    </button>
                    <button
                      disabled={disabled}
                      type="button"
                      className={`${
                        disabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-red-500 cursor-pointer'
                      } inline-flex justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md hover:opacity-80 focus:outline-none`}
                      onClick={() => remove()}>
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ConfirmDeleteModal;
