const BUSINESS_URL = '/business';

export const getBusinessById = (businessId) => {
  const url = `${BUSINESS_URL}/${businessId}`;
  return url;
};

export const updateBusiness = (id) => {
  const url = `${BUSINESS_URL}/${id}`;
  return url;
};
