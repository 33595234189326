// import { useEffect } from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import useAuth from '../hooks/useAuth';

const AuthenticatedLayout = ({ types, allowedRoles }) => {
  const { auth, user } = useAuth();
  const location = useLocation();

  // useEffect(() => {
  //   console.log(types);
  //   console.log(user?.type);
  //   console.log('TOKEN', auth?.token);
  // }, [user, auth]);

  console.log('types:', types, 'user role:', user?.role, 'allowed role:', allowedRoles);

  const roleIsAllowed = allowedRoles?.includes(user?.role);

  return (
    <>
      {user && types?.length && types.includes(user?.type) && roleIsAllowed ? (
        <div className="mx-auto">
          <Header />
          <Navbar />
          <div className="max-w-full min-h-screen p-5 pt-20 bg-white sm:pb-20 pb-10">
            <Outlet />
          </div>
          <Footer />
        </div>
      ) : auth?.token ? (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default AuthenticatedLayout;
