import { Outlet } from 'react-router-dom';

const EmptyLayout = () => {
  return (
    <>
      <div className="">
        <Outlet />
      </div>
    </>
  );
};

export default EmptyLayout;
