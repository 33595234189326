import { useEffect, useState } from 'react';

import Loader from '../../../../Shared/components/Loader';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { getProductList } from '../../../api/services/PaymentService';
import Credit from './Credit';

const CreditList = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();

  const productUrl = getProductList();

  const currency = user?.currency.toLowerCase();

  useEffect(() => {
    setError('');
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      const response = await axiosPrivate.get(productUrl);
      console.log('Products', response.data);
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center max-w-6xl">
      {products.length ? (
        <div className="flex flex-wrap gap-4">
          {products?.map((product) => (
            <Credit
              key={product.id}
              credits={product.product?.metadata?.value}
              currency={user.currency}
              price={product.currency_options[currency].unit_amount_decimal / 100}
              discount={product.product?.metadata?.discount}
            />
          ))}
          {/* <Credit orders={'500+'} credits={45000} price={0} contactButton /> */}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CreditList;
