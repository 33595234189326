import { Fragment, useEffect, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';

import { getCitiesByFleetId } from '../../../Fleet/api/services/CityService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const CityFilter = ({ fleetId, withCityId }) => {
  const [cityList, setCityList] = useState([]);
  const [cityFilter, setCityFilter] = useState('All');
  const [error, setError] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const citiesUrl = getCitiesByFleetId(fleetId, '');
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    try {
      const response = await axiosPrivate.get(citiesUrl);
      setCityList(response.data);
      if (response.data.length === 0) {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  };

  const handleCityFilter = (city) => {
    if (withCityId) {
      if (city === 'All') {
        searchParams.delete('cityFleetId');
        setCityFilter('All');
      } else {
        searchParams.set('cityFleetId', city.id);
        setCityFilter(city.name);
      }
    } else {
      if (city === 'All') {
        searchParams.delete('cityName');
        setCityFilter('All');
      } else {
        searchParams.set('cityName', city.name);
        setCityFilter(city.name);
      }
    }
    setSearchParams(searchParams);
  };

  if (error) {
    return null;
  }

  return (
    <div className="flex items-center space-x-3 z-[49]">
      <h2 className="whitespace-nowrap">Select city:</h2>
      <div className="relative z-10">
        <Listbox value={cityFilter} onChange={handleCityFilter}>
          <div className="relative mt-1">
            <Listbox.Button className="relative py-2 pl-3 pr-10 text-left text-white bg-gray-500 shadow-md cursor-pointer w-fit rounded-3xl focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
              <span className="block truncate">{cityFilter}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 min-w-max ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-2 border-b border-gray-200 pr-2 ${active ? 'bg-yellow-400 text-amber-900' : 'text-gray-900'}`
                  }
                  value="All">
                  {({ cityFilter }) => (
                    <>
                      <span className={`block truncate ${cityFilter ? 'font-medium' : 'font-normal'}`}>All</span>
                      {cityFilter ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-amber-600">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                {cityList.map((city, cityIdx) => (
                  <Listbox.Option
                    key={cityIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 border-b border-gray-200 pr-10 ${
                        active ? 'bg-yellow-400 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={city}>
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{city.name}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-amber-600">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export default CityFilter;
