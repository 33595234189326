import { useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import InfoBanner from '../../../Shared/components/InfoBanner';
import CreditList from '../../components/Credits/BuyCredits/CreditList';

const BuyCredits = () => {
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <div className="flex flex-col items-center justify-center mx-auto">
      <Heading text="Buy Credits" uppercase />
      <div className="w-full px-10 py-3 mt-2 mb-5 bg-gray-200 sm:w-2/3 rounded-3xl">
        <p className="text-sm text-center text-gray-700">
          Credits are being used to send deliveries to your connected fleets.
          <br />
          <br />
          You can always review the cost of each order when submitting a delivery!
          <br />
          <br />
          If you send an order to a fleet but then decide to cancel it before it&apos;s assigned to a driver the credits will be returned to you. You can always
          check your available credit balance on the <strong>&apos;Create new&apos;</strong> page under the <strong>&apos;Deliveries&apos;</strong> menu.
          <br />
          <br /> If you have more questions regarding the purchase of credits, send us a mail at <strong>info@lastlap.delivery!</strong>
        </p>
      </div>
      <div className="w-full sm:max-w-2xl">
        <InfoBanner variant="warning">
          <p className="text-sm text-left text-dark">
            <span className="font-semibold">IMPORTANT UPDATE:</span> By the 01/01/2024 the discount structure will be changed.{' '}
            <span className="underline cursor-pointer text-blue" onClick={() => toggleReadMore()}>
              {isReadMore ? 'Read less' : 'Read more'}
            </span>
            {isReadMore && (
              <div className="mt-4">
                <Announcement isReadMore />
              </div>
            )}
          </p>
          <div></div>
        </InfoBanner>
      </div>
      <CreditList />
    </div>
  );
};

export default BuyCredits;

const Announcement = ({ isReadMore }) => {
  return (
    <div className={`max-w-2xl p-4 mx-auto mt-4 border border-yellow-500 rounded-md bg-yellow-50 ${isReadMore ? 'visible' : 'invisible'}`}>
      <p className="mb-4 text-lg font-bold">Important Announcement!</p>

      <p>By January 1, 2024, the discount structure will be changed. Additionally, a new package priced at 23,000 DKK will become available.</p>

      <p className="mt-4 font-bold">New Package and Discount Structure (from January 1, 2024, onwards):</p>

      <ul className="list-disc list-inside">
        <li>900 credits: 0% discount</li>
        <li>4500 credits: 0% discount</li>
        <li>9000 credits: 3% discount</li>
        <li>23000 credits: 5% discount</li>
        <li>45000 credits: 7% discount</li>
      </ul>

      <p className="mt-4">
        Please note that you can make unlimited purchases of the current packages with the existing discounts up to January 1, 2024. However, the current
        packages will become unavailable after this date.
      </p>

      <p className="mt-4">Your existing credits will not be affected by this change.</p>

      <p className="mt-4">
        If you have any questions, feel free to email us at{' '}
        <a href="mailto:info@lastlap.delivery" className="text-blue-500 underline">
          info@lastlap.delivery
        </a>
        .
      </p>
    </div>
  );
};
