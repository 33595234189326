import { useState } from 'react';

import dayjs from 'dayjs';

import DeliveryDetails from '../../../Shared/components/DeliveryDetails';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import TextModal from '../../../Shared/components/Modals/TextModal';
import NoteIcon from '../../../Shared/components/NoteIcon';
import showDeliveryStatus from '../../../Shared/helpers/showDeliveryStatus';
import showNoteCount from '../../../Shared/helpers/showNoteCount';
import NoteForm from '../Deliveries/ManageDeliveries/NoteForm';

const OverviewDeliveries = ({ deliveries, loading, error, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [delivery, setDelivery] = useState({});
  const [noteModal, setNoteModal] = useState(false);

  const headers = [
    { label: 'ORDER ID', key: 1 },
    { label: 'NOTES', key: 9 },
    { label: 'Pickup from', key: 2 },
    { label: 'Pickup address', key: 3 },
    { label: 'Delivery to (company)', key: 4 },
    { label: 'Delivery address', key: 8 },
    { label: 'Distance (km)', key: 10 },
    { label: 'PICK-UP', key: 5 },
    { label: 'DELIVERY', key: 6 },
    { label: 'STATUS', key: 7 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleNoteModal = () => {
    setNoteModal(!noteModal);
  };

  return (
    <div className="relative mt-2 overflow-x-auto standard-border rounded-3xl max-h-[500px]">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase whitespace-nowrap">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-4 py-3">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {deliveries && (
            <tbody>
              {deliveries.map((delivery) => (
                <tr key={delivery.id} className="bg-white border-b whitespace-nowrap">
                  <td
                    className="px-4 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80"
                    onClick={() => {
                      toggleModal(), setDelivery(delivery);
                    }}>
                    {delivery.orderReference}
                  </td>
                  <td className="px-4 py-4 whitespace-nowrap">
                    <NoteIcon
                      counter={showNoteCount(delivery, 'business')}
                      onClick={() => {
                        toggleNoteModal(), setDelivery(delivery);
                      }}
                    />
                  </td>
                  <td className="px-4 py-4">{delivery.branchName}</td>
                  <td className="px-4 py-4">
                    {delivery.branchAddress}, {delivery.branchCity}
                    {delivery.branchPostCode && `, ${delivery.branchPostCode}`}
                  </td>
                  <td className="px-4 py-4">
                    {delivery.customerFullName} {delivery.customerBusinessName ? `(${delivery.customerBusinessName})` : ''}
                  </td>
                  <td className="px-4 py-4">
                    {delivery.deliveryAddress}
                    {delivery.deliveryCity && `, ${delivery.deliveryCity}`}
                    {delivery.deliveryPostCode && `, ${delivery.deliveryPostCode}`}
                  </td>
                  <td className="px-4 py-4">{delivery.distance}</td>
                  {type === 'future' ? (
                    <td className="px-4 py-4">{dayjs(delivery.pickupTime).format('MMM DD, HH:mm')}</td>
                  ) : (
                    <td className="px-4 py-4">{dayjs(delivery.pickupTime).format('HH:mm')}</td>
                  )}

                  {type === 'future' ? (
                    <td className="px-4 py-4">{dayjs(delivery.deliveryTime).format('MMM DD, HH:mm')}</td>
                  ) : (
                    <td className="px-4 py-4">{dayjs(delivery.deliveryTime).format('HH:mm')}</td>
                  )}
                  <td className="px-4 py-4">{showDeliveryStatus(delivery.status)}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal closeModal={toggleModal} isOpen={isOpen} title="Delivery Details" body={<DeliveryDetails closeModal={toggleModal} delivery={delivery} />} />
      )}
      {noteModal && delivery && (
        <TextModal
          closeModal={toggleNoteModal}
          isOpen={noteModal}
          type="edit"
          title="Order notes"
          labelText="Your note:"
          body={<NoteForm closeModal={toggleNoteModal} delivery={delivery} toggleNoteModal={toggleNoteModal} readonly />}
        />
      )}
    </div>
  );
};

export default OverviewDeliveries;
