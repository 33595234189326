import { Link } from 'react-router-dom';

import DynamicIcon from '../components/DynamicIcon';

const Unauthorized = () => {
  return (
    <div className="w-9/12 m-auto py-16 min-h-fit flex items-center justify-center">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
        <div className="border-t border-gray-200 text-center pt-8">
          <h1 className="text-2xl font-bold text-blue">Unauthorized :(</h1>
          <p className="text-2xl pb-8 px-12 mt-2 font-medium text-gray-600">You don&apos;t have permissions to access this page!</p>
          <Link
            to={-1}
            className="bg-blue-500 border-blue border text-blue font-semibold px-6 py-3 rounded-md w-max flex items-center justify-center mx-auto hover:opacity-75">
            <DynamicIcon icon="ArrowLeftCircleIcon" />
            <p className="ml-2">Go Back</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
