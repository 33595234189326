import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';

import Button from '../../../../Shared/components/Button';
import DynamicIcon from '../../../../Shared/components/DynamicIcon';
import Tooltip from '../../../../Shared/components/Tooltip';
import { endOfDay, startOfDay } from '../../../../Shared/helpers/date.js';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { getCitiesByFleetId } from '../../../api/services/CityService';
import { createShift } from '../../../api/services/ShiftsService';

const CreateShiftPanel = ({ getShifts }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [flexibleDate, setFlexibleDate] = useState(null);
  const [driverLimit, setDriverLimit] = useState(null);
  const [cityId, setCityId] = useState('');
  const [cities, setCities] = useState([]);
  const [flexibleShift, setFlexibleShift] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { user } = useAuth();

  const fleetId = user?.fleetId;
  const citiesUrl = getCitiesByFleetId(fleetId, '?active=true');
  const createShiftUrl = createShift();

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    if (endDate && startDate) {
      const startDay = dayjs(startDate).format('YYYY-MM-DD');
      const endDay = dayjs(endDate).format('YYYY-MM-DD');

      if (startDay !== endDay) {
        setEndDate(null);
      }
    }
  }, [startDate, endDate]);

  const clearFields = () => {
    setStartDate(null);
    setEndDate(null);
    setDriverLimit(0);
    setCityId('');
    setFlexibleShift(false);
    setFlexibleDate(null);
  };

  const getCities = async () => {
    try {
      const response = await axiosPrivate.get(citiesUrl);
      setCities(response.data);
      // TODO: if no cities created display something instead of alerting
    } catch (error) {
      console.log(error);
      toast.error(`Something went wrong! ${error}`);
    }
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    const data = {
      startDate: flexibleShift ? startOfDay(flexibleDate) : startDate,
      endDate: flexibleShift ? endOfDay(flexibleDate) : endDate,
      maxNumber: flexibleShift ? 99999 : driverLimit,
      cityId,
      fleetId
    };

    if (dayjs(endDate).isBefore(startDate)) {
      toast.warn('End date must be after start date!');
    } else {
      try {
        const response = await axiosPrivate.post(createShiftUrl, data);
        console.log(response);
        getShifts();
        clearFields();
        toast.success('Shift created!');
      } catch (err) {
        console.log(err.response);
        toast.success('Something went wrong, try again or contact support!');
      }
    }

    setSubmitting(false);
  };

  const filterStartTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterEndTime = (time) => {
    const currentDate = startDate ? new Date(startDate) : new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const toggleFlexibleShift = () => {
    setFlexibleShift(!flexibleShift);
  };

  return (
    <div className="w-full">
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <div className="overflow-hidden shadow rounded-3xl md:rounded-full">
          <div className="flex flex-col items-center justify-center p-6 space-y-3 bg-white lg:space-x-5 lg:space-y-0 lg:flex-row">
            <p className="text-xl whitespace-nowrap">Create a shift:</p>
            <div className="grid grid-cols-8 gap-5 lg:grid-cols-10">
              <div className="flex items-center justify-center col-span-8 lg:justify-start lg:col-span-2">
                <input
                  name="Flexible shift"
                  type="checkbox"
                  id="flexibleShift"
                  onChange={() => toggleFlexibleShift()}
                  checked={flexibleShift}
                  className="w-4 h-4 border-gray-300 rounded text-blue focus:ring-0 hover:cursor-pointer"
                />
                <div className="flex items-center space-x-1">
                  <label htmlFor="flexibleShift" className="block ml-2 text-sm text-gray-900 hover:cursor-pointer">
                    Flexible shift
                  </label>
                  <Tooltip message={`Shift that any driver can join at any time`}>
                    <DynamicIcon icon="InformationCircleIcon" width="w-4" height="h-4" />
                  </Tooltip>
                </div>
              </div>
              {flexibleShift ? (
                <div className="col-span-4 lg:col-span-3">
                  <DatePicker
                    wrapperClassName="datePicker"
                    className="date-picker-rounded"
                    required
                    placeholderText="Select Date"
                    selected={flexibleDate}
                    minDate={new Date()}
                    filterTime={filterStartTime}
                    onChange={(date) => setFlexibleDate(date)}
                    showTimeSelect={false}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              ) : (
                <>
                  <div className="col-span-4 lg:col-span-2">
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="date-picker-rounded"
                      disabled={flexibleShift}
                      required
                      placeholderText={flexibleShift ? '---' : 'Start date/time'}
                      selected={startDate}
                      minDate={new Date()}
                      filterTime={filterStartTime}
                      onChange={(date) => setStartDate(date)}
                      timeIntervals={15}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, HH:mm"
                    />
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <DatePicker
                      wrapperClassName="datePicker"
                      className="date-picker-rounded"
                      disabled={flexibleShift}
                      required
                      placeholderText={flexibleShift ? '---' : 'End date/time'}
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      minDate={dayjs(startDate).toDate()}
                      maxDate={dayjs(startDate).endOf('day').toDate()}
                      filterTime={filterEndTime}
                      timeIntervals={15}
                      showTimeSelect
                      timeFormat="HH:mm"
                      dateFormat="MMMM d, HH:mm"
                    />
                  </div>
                  <div className="col-span-4 lg:col-span-2">
                    <input
                      type="number"
                      name="driverLimit"
                      id="driverLimit"
                      placeholder={`${flexibleShift ? '---' : 'Driver limit'}`}
                      min={1}
                      onChange={(e) => setDriverLimit(e.target.value)}
                      value={driverLimit}
                      autoComplete="driverLimit"
                      disabled={flexibleShift}
                      className={`border-gray-300 text-center block w-full mt-1 shadow-sm focus:ring-blue focus:border-blue text-sm rounded-3xl`}
                    />
                  </div>
                </>
              )}
              <div className={`${flexibleShift ? 'col-span-4 lg:col-span-4' : 'col-span-4 lg:col-span-2'}`}>
                <select
                  id="city"
                  name="city"
                  autoComplete="city"
                  onChange={(e) => setCityId(e.target.value)}
                  value={cityId}
                  className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue focus:border-blue sm:text-sm rounded-3xl">
                  <option value={''}>Select a city</option>
                  {cities &&
                    cities.map((city) => (
                      <option value={city.id} key={city.id}>
                        {city.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
            <div className="flex justify-center">
              <Button
                text="Create Shift"
                rounded
                disabled={
                  (flexibleShift ? !flexibleDate || !cityId : startDate === null || endDate === null || !cityId.length || !driverLimit.length) || submitting
                }
                onClick={handleSubmit}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateShiftPanel;
