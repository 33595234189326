import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../../Shared/hooks/useAxiosPrivate';
import { createAdvancedBonus, updateAdvancedBonus } from '../../../../api/services/BonusService';
import AdvancedBonusFields from './AdvancedBonusFields';

const AdvancedBonusForm = ({ closeModal, getBonuses, type, bonus }) => {
  const [bonusId, setBonusId] = useState('');
  const [bonusName, setBonusName] = useState('');
  const [intervals, setIntervals] = useState([]);
  const [active, setActive] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newIntervals, setNewIntervals] = useState([
    {
      minDistance: '',
      maxDistance: '',
      amount: ''
    }
  ]);

  const errRef = useRef();
  const createBonusUrl = createAdvancedBonus();
  const updateBonusUrl = updateAdvancedBonus(bonusId);
  const { user } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    setErrorMsg('');
  }, [bonusName]);

  useEffect(() => {
    if (type === 'Edit' && bonus) {
      setBonusId(bonus.id);
      setBonusName(bonus.name);
      setActive(bonus.active);
      setIntervals(bonus.intervals);
      console.log(bonus);
    }
  }, [bonus]);

  const clearFields = () => {
    setBonusName('');
    setErrorMsg('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    // console.log(bonusName, intervals);
    const data = {
      name: bonusName,
      criteria: 'Distance',
      fleetId: user?.fleetId,
      intervals: newIntervals
    };

    console.log(newIntervals);

    try {
      if (type === 'Edit') {
        await axiosPrivate.put(updateBonusUrl, data);
        toast.success('Edited!');
      } else {
        console.log('created');
        await axiosPrivate.post(createBonusUrl, data);
        toast.success('Created!');
      }

      getBonuses();
      clearFields();
      closeModal();
    } catch (err) {
      console.log(err.response);
      setErrorMsg('Error happened, please try again');
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateBonusUrl, { active: !active });
      toast.success('Status changed!');
      getBonuses();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 md:mt-0">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid w-full grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="bonus-name" className="block text-sm font-medium text-gray-700">
                    Bonus Name
                  </label>
                  <input
                    type="text"
                    name="bonus-name"
                    id="bonus-name"
                    onChange={(e) => setBonusName(e.target.value)}
                    value={bonusName}
                    autoComplete="bonus-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="criteria" className="block text-sm font-medium text-gray-700">
                    Bonus Criteria
                  </label>
                  <input
                    name="criteria"
                    id="criteria"
                    value={'Distance'}
                    disabled
                    autoComplete="criteria"
                    className="block w-full px-3 py-2 mt-1 text-gray-400 bg-white border border-gray-200 rounded-md shadow-sm cursor-not-allowed focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"></input>
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <FromFields advancedBonuses={[]} bonus={bonus} intervals={intervals} newIntervals={newIntervals} setNewIntervals={setNewIntervals} />
                </div>
              </div>
              <div className="flex justify-center mt-10 space-x-5">
                <Button
                  type="submit"
                  text="Submit"
                  disabled={
                    submitting ||
                    bonusName === '' ||
                    newIntervals.some((interval) => interval.amount === '' || interval.minDistance === '' || interval.maxDistance === '')
                  }
                />
                {type === 'Edit' && (
                  <Button
                    disabled={submitting}
                    text={active ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && bonusId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={bonusId}
            action={active ? 'deactivate' : 'activate'}
            name="bonus"
          />
        )}
      </div>
    </>
  );
};

export default AdvancedBonusForm;

const FromFields = ({ type, getAdvancedBonuses, intervals, newIntervals, setNewIntervals }) => {
  useEffect(() => {
    if (intervals.length > 0) {
      setNewIntervals(intervals);
    }
  }, [intervals]);

  const handleAddInterval = () => {
    setNewIntervals([...newIntervals, { amount: '', maxDistance: '', minDistance: '' }]);
  };

  const handleAmountChange = (e, index) => {
    const updatedIntervals = [...newIntervals];
    updatedIntervals[index].amount = e.target.value;
    setNewIntervals(updatedIntervals);
  };

  const handleMinDistanceChange = (e, index) => {
    const updatedIntervals = [...newIntervals];
    updatedIntervals[index].minDistance = e.target.value;
    setNewIntervals(updatedIntervals);
  };

  const handleMaxDistanceChange = (e, index) => {
    const updatedIntervals = [...newIntervals];
    updatedIntervals[index].maxDistance = e.target.value;
    setNewIntervals(updatedIntervals);
  };

  const handleDeleteInterval = (index) => {
    const updatedIntervals = [...newIntervals];
    updatedIntervals.splice(index, 1);
    setNewIntervals(updatedIntervals);
  };

  return (
    <>
      <div className="md:mt-0">
        <div className="overflow-hidden sm:rounded-md">
          <div className="">
            <div className="flex flex-col space-y-3">
              {newIntervals &&
                newIntervals?.length > 0 &&
                newIntervals?.map((item, index) => (
                  <AdvancedBonusFields
                    key={index}
                    id={index}
                    measure="km"
                    formVariant="Edit"
                    interval={item}
                    newIntervals={newIntervals}
                    getAdvancedBonuses={getAdvancedBonuses}
                    type={type}
                    addMore={handleAddInterval}
                    handleAmountChange={handleAmountChange}
                    handleMinDistanceChange={handleMinDistanceChange}
                    handleMaxDistanceChange={handleMaxDistanceChange}
                    handleDeleteInterval={handleDeleteInterval}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
