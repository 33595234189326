import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { createShippingPrice, deleteShippingPrice, updateShippingPrice } from '../../../api/services/PricingService';

const PricingCriteriaFields = ({ pricing, measure, getPricing, formVariant, type, cityId, branchId, clearFields }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [minimum, setMinimum] = useState(0);
  const [maximum, setMaximum] = useState(0);
  const [price, setPrice] = useState('');
  const [pricingId, setPricingId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const { user } = useAuth();
  const fleetId = user?.fleetId;

  useEffect(() => {
    if (pricing && formVariant === 'Edit') {
      setMinimum(pricing.minimum);
      setMaximum(pricing.maximum);
      setPrice(pricing.price);
      setPricingId(pricing.id);
    }
  }, [pricing]);

  const handleSave = async () => {
    setSubmitting(true);
    const editData = {
      minimum,
      maximum,
      price
    };
    const createData = {
      type,
      minimum,
      maximum,
      price,
      fleetId,
      cityFleetId: cityId || null,
      branchId: branchId || null
    };
    const updatePricingUrl = updateShippingPrice(pricingId);
    const createPricingUrl = createShippingPrice();
    try {
      if (formVariant === 'Edit') {
        const response = await axiosPrivate.put(updatePricingUrl, editData);
        console.log(response);
        setLoading(false);
        toast.success('Updated successfully');
      } else {
        const response = await axiosPrivate.post(createPricingUrl, createData);
        console.log(response);
        setLoading(false);
        toast.success('Created successfully');
        clearFields();
        getPricing();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong');
      toast.error('Something went wrong');
    }
    setSubmitting(false);
  };

  const handleDeletePricing = async (id) => {
    setSubmitting(true);
    const deletePricingUrl = deleteShippingPrice(id);
    try {
      const response = await axiosPrivate.delete(deletePricingUrl);
      console.log(response);
      setLoading(false);
      toast.success('Deleted successfully');
      getPricing();
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Something went wrong');
    }
    setSubmitting(false);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <div className="flex space-x-5">
      {type !== 'Base' && (
        <>
          <div className="">
            <label htmlFor="minimum" className="block text-sm font-medium text-center text-gray-600">
              From
            </label>
            <div className="flex items-center px-3 py-1 mt-1 space-x-1 border border-gray-300 shadow-sm w-max rounded-3xl">
              <input
                type="number"
                name="minimum"
                id="minimum"
                onChange={(e) => setMinimum(e.target.value)}
                value={minimum}
                min={0}
                autoComplete="minimum"
                className="block w-20 font-medium text-gray-500 border-none focus:ring-0 focus:border-blue text-md"
              />
              <span className="text-sm font-medium text-gray-500 ">{measure}</span>
            </div>
          </div>

          <div className="">
            <label htmlFor="maximum" className="block text-sm font-medium text-center text-gray-600">
              To
            </label>
            <div className="flex items-center px-3 py-1 mt-1 space-x-1 border border-gray-300 shadow-sm w-max rounded-3xl">
              <input
                type="number"
                name="maximum"
                id="maximum"
                min={minimum}
                onChange={(e) => setMaximum(e.target.value)}
                value={maximum}
                autoComplete="maximum"
                className="block w-20 font-medium text-gray-500 border-none focus:ring-0 focus:border-blue text-md"
              />
              <span className="text-sm font-medium text-gray-500">{measure}</span>
            </div>
          </div>
        </>
      )}

      <div className="">
        <div className="flex items-center justify-center space-x-1">
          <label htmlFor="price" className="block text-sm font-medium text-center text-gray-600">
            Price
          </label>
        </div>
        <div className="flex items-center px-3 py-1 mt-1 space-x-1 border border-gray-300 shadow-sm w-max rounded-3xl">
          <input
            type="number"
            name="price"
            id="price"
            min={0}
            onChange={(e) => setPrice(e.target.value)}
            value={price}
            autoComplete="price"
            className="block w-20 font-medium text-gray-500 border-none focus:ring-0 focus:border-blue text-md"
          />
          <span className="text-sm font-medium text-gray-500">Credits</span>
        </div>
      </div>

      <div className="flex self-end space-x-2">
        <Button
          text="Save"
          disabled={
            ((formVariant === 'Add' || 'Edit') && type === 'Base' && !price) ||
            ((formVariant === 'Edit' || 'Add') && type !== 'Base' && (minimum < 0 || !maximum || !price)) ||
            submitting
          }
          onClick={() => {
            handleSave();
          }}
          bg="bg-green-600"
          border="border-green-600"
          textColor="text-white"
          rounded
        />
        {formVariant === 'Edit' && (
          <Button
            text="Delete"
            onClick={toggleDeleteModal}
            disabled={submitting}
            bg="bg-white"
            border="border-red-600"
            textColor={`${submitting ? 'text-white' : 'text-red-600'}`}
            rounded
          />
        )}
      </div>
      <ConfirmDeleteModal
        cancel={toggleDeleteModal}
        disabled={submitting}
        isOpen={deleteModal}
        remove={() => handleDeletePricing(pricing.id)}
        action="delete"
        name="criteria"
      />
    </div>
  );
};

export default PricingCriteriaFields;
