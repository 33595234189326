import React, { useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../Shared/components/Button';
import useAuth from '../../../Shared/hooks/useAuth';
import { sendEmail } from '../../api/services/EmailService';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const ContactForm = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const sendEmailUrl = sendEmail();
  const axiosPrivate = useAxiosPrivate();

  const { user } = useAuth();
  const errRef = useRef();

  const clearFields = () => {
    setSubject('');
    setMessage('');
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const data = {
      name: user?.firstName + ' ' + user?.lastName,
      from: user?.email,
      subject,
      text: message
    };
    try {
      const response = await axiosPrivate.post(sendEmailUrl, data);
      console.log(response.data);

      clearFields();
      toast.success('Email sent!');
    } catch (error) {
      console.log(error);
      setErrorMsg('Something went wrong! Please try again later.');
    }
    setSubmitting(false);
  };

  return (
    <div className="w-full mx-auto">
      <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
        {errorMsg}
      </p>
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <div className="overflow-hidden shadow sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12">
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  readOnly
                  disabled
                  value={user?.firstName + ' ' + user?.lastName}
                  autoComplete="name"
                  className="block w-full mt-1 text-gray-400 border-gray-200 rounded-md shadow-sm focus:ring-0 focus:border-0 hover:cursor-not-allowed sm:text-sm"
                />
              </div>
              <div className="col-span-12">
                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                  Your Email
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  disabled
                  value={user?.email}
                  required
                  readOnly
                  className="block w-full mt-1 text-gray-400 border-gray-200 rounded-md shadow-sm focus:ring-0 focus:border-0 hover:cursor-not-allowed sm:text-sm"
                />
              </div>

              <div className="col-span-12">
                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                  Subject*
                </label>
                <input
                  id="subject"
                  name="subject"
                  type="text"
                  autoComplete="subject"
                  onChange={(e) => setSubject(e.target.value)}
                  value={subject}
                  required
                  placeholder="Email subject"
                  className="block w-full mt-1 text-gray-500 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                />
              </div>

              <div className="col-span-12">
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message*
                </label>
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  autoComplete="message"
                  placeholder="Write your enquiry here..."
                  className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue h-44 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex justify-center mt-6 space-x-5">
              <Button text="Submit" rounded small width="w-full" onClick={handleSubmit} disabled={!message.length || submitting} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
