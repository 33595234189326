import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Button from '../../../../Shared/components/Button';
import DynamicIcon from '../../../../Shared/components/DynamicIcon';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import ConfirmModal from '../../../../Shared/components/Modals/ConfirmModal';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';
import useAuth from '../../../../Shared/hooks/useAuth';
import PurchaseDetails from './PurchaseDetails';

const Credit = ({ credits, price, discount, contactButton, currency }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [purchaseModal, setPurchaseModal] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const businessId = user?.businessId;
  const businessEmail = user?.email;

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const togglePurchaseModal = () => {
    setPurchaseModal(!purchaseModal);
  };

  const calcPriceWithVat = (price) => {
    return (price + price * 0.25).toFixed(2);
  };

  const priceWithVat = calcPriceWithVat(price);

  return (
    <div className="mt-10 ml-5 text-center rounded-xl min-w-min w-36">
      <div className={`${price === 0 ? 'bg-llYellow border-llYellow text-gray-800' : 'bg-blue border-blue text-white'} py-4 rounded-xl h-3/5`}>
        <p className="mt-1 text-2xl font-bold uppercase">
          {formatNumber(credits)} {price === 0 && '+'}
        </p>
        <p className="text-xl">credits</p>
        {discount && (
          <div className="flex items-center justify-center py-2 mx-2 mt-3 space-x-1 text-gray-800 rounded-3xl bg-llYellow">
            <DynamicIcon icon="TagIcon" width="w-5" height="h-5" />
            <p>{discount}% Off</p>
          </div>
        )}
      </div>
      <div className="flex flex-col p-2 py-4 bg-white">
        {price === 0 ? (
          <span className="text-lg">-</span>
        ) : (
          <p className="text-lg">
            {formatNumber(price)} {currency}
          </p>
        )}
        {price !== 0 ? <p className="text-xs">(price excl. VAT)</p> : <span className="text-xs text-white">-</span>}
      </div>
      <div className="w-full">
        {contactButton ? (
          <Button
            text="Contact us"
            width="w-full"
            bg="bg-llYellow"
            border="border-llYellow"
            textColor="text-gray-800"
            textSize="text-sm"
            onClick={() => navigate('/business/contact')}
          />
        ) : (
          <Button text="Select" textSize="text-sm" width="w-full" onClick={toggleConfirmationModal} />
        )}
      </div>
      {confirmationModal && (
        <ConfirmModal
          cancel={toggleConfirmationModal}
          isOpen={confirmationModal}
          confirm={togglePurchaseModal}
          action={'purchase'}
          name={`package of ${credits} credits for ${formatNumber(price)} ${currency} + VAT`}
        />
      )}
      {purchaseModal && (
        <BaseModal
          closeModal={togglePurchaseModal}
          isOpen={purchaseModal}
          title={`Buying ${credits} credits for ${formatNumber(price)} ${currency} + VAT`}
          body={
            <PurchaseDetails
              closeModal={togglePurchaseModal}
              isOpen={purchaseModal}
              purchase={{ credits: credits, price: priceWithVat, businessId, businessEmail, currency }}
              priceWithoutVat={price}
            />
          }
        />
      )}
    </div>
  );
};

export default Credit;
