const getUserStatus = (user) => {
  if (user?.approved) {
    return 'active';
  } else if (!user?.approved && user?.inviteTokenExpiresAt !== null) {
    return 'pending';
  } else if (!user?.approved && user?.inviteTokenExpiresAt === null) {
    return 'deactivated';
  } else {
    return '---';
  }
};

export default getUserStatus;
