import React, { useEffect, useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getCitiesByFleetId } from '../../api/services/CityService';
import PricingTable from '../../components/Sales/Pricing/PricingTable';

const Pricing = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [cities, setCities] = useState([]);

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const citiesUrl = getCitiesByFleetId(fleetId);

  useEffect(() => {
    setError('');
    getCities();
  }, []);

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(citiesUrl);
      console.log(response.data);
      setCities(response.data);
      if (response.data.length === 0) {
        setError('No pricing registered');
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError('Something went wrong');
    }
  };
  return (
    <div className="flex flex-col w-full m-auto lg:max-w-5xl">
      <Heading text="Register pricing" />
      <PricingTable cities={cities} loading={loading} error={error} />
    </div>
  );
};

export default Pricing;
