import { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';

const StatusFilter = () => {
  const [statusFilter, setStatusFilter] = useState('All');

  const [searchParams, setSearchParams] = useSearchParams();

  const handleStatusFilter = (status) => {
    if (status === 'Active') {
      searchParams.set('active', true);
    } else if (status === 'Deactivated') {
      searchParams.set('active', false);
    } else {
      searchParams.delete('active');
    }
    setSearchParams(searchParams);
    setStatusFilter(status);
  };

  const statusFilterList = ['All', 'Active', 'Deactivated'];

  return (
    <div className="flex items-center space-x-3 z-[49]">
      <h2 className="whitespace-nowrap">Select status:</h2>
      <div className="relative z-10">
        <Listbox value={statusFilter} onChange={handleStatusFilter}>
          <div className="relative mt-1">
            <Listbox.Button className="relative py-2 pl-3 pr-10 text-left text-white bg-gray-500 shadow-md cursor-pointer w-fit rounded-3xl focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
              <span className="block truncate">{statusFilter}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {statusFilterList.map((status, statusIdx) => (
                  <Listbox.Option
                    key={statusIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 border-b border-gray-200 pr-10 ${
                        active ? 'bg-yellow-400 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={status}>
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{status}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-amber-600">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export default StatusFilter;
