import { useEffect, useState } from 'react';

import Loader from '../../../Shared/components/Loader';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getBranchesByBusinessId } from '../../api/services/BranchService';
import BranchItem from './BranchItem';

const BranchList = () => {
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const id = user?.businessId;
  const branchesUrl = getBranchesByBusinessId(id);

  useEffect(() => {
    setError('');
    getBranches();
  }, []);

  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get(branchesUrl);
      console.log(response.data);
      if (response.data.length === 0) {
        setError('No branches have been created so far');
      } else {
        setBranches(response.data);
        console.log(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <div className="grid grid-cols-12 gap-10">
          {branches &&
            branches.map((branch) => {
              return (
                <div key={branch.id} className="col-span-12 md:col-span-6 xl:col-span-4">
                  <BranchItem
                    name={branch.name}
                    city={branch.city}
                    address={branch.address}
                    postCode={branch.postCode}
                    phoneNumber={branch.phoneNumber}
                    email={branch.email}
                    id={branch.id}
                    fleetId={branch.fleetId ? branch.fleetId : null}
                    fleetName={branch.fleetName ? branch.fleetName : null}
                    getBranches={getBranches}
                  />
                </div>
              );
            })}
        </div>
      )}
    </>
  );
};

export default BranchList;
