import { useContext, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../../Shared/api/axios.js';
import Button from '../../../../Shared/components/Button.jsx';
import Loader from '../../../../Shared/components/Loader.jsx';
import useAuth from '../../../../Shared/hooks/useAuth.js';
import { createDeliveries } from '../../../api/services/DeliveryService.js';
import { getDistance } from '../../../api/services/DistanceService.js';
import { calculateOrderPrice } from '../../../api/services/PricingService.js';
import { DeliveryContext } from '../../../contexts/DeliveryContext.js';
import DeliveryStepper from './elements/DeliveryStepper.jsx';
import DeliveryDetails from './sections/DeliveryDetails.jsx';
import RepeatOrder from './sections/RepeatOrder.jsx';
import Summary from './sections/Summary.jsx';

const DeliveryModal = ({ closeModal, business }) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [distanceText, setDistanceText] = useState('');
  const [distanceValue, setDistanceValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingPricing, setLoadingPricing] = useState(false);
  const [pricing, setPricing] = useState({});
  const [submitting, setSubmitting] = useState(false);

  const [mainOrder, setMainOrder] = useState({});
  const [creditError, setCreditError] = useState(false);
  const [additionalOrders, setAdditionalOrders] = useState([]);
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  const [activeStep, setActiveStep] = useState(1);

  const errRef = useRef();
  const { user } = useAuth();
  const {
    basicPickupDetails,
    customPickupDetails,
    deliveryDetails,
    pickupType,
    setBasicPickupDetails,
    setCustomPickupDetails,
    setDeliveryDetails,
    setSubmitted
  } = useContext(DeliveryContext);

  const createDeliveriesUrl = createDeliveries();
  const getDistanceUrl = getDistance();
  const getPricingUrl = calculateOrderPrice();
  const businessId = user?.businessId;

  const calculateDistance = async () => {
    setLoading(true);
    try {
      const branchLatitude = pickupType === 'custom' ? customPickupDetails?.latitude : basicPickupDetails?.branch.latitude;
      const branchLongitude = pickupType === 'custom' ? customPickupDetails?.longitude : basicPickupDetails?.branch.longitude;
      const deliveryLatitude = deliveryDetails.receiver.latitude;
      const deliveryLongitude = deliveryDetails.receiver.longitude;
      const response = await axiosPrivate.post(getDistanceUrl, { branchLatitude, branchLongitude, deliveryLatitude, deliveryLongitude });

      setDistanceText(response.data.text);
      setDistanceValue(response.data.value);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const calculateDeliveryPrice = async () => {
    setLoadingPricing(true);
    try {
      const fleetId = basicPickupDetails.branch.fleetId;
      const branchId = basicPickupDetails.branch.id;
      const cityId = basicPickupDetails.cityFleetId;
      const distance = distanceValue;
      const weight = basicPickupDetails.weight;
      const response = await axiosPrivate.post(getPricingUrl, { fleetId, branchId, cityId, distance, weight });

      setPricing(response.data);
      setLoadingPricing(false);
    } catch (error) {
      console.log(error);
      setLoadingPricing(false);
    }
  };

  useEffect(() => {
    if (basicPickupDetails && customPickupDetails && deliveryDetails) {
      if (
        (basicPickupDetails.branch.latitude &&
          basicPickupDetails.branch.longitude &&
          deliveryDetails.receiver.latitude &&
          deliveryDetails.receiver.longitude) ||
        (pickupType === 'custom' &&
          customPickupDetails.latitude &&
          customPickupDetails.longitude &&
          deliveryDetails.receiver.latitude &&
          deliveryDetails.receiver.longitude)
      ) {
        calculateDistance();
      }
    }
  }, [basicPickupDetails, customPickupDetails, deliveryDetails]);

  useEffect(() => {
    if (basicPickupDetails && customPickupDetails && deliveryDetails && distanceValue > 0) {
      setMainOrder({
        businessId,
        fleetId: basicPickupDetails?.branch?.fleetId,
        branchName: basicPickupDetails?.branch?.name,
        numberOfPackages: basicPickupDetails?.packageCount,
        pickupTime: basicPickupDetails?.pickupTime,
        deliveryTime: basicPickupDetails?.deliveryTime,
        branchId: basicPickupDetails?.branch?.id,
        cityFleetId: basicPickupDetails?.cityFleetId,
        weight: basicPickupDetails?.weight,
        addressId: deliveryDetails?.receiver.id,
        customerPhoneNumber: deliveryDetails?.receiver.phoneNumber,
        customerBusinessName: deliveryDetails?.receiver.businessName,
        customerFullName: deliveryDetails?.receiver.fullName,
        deliveryAddress: deliveryDetails?.receiver.address,
        deliveryPostCode: deliveryDetails?.receiver.postCode,
        deliveryCity: deliveryDetails?.receiver.city,
        branchLatitude: pickupType === 'custom' ? customPickupDetails?.latitude : basicPickupDetails?.branch.latitude,
        branchLongitude: pickupType === 'custom' ? customPickupDetails?.longitude : basicPickupDetails?.branch.longitude,
        branchCity: pickupType === 'custom' ? customPickupDetails?.city : basicPickupDetails?.branch.city,
        branchAddress: pickupType === 'custom' ? customPickupDetails?.address : basicPickupDetails?.branch.address,
        branchPostCode: pickupType === 'custom' ? customPickupDetails?.postCode : basicPickupDetails?.branch.postCode,
        deliveryLatitude: deliveryDetails?.receiver.latitude,
        deliveryLongitude: deliveryDetails?.receiver.longitude,
        distance: distanceValue,
        baseFee: pricing.baseFee,
        distanceFee: pricing.distanceFee,
        weightFee: pricing.weightFee,
        totalFee: pricing.totalFee,
        serviceFee: pricing.serviceFee,
        notes: basicPickupDetails?.notes?.length > 0 ? basicPickupDetails.notes : null
      });
    }
  }, [basicPickupDetails, customPickupDetails, deliveryDetails, distanceValue, pricing]);

  useEffect(() => {
    if (distanceValue > 0 && !business?.isFreeAccount) {
      calculateDeliveryPrice();
    }
  }, [distanceValue]);

  useEffect(() => {
    if (mainOrder) {
      setConfirmDisabled(false);
    }

    if (additionalOrders.length > 0) {
      const emptyDeliveryTime = additionalOrders.some((order) => order.deliveryTime === '');
      const emptyPickupTime = additionalOrders.some((order) => order.pickupTime === '');

      if (emptyDeliveryTime || emptyPickupTime) {
        setConfirmDisabled(true);
      } else {
        setConfirmDisabled(false);
      }
    }

    if (pricing.totalFee * allOrderCount > business?.credits) {
      setCreditError(true);
      setConfirmDisabled(true);
    } else {
      setCreditError(false);
    }
  }, [mainOrder, additionalOrders]);

  const handleSubmit = async () => {
    setSubmitting(true);

    const data = {
      ...mainOrder
    };

    const allOrders = [data, ...additionalOrders];

    try {
      await axiosPrivate.post(createDeliveriesUrl, { data: allOrders });
      setBasicPickupDetails({});
      setCustomPickupDetails({});
      setDeliveryDetails({});
      setSubmitted(true);
      closeModal();
      toast.success(`Created ${allOrders.length} order(s)!`);
    } catch (err) {
      if (err.response?.status === 400) {
        setErrorMsg('Insufficient funds...');
      } else {
        setErrorMsg('Something went wrong');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const allOrderCount = additionalOrders.length + 1;

  const pickupLocation =
    pickupType === 'custom'
      ? `${customPickupDetails?.address}, ${customPickupDetails.city}, ${customPickupDetails.postCode}`
      : `${basicPickupDetails?.branch?.address}, ${basicPickupDetails?.branch?.city}, ${basicPickupDetails?.branch?.postCode}`;

  const nextButtonText = (() => {
    if (activeStep === 3) {
      return 'send';
    }

    if (activeStep === 2 && !additionalOrders.length) {
      return 'skip';
    }

    return 'next';
  })();

  const handleNext = () => {
    if (activeStep === 3) {
      handleSubmit();
    } else {
      setActiveStep((prevStep) => Math.min(prevStep + 1, 3));
    }
  };

  const handleBack = () => {
    if (activeStep === 1) {
      closeModal();
    } else {
      setActiveStep((prevStep) => Math.max(prevStep - 1, 1));
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-full my-5">
        <Loader loading={loading} />
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="flex items-center justify-center h-full my-5">
        <p ref={errRef} className="text-red-500">
          {errorMsg}
        </p>
      </div>
    );
  }

  return (
    <>
      <DeliveryStepper activeStep={activeStep} />

      {mainOrder && (
        <div>
          {activeStep === 1 && (
            <DeliveryDetails
              pickupLocation={pickupLocation}
              pickupTime={basicPickupDetails?.pickupTime}
              distance={distanceText}
              weight={basicPickupDetails?.weight}
              deliveryLocation={`${deliveryDetails?.receiver?.address}, ${deliveryDetails?.receiver?.city}, ${deliveryDetails?.receiver?.postCode}`}
              deliveryTime={basicPickupDetails?.deliveryTime}
              notes={basicPickupDetails?.notes}
              branchName={basicPickupDetails?.branch?.name}
              customerBusinessName={deliveryDetails?.receiver.businessName}
              customerName={deliveryDetails?.receiver.fullName}
              numberOfPackages={basicPickupDetails?.packageCount}
            />
          )}
          {activeStep === 2 && (
            <RepeatOrder
              minDate={basicPickupDetails?.pickupTime}
              mainOrder={mainOrder}
              additionalOrders={additionalOrders}
              setAdditionalOrders={setAdditionalOrders}
            />
          )}
          {activeStep === 3 && (
            <Summary
              pricing={pricing}
              orders={additionalOrders}
              isFreeAccount={business?.isFreeAccount}
              allOrderCount={allOrderCount}
              creditError={creditError}
              loading={loadingPricing}
            />
          )}
        </div>
      )}

      <div className="flex items-center justify-between space-x-5 mt-7">
        <Button text="back" bg="white" border="border-blue" textColor="text-blue" rounded onClick={handleBack} disabled={submitting} />
        <Button text={nextButtonText} rounded onClick={handleNext} disabled={submitting || (confirmDisabled && activeStep === 3)} />
      </div>
    </>
  );
};

export default DeliveryModal;
