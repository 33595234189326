import React from 'react';

import { NavLink, useMatch } from 'react-router-dom';

import Dropdown from '../../../Shared/components/Dropdown';
import permissionCheck from '../../../Shared/helpers/permissionCheck';
import useAuth from '../../../Shared/hooks/useAuth';

const BusinessNavigation = () => {
  const { user } = useAuth();

  const deliveryPath = useMatch({
    path: '/business/deliveries/:slug',
    exactly: false
  });

  const creditPath = useMatch({
    path: '/business/credits/:slug',
    exactly: false
  });

  const deliverySubPages = [
    {
      name: 'Create new',
      url: '/business/deliveries/new',
      action: 'deliveries-new:see',
      id: 1
    },
    {
      name: 'Delivery history',
      url: '/business/deliveries/history',
      action: 'deliveries-history:see',
      id: 2
    },
    {
      name: 'Manage deliveries',
      url: '/business/deliveries/manage',
      action: 'deliveries-manage:see',
      id: 3
    }
  ];

  const creditSubPages = [
    { name: 'Buy credits', url: '/business/credits/buy', action: 'credits-buy:see', id: 1 },
    { name: 'Purchase history', url: '/business/credits/history', action: 'credits-history:see', id: 2 }
  ];

  const check = (action) => {
    const permissionGranted = permissionCheck(action, user?.role, 'business');
    return permissionGranted;
  };

  const inactiveLinkStyle = 'text-white py-2 rounded-lg md:rounded-none px-5 py-4 bg-dark z-10 hover:bg-blue duration-300 transition-all ease-in-out';
  const activeLinkStyle = 'text-blue text-white font-bold rounded-lg md:rounded-none bg-blue px-5 py-4';
  const linkStyle = 'text-sm hover:cursor-pointer';

  return (
    <div className="flex flex-col flex-wrap items-center justify-center mb-5 space-x-0 space-y-2 uppercase md:space-y-0 md:-mb-5 md:flex-row lg:space-y-0 lg:rounded-xl lg:flex-row w-max">
      {check('overview:see') && (
        <NavLink
          to="/business/overview"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px] md:rounded-l-lg'}>
          <p className={linkStyle}>Overview</p>
        </NavLink>
      )}

      {check('deliveries:see') && <Dropdown path={deliveryPath} subPages={deliverySubPages} check={check} title="Deliveries" />}

      {check('credits:see') && <Dropdown path={creditPath} subPages={creditSubPages} check={check} title="Credits" />}

      {check('addresses:see') && (
        <NavLink
          to="/business/addresses"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Addresses</p>
        </NavLink>
      )}

      {check('fleet-connection:see') && (
        <NavLink
          to="/business/fleet-connection"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Fleet connection</p>
        </NavLink>
      )}

      {check('branches:see') && (
        <NavLink
          to="/business/branches"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Branches</p>
        </NavLink>
      )}

      {check('api-control:see') && user?.business?.isApiClient == true && (
        <NavLink
          to="/business/api-control"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Api control</p>
        </NavLink>
      )}

      {check('users:see') && (
        <NavLink
          to="/business/users"
          className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:border-r-[#48676D] md:border-r-[1px]'}>
          <p className={linkStyle}>Users</p>
        </NavLink>
      )}

      {check('contact:see') && (
        <NavLink to="/business/contact" className={({ isActive }) => (isActive ? activeLinkStyle : inactiveLinkStyle) + ' md:rounded-r-lg'}>
          <p className={linkStyle}>Contact</p>
        </NavLink>
      )}
    </div>
  );
};

export default BusinessNavigation;
