import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import RegisteredPill from '../../../../Shared/components/Pills/RegisteredPill';
import PricingCriteriaList from './PricingCriteriaList';

const PricingTable = ({ cities, loading, error }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [cityId, setCityId] = useState('');
  const [cityName, setCityName] = useState('');

  const headers = [
    { label: 'City Name', key: 1 },
    { label: 'Pricing', key: 2 },
    { label: 'Registered at', key: 3 },
    { label: 'Manage', key: 4 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {cities && (
            <tbody>
              {cities.map((city) => (
                <tr key={city.id} className="bg-white border-b whitespace-nowrap">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900">
                    {city.name}
                  </th>
                  <td className="px-6 py-4">
                    <RegisteredPill status={city.isPricingRegistered} />
                  </td>
                  <td className="px-6 py-4">{city.pricingRegisteredAt ? dayjs(city.pricingRegisteredAt).format('MMM DD, YYYY') : '-'}</td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text={city.isPricingRegistered == 1 ? 'Edit Pricing' : 'Add Pricing'}
                        rounded
                        bg="bg-blue"
                        border="border-blue"
                        onClick={() => {
                          setCityId(city.id);
                          setCityName(city.name);
                          toggleModal();
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title={`Editing ${cityName} Pricing`}
          body={<PricingCriteriaList closeModal={toggleModal} cityId={cityId} type="Edit" />}
        />
      )}
    </div>
  );
};

export default PricingTable;
