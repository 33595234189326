import Loader from '../../../../Shared/components/Loader';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';

const SalaryTable = ({ loading, error, salaries }) => {
  const headers = [
    { label: 'Driver Name', key: 1 },
    { label: 'Contract', key: 2 },
    { label: 'Shifts', key: 3 },
    { label: 'Orders', key: 4 },
    { label: 'Hours', key: 5 },
    { label: 'Paused hours', key: 6 },
    { label: 'Salary', key: 7 },
    { label: 'Bonus', key: 8 },
    { label: 'Fuel Bonus', key: 9 },
    { label: 'Distance Bonus', key: 10 },
    { label: 'Total', key: 11 }
  ];

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[700px] ">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(salaries) && (
              <tr className="font-bold text-gray-700 border-b whitespace-nowrap" key="total">
                <th scope="row" className="px-6 py-4 whitespace-nowrap ">
                  ALL DRIVER DATA:
                </th>
                <td className="px-6 py-4">---</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.shiftCount), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.orderCount), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.totalHours), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.totalPaused), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.salary), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.bonus), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.fuelBonus), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.distanceBonus), 0))}</td>
                <td className="px-6 py-4">{formatNumber(salaries.reduce((total, salary) => total + parseFloat(salary.totalSalary), 0))}</td>
              </tr>
            )}
            {salaries
              ? salaries.map((salary) => (
                  <tr className="bg-white border-b whitespace-nowrap" key={salary.id}>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                      {salary.firstName + ' ' + salary.lastName}
                    </th>
                    <td className="px-6 py-4">{salary.contractName}</td>
                    <td className="px-6 py-4">{formatNumber(salary.shiftCount)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.orderCount)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.totalHours)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.totalPaused)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.salary)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.bonus)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.fuelBonus)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.distanceBonus)}</td>
                    <td className="px-6 py-4">{formatNumber(salary.totalSalary)}</td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default SalaryTable;
