import { Fragment, useState } from 'react';

import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

//TODO: make it global
const Autocomplete = ({ values, handleSelect }) => {
  const [selected, setSelected] = useState('');
  const [query, setQuery] = useState('');

  const filteredValues =
    query === ''
      ? values
      : values.filter(
          (value) =>
            value?.fullName.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) ||
            value?.address.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')) ||
            value?.phoneNumber.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const handleChange = (event) => {
    setSelected(event);
    setQuery(event.fullName);
    handleSelect(event.id);
  };

  return (
    <div className="w-full">
      {values.length > 0 && (
        <Combobox value={selected} onChange={(e) => handleChange(e)}>
          <div className="relative mt-1">
            <div className="relative w-full text-left bg-white rounded-lg cursor-default focus:outline-none">
              <Combobox.Input
                className="block w-full mt-1 text-gray-700 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
                displayValue={(value) => value.fullName}
                onChange={(event) => setQuery(event.target.value)}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>
            </div>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
              <Combobox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredValues.length === 0 && query !== '' ? (
                  <div className="relative px-4 py-2 text-gray-700 cursor-default select-none">Nothing found...</div>
                ) : (
                  filteredValues.map((value) => (
                    <Combobox.Option
                      key={value.id}
                      className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue text-white' : 'text-gray-900'}`}
                      value={value}>
                      {({ selected, active }) => (
                        <>
                          <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                            {value.fullName}{' '}
                            <span className={`text-sm ${active ? 'bg-blue text-white' : 'text-gray-400'}`}>
                              {' '}
                              ({value.address}, {value.city} {value.postCode}, {value.phoneNumber}){' '}
                            </span>
                          </span>
                          {selected ? (
                            <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue'}`}>
                              <CheckIcon className="w-5 h-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      )}
    </div>
  );
};

export default Autocomplete;
