import { useEffect, useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getPurchaseHistoryByBusinessId } from '../../api/services/PurchaseService';
import PurchaseHistoryTable from '../../components/Credits/PurchaseHistory/PurchaseHistoryTable';

const PurchaseHistory = () => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const id = user?.businessId;
  const purchaseUrl = getPurchaseHistoryByBusinessId(id);

  useEffect(() => {
    setError('');
    getPurchaseHistory();
  }, []);

  const getPurchaseHistory = async () => {
    try {
      const response = await axiosPrivate.get(purchaseUrl);
      console.log('PURCHASES', response.data);
      if (response.data.length === 0) {
        setError('No purchases have been made so far');
      } else {
        setPurchases(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center mx-auto space-y-5">
        <Heading text="Your Credit purchases" uppercase />
        <div className="w-full lg:w-2/3">
          <PurchaseHistoryTable purchases={purchases} error={error} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default PurchaseHistory;
