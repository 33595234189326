import { useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { createCity } from '../../../api/services/CityService';

const CreateShiftPanel = ({ getCities }) => {
  const [name, setName] = useState('');
  const [postalCodes, setPostalCodes] = useState([]);
  const [country, setCountry] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const errRef = useRef();
  const axiosPrivate = useAxiosPrivate();

  const { user } = useAuth();

  const createCityUrl = createCity();
  const fleetId = user?.fleetId;

  const clearFields = () => {
    setName('');
    setPostalCodes([]);
    setCountry('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, fleetId, countryCode: "DK" };
    setSubmitting(true);

    try {
      const response = await axiosPrivate.post(createCityUrl, data);
      console.log(response);
      toast.success('Created!');
      getCities();
      clearFields();
    } catch (err) {
      console.log(err.response);
      if (err.response?.status === 422) {
        toast.error('Could not find such address!');
      } else {
        toast.error('Something went wrong!');
      }
    }
    setSubmitting(false);
  };

  return (
    <div className="">
      <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
        {errorMsg}
      </p>
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <div className="my-2 overflow-hidden shadow md:rounded-full rounded-2xl">
          <div className="flex flex-col items-center justify-center p-8 space-x-2 space-y-2 bg-white md:space-y-0 md:flex-row">
            <div className="flex items-center justify-center space-x-2">
              <p className="hidden mr-2 text-lg sm:block min-w-fit">Add a city:</p>
              <div className="grid items-center content-center grid-cols-12 gap-3">
                <div className="col-span-12 ">
                  <label htmlFor="receiver" className="block text-sm font-medium text-gray-700 sm:hidden">
                    Add a city*
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="City Name"
                    onChange={(e) => {
                      setName(e.target.value);
                      setErrorMsg('');
                    }}
                    value={name}
                    autoComplete="name"
                    className="block w-full mt-1 border-gray-300 shadow-sm focus:ring-blue focus:border-blue sm:text-sm rounded-3xl"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <Button type="submit" text="add city" rounded disabled={!name.length || submitting} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateShiftPanel;
