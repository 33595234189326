const DELIVERY_URL = '/delivery';

export const getAllDeliveriesTodayByFleetId = (fleetId) => {
  const url = `${DELIVERY_URL}/today/all/fleet/${fleetId}`;
  return url;
};

export const getOngoingDeliveriesTodayByFleetId = (fleetId) => {
  const url = `${DELIVERY_URL}/today/ongoing/fleet/${fleetId}`;
  return url;
};

export const getCompletedDeliveriesTodayByFleetId = (fleetId) => {
  const url = `${DELIVERY_URL}/today/completed/fleet/${fleetId}`;
  return url;
};

export const getPreordersByFleetId = (fleetId) => {
  const url = `${DELIVERY_URL}/preorders/fleet/${fleetId}`;
  return url;
};

export const getDeliveriesStatsByFleetId = (fleetId) => {
  const url = `${DELIVERY_URL}/stats/fleet/${fleetId}`;
  return url;
};

export const getDeliveriesByDriverId = (driverId) => {
  const url = `${DELIVERY_URL}/today/driver/${driverId}`;
  return url;
};

export const assignDeliveryToDriver = (orderId, driverId) => {
  const url = `${DELIVERY_URL}/assign/${orderId}/${driverId}`;
  return url;
};

export const unassignDeliveryFromDriver = (orderId, driverId) => {
  const url = `${DELIVERY_URL}/unassign/${orderId}/${driverId}`;
  return url;
};

export const createOrUpdateFleetNote = (orderId) => {
  const url = `${DELIVERY_URL}/note/fleet/${orderId}`;
  return url;
};
