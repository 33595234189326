import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import BonusCriteriaFilter from '../../../Shared/components/Filters/BonusCriteriaFilter';
import StatusFilter from '../../../Shared/components/Filters/StatusFilter';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getAdvancedBonusesByFleetId, getBonusesByFleetId } from '../../api/services/BonusService';
import AdvancedBonusForm from '../../components/Drivers/Bonuses/Advanced/AdvancedBonusForm';
import BonusForm from '../../components/Drivers/Bonuses/BonusForm';
import BonusTable from '../../components/Drivers/Bonuses/BonusTable';

const Bonuses = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bonuses, setBonuses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [isAdvancedModalOpen, setIsAdvancedModalOpen] = useState(false);
  const [advancedBonuses, setAdvancedBonuses] = useState([]);
  const [advancedBonusLoading, setAdvancedBonusLoading] = useState(false);
  const [advancedBonusError, setAdvancedBonusError] = useState('');

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const bonusesUrl = getBonusesByFleetId(fleetId, search);
  const advancedBonusesUrl = getAdvancedBonusesByFleetId(fleetId, search);

  useEffect(() => {
    setError('');
    getBonuses();
    getAdvancedBonuses();
  }, [search]);

  // gets all bonuses and filters out distance bonuses
  const getBonuses = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(bonusesUrl);
      setLoading(false);
      if (response.data.length === 0) {
        setError('No bonuses found');
      } else {
        const filteredBonuses = response?.data?.filter((bonus) => bonus.criteria !== 'Distance');
        setBonuses(filteredBonuses);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getAdvancedBonuses = async () => {
    setAdvancedBonusLoading(true);
    try {
      const response = await axiosPrivate.get(advancedBonusesUrl);
      setAdvancedBonusLoading(false);
      if (response.data.length === 0) {
        setAdvancedBonusError('No advanced bonuses');
      } else {
        setAdvancedBonuses(response.data);
        console.log('DISTANCE', response.data);
        setAdvancedBonusError('');
      }
    } catch (error) {
      console.log(error);
      setAdvancedBonusError('Something went wrong');
      setAdvancedBonusLoading(false);
    }
  };

  const toggleAdvancedModal = () => {
    setIsAdvancedModalOpen(!isAdvancedModalOpen);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col space-y-10">
      <div className="w-full m-auto lg:max-w-5xl">
        <div className="flex justify-center w-full sm:justify-end">
          <Button text="Add Bonus" onClick={toggleModal} rounded>
            <DynamicIcon icon="PlusCircleIcon" />
          </Button>
        </div>
        <div className="flex flex-col items-baseline justify-between mt-5 sm:flex-row sm:items-center">
          <Heading text="Created Bonuses" />
          {bonuses.length > 0 && (
            <div className="flex flex-col mt-2 space-y-2 sm:mt-0 sm:space-y-0 sm:space-x-4 sm:flex-row">
              <div className="z-30 flex items-center space-x-2">
                <BonusCriteriaFilter />
              </div>
              <div className="z-10 flex items-center space-x-2">
                <StatusFilter />
              </div>
            </div>
          )}
        </div>
        <BonusTable bonuses={bonuses} loading={loading} error={error} getBonuses={getBonuses} />
        {isOpen && (
          <BaseModal
            closeModal={toggleModal}
            isOpen={isOpen}
            title="Add Bonus"
            body={<BonusForm closeModal={toggleModal} getBonuses={getBonuses} type="Add" />}
          />
        )}
      </div>
      {/* ADVANCED */}
      <div className="w-full m-auto lg:max-w-5xl">
        <div className="flex flex-col items-baseline justify-between mt-5 sm:flex-row sm:items-center">
          <Heading text="Advanced Bonuses" />
          <Button text="Add Advanced Bonus" onClick={toggleAdvancedModal} rounded>
            <DynamicIcon icon="PlusCircleIcon" />
          </Button>
        </div>
        <BonusTable bonuses={advancedBonuses} loading={advancedBonusLoading} error={advancedBonusError} getBonuses={getAdvancedBonuses} advanced />
        {isAdvancedModalOpen && (
          <BaseModal
            closeModal={toggleAdvancedModal}
            isOpen={isAdvancedModalOpen}
            title="Add Advanced Bonus"
            body={<AdvancedBonusForm closeModal={toggleAdvancedModal} getBonuses={getAdvancedBonuses} type="Add" advanced />}
          />
        )}
      </div>
    </div>
  );
};

export default Bonuses;
