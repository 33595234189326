import React from 'react';

import ReactDOM from 'react-dom/client';
import './index.css';
import 'leaflet/dist/leaflet.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import App from './App';
import { DeliveryProvider } from './Business/contexts/DeliveryContext';
import { AuthProvider } from './Shared/context/AuthProvider';
import { NotificationProvider } from './Shared/context/NotificationProvider';
import { SocketProvider } from './Shared/context/Socket';

//disable logs - ONLY PRODUCTION!
if (process.env.NODE_ENV === 'production') {
  console.log = console.warn = console.error = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <AuthProvider>
      <SocketProvider>
        <DeliveryProvider>
          <NotificationProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </NotificationProvider>
        </DeliveryProvider>
      </SocketProvider>
    </AuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);
