const FLEET_URL = '/fleet';

export const getFleetById = (fleetId) => {
  const url = `${FLEET_URL}/${fleetId}`;
  return url;
};

export const updateFleet = (id) => {
  const url = `${FLEET_URL}/${id}`;
  return url;
};
