const CreditPill = ({ amount, bgColor, textColor }) => {
  return (
    <p
      className={`px-2 py-1 w-36 rounded-full whitespace-nowrap text-sm text-center font-bold ${bgColor ? bgColor : ' bg-gray-200'} ${
        textColor ? textColor : 'text-gray-700'
      }`}>
      {amount} {amount === 1 ? 'credit' : 'credits'}
    </p>
  );
};

export default CreditPill;
