import { useState } from 'react';

import Button from '../../../Shared/components/Button';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import ConnectFleetForm from './ConnectFleetForm';
import FleetDetailModal from './FleetDetails';

const BranchItem = ({ name, city, address, postCode, phoneNumber, email, fleetId, fleetName, getBranches, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [branchDetailsOpen, setBranchDetailsOpen] = useState(false);

  const [branchId, setBranchId] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleBranchDetailsModal = () => {
    setBranchDetailsOpen(!branchDetailsOpen);
  };

  return (
    <>
      <div className="">
        <h1 className="mb-2 text-xl font-bold text-center text-blue">{name}</h1>
        <div className="w-full p-10 transition-all duration-300 border-2 border-gray-500 rounded-3xl">
          <div className="grid justify-center grid-cols-12 gap-5">
            <div className="col-span-4 space-y-5 font-semibold">
              <p>City:</p>
              <p>Address:</p>
              <p>Postal code:</p>
              <p>Phone nr.:</p>
              <p>E-mail:</p>
            </div>
            <div className="col-span-8 space-y-5">
              <p>{city}</p>
              <p>{address}</p>
              <p>{postCode}</p>
              <p>{phoneNumber.length > 0 ? phoneNumber : '---'}</p>
              <p className="text-clip">{email.length > 0 ? email : '---'}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-3">
          {fleetId ? (
            <Button
              rounded
              text="Connected"
              smallText={fleetName}
              bg="bg-yellow-500"
              border="border-yellow-500"
              onClick={() => {
                setBranchId(id), toggleBranchDetailsModal();
              }}
            />
          ) : (
            <Button
              rounded
              text="Connect with fleet"
              bg="bg-blue"
              border="bg-blue"
              onClick={() => {
                setBranchId(id), toggleModal();
              }}
            />
          )}
        </div>
      </div>
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Enter Fleet Code"
          body={<ConnectFleetForm getBranches={getBranches} branchId={branchId} closeModal={toggleModal} />}
        />
      )}
      {branchDetailsOpen && (
        <BaseModal
          closeModal={toggleBranchDetailsModal}
          isOpen={branchDetailsOpen}
          body={<FleetDetailModal fleetId={fleetId} closeModal={toggleBranchDetailsModal} branchId={branchId} getBranches={getBranches} />}
          title="Fleet Information"
        />
      )}
    </>
  );
};

export default BranchItem;
