import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const [newOrderCount, setNewOrderCount] = useState(0);

  const resetOrderCount = () => {
    setNewOrderCount(0);
  };

  const increaseOrderCount = () => {
    setNewOrderCount((prev) => prev + 1);
  };

  return <NotificationContext.Provider value={{ newOrderCount, increaseOrderCount, resetOrderCount }}>{children}</NotificationContext.Provider>;
};

export default NotificationContext;
