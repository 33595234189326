import React from 'react';

const Alert = ({ variant, text }) => {
  const variantStyle = {
    success: 'bg-green-50 border-green-500 text-green-500',
    error: 'bg-red-50 border-red-500 text-red-500',
    warning: 'bg-yellow-50 border-yellow-500 text-yellow-500',
    info: 'bg-cyan-50 border-blue text-blue'
  };

  return (
    <div className={`flex items-center justify-center w-full p-2 space-x-2 text-center border rounded-md ${variantStyle[variant]}`}>
      <p>{text}</p>
    </div>
  );
};

export default Alert;
