import React, { useEffect, useRef, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
// import { getDriversByFleetIdAndCity } from '../../../api/services/DriverService';
import { getActiveDriversByFleetIdAndCity } from '../../../api/services/DriverService';
import { createDriverShift, getDriverShiftByShiftId } from '../../../api/services/DriverShiftService';
import { deleteShift, updateShift } from '../../../api/services/ShiftsService';
import ShiftDriverTable from './ShiftDriverTable';

const ShiftForm = ({ shift, getShifts, closeModal, type }) => {
  const [maxNumber, setMaxNumber] = useState(0);
  const [shiftId, setShiftId] = useState('');
  const [drivers, setDrivers] = useState([]);
  const [driver, setDriver] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [driverShifts, setDriverShifts] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const [driverError, setDriverError] = useState('');
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();

  const { city } = useParams();
  const { user } = useAuth();
  const fleetId = user?.fleetId;

  const updateShiftUrl = updateShift(shiftId);
  const deleteShiftUrl = deleteShift(shiftId);
  // const driverUrl = getDriversByFleetIdAndCity(fleetId, city);
  const driverUrl = getActiveDriversByFleetIdAndCity(fleetId, city);
  const createDriverShiftUrl = createDriverShift();
  const getDriverShiftsUrl = getDriverShiftByShiftId(shiftId);

  useEffect(() => {
    if (shift) {
      setMaxNumber(shift.maxNumber);
      setShiftId(shift.id);
    }
  }, [shift]);

  useEffect(() => {
    if (shiftId && city) {
      getDrivers();
      getDriverShifts();
    }
  }, [shiftId, city]);

  const getDrivers = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(driverUrl);
      setLoading(false);
      setDrivers(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setDriverError('Something went wrong');
      setLoading(false);
    }
  };

  const getDriverShifts = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(getDriverShiftsUrl);
      setLoading(false);
      console.log(response.data);
      setDriverShifts(response.data);
      if (response.data?.length === 0) {
        // setErrorMsg('No drivers assigned to shift far.');
        // setLoading(false);
      }
      setLoading(false);
      setErrorMsg('');
    } catch (error) {
      console.log(error);
      setLoading(false);
      setErrorMsg('Something went wrong');
    }
  };

  const clearFields = () => {
    setMaxNumber(0);
    setShiftId('');
    setDriver('');
    setSelectedDriver('');
    setErrorMsg('');
    setLoading(false);
    setDriverError('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const filteredDrivers = drivers.filter((driver) => {
    const driverShift = driverShifts.find((driverShift) => driverShift.driverId === driver.id);
    return !driverShift;
  });

  const handleDelete = async () => {
    setSubmitting(true);
    try {
      const response = await axiosPrivate.delete(deleteShiftUrl);
      console.log(response);
      getShifts();
      closeModal();
      toast.success('Deleted!');
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const data = {
      maxNumber
    };
    try {
      const response = await axiosPrivate.put(updateShiftUrl, data);
      console.log(response);
      getShifts();
      clearFields();
      closeModal();
      toast.success('Edited!');
    } catch (err) {
      console.log(err.response);
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrorMsg('Invalid Credentials');
      } else {
        setErrorMsg('Something went wrong');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const handleDriverAdd = async () => {
    const data = {
      contractId: selectedDriver.contractId,
      driverId: selectedDriver.id,
      fleetId: selectedDriver.fleetId,
      shiftId,
      clockedIn: false
    };
    setSubmitting(true);
    try {
      if (driverShifts.length >= maxNumber) {
        setErrorMsg('Max number of drivers reached');
        toast.error('Failed to add driver...');
        return;
      }

      const response = await axiosPrivate.post(createDriverShiftUrl, data);
      console.log(response);
      getDriverShifts();
      setDriver('');
      setSelectedDriver('');
      // closeModal();
      toast.success('Added Driver!');
    } catch (err) {
      console.log(err);
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 422) {
        setErrorMsg('This shift is overlapping with another shift of this driver.');
      } else {
        setErrorMsg('Something went wrong :(');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const handleDriverSelect = (e) => {
    setDriver(e.target.value);
    const driverId = e.target.value;
    const driver = drivers.find((u) => u.id === driverId);
    setSelectedDriver(driver);
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-2">
                  <label htmlFor="maxNumber" className="block text-sm font-medium text-gray-700">
                    Driver Limit:*
                  </label>
                  <input
                    type="number"
                    name="maxNumber"
                    id="maxNumber"
                    onChange={(e) => setMaxNumber(e.target.value)}
                    value={maxNumber}
                    autoComplete="maxNumber"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
                <div className="flex items-end justify-end col-span-4 sm:col-span-2">
                  <Button text="Edit Limit" onClick={handleSubmit} disabled={!maxNumber || submitting} />
                </div>
                {driverShifts && driverShifts.length > 0 && shiftId && (
                  <div className="col-span-4 h-max">
                    <ShiftDriverTable driverShifts={driverShifts} shiftId={shiftId} getDriverShifts={getDriverShifts} />
                  </div>
                )}
              </div>

              <hr className="w-full mx-auto my-5 bg-gray-200 border border-gray-200" />
              {loading ? (
                <div className="flex items-center justify-center w-full p-5">
                  <Loader />
                </div>
              ) : driverError ? (
                <p className="m-5 text-xl text-gray-500">{driverError}</p>
              ) : (
                <div className="grid grid-cols-4 gap-6">
                  <div className="col-span-4 sm:col-span-2">
                    <label htmlFor="driver" className="block text-sm font-medium text-gray-700">
                      Add driver
                    </label>
                    <select
                      id="driver"
                      name="driver"
                      autoComplete="driver"
                      onChange={(e) => handleDriverSelect(e)}
                      value={driver}
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option value={''}>Select driver</option>
                      {filteredDrivers &&
                        filteredDrivers.map((driver) => (
                          <option value={driver.id} key={driver.id}>
                            {driver.firstName} {driver.lastName}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="flex items-end justify-end col-span-4 sm:col-span-2">
                    <Button text="Add" onClick={handleDriverAdd} disabled={!driver || submitting} />
                  </div>
                </div>
              )}
              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                  disabled={submitting}
                />
                {type === 'Edit' && <Button disabled={submitting} text="Delete Shift" onClick={toggleDeleteModal} bg="bg-red-500" border="border-red-500" />}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && shiftId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDelete}
            id={shiftId}
            action="delete"
            name="shift"
          />
        )}
      </div>
    </>
  );
};

export default ShiftForm;
