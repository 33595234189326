import { useEffect, useState } from 'react';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getApiKeysByBusinessId } from '../../api/services/ApiKeyService';
import ApiControlForm from '../../components/ApiControl/ApiControlForm';
import ApiControlHeader from '../../components/ApiControl/ApiControlHeader';
import ApiControlTable from '../../components/ApiControl/ApiControlTable';

const ApiControl = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const businessId = user?.businessId;

  const getApiKeysUrl = getApiKeysByBusinessId(businessId);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    getApiKeys();
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const getApiKeys = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(getApiKeysUrl);
      console.log('KEYS', response.data);
      if (response.data.length === 0) {
        setError('No API keys have been created so far');
      } else {
        setApiKeys(response.data);
      }
      setLoading(false);
    } catch (error) {
      setError('Something went wrong..');
      setLoading(false);
    }
  };

  return (
    <div className="text-center">
      <Heading text="API Control" />
      <div className="flex flex-col items-center justify-center mt-5 space-x-3 lg:flex-row bg-llYellow rounded-xl ">
        <div className="w-11/12 p-4 md:w-3/4 lg:w-1/2">
          <ApiControlHeader />
        </div>
      </div>
      <div className="w-full m-auto mt-10 lg:max-w-6xl">
        <div className="flex flex-col-reverse items-baseline space-y-5 sm:justify-between sm:space-y-0 sm:flex-row sm:items-center">
          <Heading text="Manage API keys" />
          <Button text="Add api key" onClick={toggleModal} rounded>
            <DynamicIcon icon="PlusCircleIcon" />
          </Button>
        </div>
        <ApiControlTable apiKeys={apiKeys} loading={loading} error={error} getApiKeys={getApiKeys} />
      </div>
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Add New Api Key"
          body={<ApiControlForm getApiKeys={getApiKeys} closeModal={toggleModal} />}
        />
      )}
    </div>
  );
};

export default ApiControl;
