import { useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../Shared/api/axios';
import Button from '../../../Shared/components/Button';
import { assignFleetToBranch } from '../../api/services/BranchService';

const ConnectFleetForm = ({ closeModal, getBranches, branchId }) => {
  const [code, setCode] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const errRef = useRef();

  const id = branchId ? branchId : null;
  const assignFleetUrl = assignFleetToBranch(id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axiosPrivate.put(assignFleetUrl, { code });
      console.log(response);

      getBranches();
      setCode('');
      closeModal();
      setSubmitting(false);
      toast.success('Connected successfully!');
    } catch (err) {
      // console.log(err);
      if (err.response?.status === 409) {
        toast.error('Wrong code, try again...!');
      } else {
        setErrorMsg('Something went wrong...');
      }
      errRef.current.focus();
      setSubmitting(false);
    }
  };

  return (
    <>
      <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
        {errorMsg}
      </p>
      <form action="#" method="POST" onSubmit={handleSubmit}>
        <div className="overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <div className="grid grid-cols-4 gap-6">
              <div className="col-span-4 sm:col-span-4">
                <input
                  type="text"
                  name="code"
                  id="code"
                  onChange={(e) => setCode(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === ' ') {
                      e.preventDefault(); // Prevent entering spaces
                    }
                  }}
                  value={code}
                  autoComplete="code"
                  className="block w-full p-5 mt-1 text-2xl text-center border-gray-300 rounded-full shadow-sm focus:ring-blue focus:border-blue"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4 space-x-5">
          <Button text="Confirm" rounded type="submit" disabled={!code.length || submitting} />
        </div>
      </form>
    </>
  );
};

export default ConnectFleetForm;
