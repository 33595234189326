import React, { useEffect, useMemo, useState } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, MapIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import CustomSwitch from '../../../Shared/components/CustomSwitch';
import DeliveryDetails from '../../../Shared/components/DeliveryDetails';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import Tooltip from '../../../Shared/components/Tooltip';
import showDeliveryStatus from '../../../Shared/helpers/showDeliveryStatus';
import showOrderRef from '../../../Shared/helpers/showOrderRef';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDeliveriesByDriverId } from '../../api/services/DeliveryService';
import { pauseDriverShift } from '../../api/services/DriverShiftService';
import DriverMapAll from './DriverMapAll';
import DriverMapDetails from './DriverMapDetails';

const headers = [
  { label: 'Order ref.', key: 1 },
  { label: 'Pickup', key: 2 },
  { label: 'EST Pickup', key: 3 },
  { label: 'Delivery', key: 4 },
  { label: 'EST Delivery', key: 5 },
  { label: 'Distance', key: 6 },
  { label: 'Status', key: 7 }
];

const DeliveryDrivers = ({ loading, error, drivers, cityLatitude, cityLongitude, fleetId, getDrivers }) => {
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);

  const [infoModal, setInfoModal] = useState(false);

  const [delivery, setDelivery] = useState(null);
  const [driver, setDriver] = useState(null);
  const [driverList, setDriverList] = useState(JSON.parse(localStorage.getItem('driverList')) || []);
  const [driverMapModal, setDriverMapModal] = useState(false);
  const [allDriverMapModal, setAllDriverMapModal] = useState(false);

  const [driversWithOrders, setDriversWithOrders] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    localStorage.removeItem('driverList');

    const fetchData = async () => {
      const updatedDriversWithOrders = [];

      if (drivers?.length > 0) {
        for (const driver of drivers) {
          setLoadingDrivers(true);

          try {
            const orders = await getDriverDeliveries(driver.id);

            const driverId = driverList.find((id) => id.id === driver.id);
            if (!driverId) {
              driverList.push({ id: driver.id, open: false });
              localStorage.setItem('driverList', JSON.stringify(driverList));
              setDriverList(JSON.parse(localStorage.getItem('driverList')));
            }
            driver.orders = orders;
            setDriversWithOrders([...driversWithOrders, driver]);
          } catch (error) {
            console.log(error);
          } finally {
            setLoadingDrivers(false);
          }
        }
      }

      setDriversWithOrders(updatedDriversWithOrders);
    };

    fetchData();
  }, [drivers]);

  const toggleListState = (driverId) => {
    // const driverList = JSON.parse(localStorage.getItem('driverList'));
    const driver = driverList.find((id) => id.id === driverId);
    driver.open = !driver.open;
    localStorage.setItem('driverList', JSON.stringify(driverList));
    setDriverList(JSON.parse(localStorage.getItem('driverList')));
  };

  // check if the item from driver list is open or not
  const checkListState = (driverId) => {
    // const driverList = JSON.parse(localStorage.getItem('driverList'));
    const driver = driverList.find((id) => id.id === driverId);
    const state = driver?.open;
    return state;
  };

  const getDriverDeliveries = async (driverId) => {
    setLoadingDeliveries(true);
    try {
      const response = await axiosPrivate.get(getDeliveriesByDriverId(driverId));
      setLoadingDeliveries(false);
      return response.data;
    } catch (error) {
      console.log(error);
      setLoadingDeliveries(false);
    }
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  const toggleDriverMapModal = () => {
    setDriverMapModal(!driverMapModal);
  };

  const toggleAllDriverMapModal = () => {
    setAllDriverMapModal(!allDriverMapModal);
  };

  const toggleDriverPause = async (driver) => {
    console.log(driver);
    // setSubmitting(true);
    try {
      if (driver?.paused === 0) {
        console.log('not paused');
        const response = await axiosPrivate.post(pauseDriverShift(driver?.id, driver?.driverShiftId));
        console.log(response.data);
        getDrivers();
        toast.success(`Driver ${driver.name} is now paused!`);
      } else if (driver?.paused === 1) {
        const response = await axiosPrivate.put(pauseDriverShift(driver?.id, driver?.driverShiftId));
        console.log(response.data);
        getDrivers();
        toast.success(`Driver ${driver.name} is now resumed!`);
      } else {
        toast.error('Something went wrong');
      }
    } catch (err) {
      console.log(err);
      toast.error('Something went wrong');
    }
    // setSubmitting(false);
  };

  const renderDriverStatus = (status) => {
    switch (status) {
      case 1:
        return <div className="w-2 h-2 bg-red-500 rounded-full"></div>;
      case 0:
        return <div className="w-2 h-2 bg-green-500 rounded-full"></div>;
      default:
        return <div className="w-2 h-2 bg-gray-500 rounded-full"></div>;
    }
  };

  const sortedDrivers = useMemo(() => {
    if (!drivers && loadingDrivers) {
      return null;
    }

    return drivers.slice().sort((a, b) => {
      const activeOrdersA = a.orders?.filter((order) => order?.status !== -1 && order?.status !== 3);
      const activeOrdersB = b.orders?.filter((order) => order?.status !== -1 && order?.status !== 3);

      // Sort by the number of active orders in descending order
      const activeOrdersComparison = activeOrdersB?.length - activeOrdersA?.length;

      // If the number of active orders is the same, sort by paused in ascending order
      const pausedComparison = a?.paused - b?.paused;

      // Combine the two comparisons
      return activeOrdersComparison || pausedComparison;
    });
  }, [drivers, loadingDrivers]);

  return (
    <>
      <div className="border border-gray-200 rounded-2xl">
        <div className="w-full h-full p-3 bg-white rounded-2xl">
          {loading || loadingDeliveries ? (
            <div className="flex justify-center w-full p-5 ">
              <Loader />
            </div>
          ) : error ? (
            <p className="m-5 text-xl text-gray-500">{error}</p>
          ) : (
            <div className="w-full p-2 rounded-2xl xl:px-2">
              <div className="flex mx-auto mb-2 w-fit">
                <div
                  onClick={() => {
                    toggleAllDriverMapModal();
                  }}
                  className="flex items-center justify-center px-4 py-2 space-x-3 text-sm font-medium text-left border-2 rounded-full cursor-pointer bg-blue border-blue hover:opacity-90 outline-4">
                  <div className="text-white uppercase">all drivers on map</div>
                  <MapIcon className="w-5 h-5 mx-auto text-white" />
                </div>
              </div>
              {!!sortedDrivers &&
                sortedDrivers?.map((driver) => {
                  return (
                    <Disclosure as="div" key={driver?.id} className="p-1" defaultOpen={checkListState(driver?.id)}>
                      {/* {({ open }) => ( */}
                      <>
                        <div className="flex flex-col items-center justify-between w-full space-y-2 sm:space-y-0 sm:space-x-4 sm:flex-row">
                          <Disclosure.Button
                            onClick={() => toggleListState(driver.id)}
                            className="flex justify-between w-full px-3 py-2 text-sm font-medium text-left bg-white border-2 border-gray-200 rounded-full sm:w-10/12 hover:bg-gray-50 outline-4">
                            <div className="flex items-center space-x-2">
                              {renderDriverStatus(driver?.paused)}
                              <Tooltip message={`${driver?.phoneNumber}`}>
                                <DynamicIcon icon="PhoneIcon" width="w-4" height="h-4" />
                              </Tooltip>
                              {/* <span>{driver?.name?.length > 15 ? driver?.name?.substring(0, 15) + '...' : driver?.name}</span> */}
                              <span>{driver.name}</span>
                              <img src={require(`../../../../public/icons/${driver?.vehicleId?.toString()}.svg`)} alt="" width={20} height={20} />
                              <span>Ongoing orders: {driver.ongoing || '0'} </span>
                            </div>
                            <ChevronUpIcon className={`${checkListState(driver.id) === true ? 'rotate-180 transform' : ''} h-5 w-5 text-blue`} />
                          </Disclosure.Button>
                          <div className="hidden w-full sm:justify-end sm:w-1/12 sm:flex">
                            <div
                              onClick={() => {
                                setDriver(driver);
                                toggleDriverMapModal();
                              }}
                              className="flex items-center justify-center px-2 py-2 text-sm font-medium text-left bg-white border-2 border-gray-200 rounded-full hover:cursor-pointer hover:opacity-90 hover:bg-gray-50 outline-4">
                              <MapIcon className="w-5 h-5 mx-auto text-blue" />
                            </div>
                          </div>
                          <div className="hidden w-full sm:justify-start sm:w-1/12 sm:flex">
                            <CustomSwitch
                              small
                              enabled={driver.paused}
                              onChange={() => toggleDriverPause(driver)}
                              enabledColor="bg-red-500"
                              disabledColor="bg-green-500"
                            />
                          </div>
                        </div>

                        <Disclosure.Panel
                          open={checkListState(driver.id)}
                          className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl"
                          as="div">
                          {loadingDrivers ? (
                            <div className="flex justify-center w-full p-5">
                              <Loader small />
                            </div>
                          ) : driver.orders && driver.orders.length > 0 ? (
                            <table className="w-full overflow-scroll text-sm text-left text-gray-500 ">
                              <thead className="text-xs text-left text-gray-700 uppercase bg-gray-50">
                                <tr>
                                  {headers.map((header) => (
                                    <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                                      {header.label}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              {driver.orders.map((order) => {
                                return (
                                  <tbody key={order.id}>
                                    <tr className="bg-white whitespace-nowrap">
                                      <td
                                        className="px-6 py-4 font-bold cursor-pointer w-max hover:opacity-80"
                                        onClick={() => {
                                          toggleInfoModal(), setDelivery(order);
                                        }}>
                                        {showOrderRef(order.orderReference, order.status)}
                                      </td>
                                      <td className="px-6 py-4">{dayjs(order.pickupTime).format('HH:mm')}</td>
                                      <td className="px-6 py-4">{order.estimatedPickupTime ? `${dayjs(order.estimatedPickupTime).format('HH:mm')}` : '-'}</td>
                                      <td className="px-6 py-4">{dayjs(order.deliveryTime).format('HH:mm')}</td>
                                      <td className="px-6 py-4">
                                        {order.estimatedDeliveryTime ? `${dayjs(order.estimatedDeliveryTime).format('HH:mm')}` : '-'}
                                      </td>
                                      <td className="px-6 py-4">{order.distance}</td>
                                      <td className="px-6 py-4">{showDeliveryStatus(order.status)}</td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          ) : (
                            <div className="flex justify-center w-full p-5">
                              <p className="text-xl text-gray-500">No orders yet</p>
                            </div>
                          )}
                        </Disclosure.Panel>
                      </>
                    </Disclosure>
                  );
                })}
            </div>
          )}
        </div>
      </div>
      {infoModal && delivery && (
        <BaseModal
          closeModal={toggleInfoModal}
          isOpen={infoModal}
          title="Delivery Details"
          body={<DeliveryDetails closeModal={toggleInfoModal} delivery={delivery} manualAssign={true} drivers={drivers} />}
        />
      )}

      {driverMapModal && driver && (
        <BaseModal
          closeModal={toggleDriverMapModal}
          isOpen={driverMapModal}
          title={`Driver Order Map And Location`}
          big={true}
          body={<DriverMapDetails closeModal={toggleDriverMapModal} driver={driver} />}
        />
      )}

      {allDriverMapModal && drivers && (
        <BaseModal
          closeModal={toggleAllDriverMapModal}
          isOpen={allDriverMapModal}
          title={`ALL Driver Live Location`}
          big={true}
          body={<DriverMapAll drivers={drivers} cityLatitude={cityLatitude} cityLongitude={cityLongitude} fleetId={fleetId} />}
        />
      )}
    </>
  );
};

export default DeliveryDrivers;
