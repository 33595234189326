import { refresh as refreshRequest } from '../api/services/Auth';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth, setUser } = useAuth();

  const refresh = async () => {
    const response = await refreshRequest();

    if (response) {
      setAuth((previous) => {
        // console.log(JSON.stringify(previous));
        // console.log(response.data.token);
        // console.log(response.data);
        return {
          ...previous,
          token: response.data.token
        };
      });
      setUser(response.data);

      return response.data.token;
    }
  };
  return refresh;
};

export default useRefreshToken;
