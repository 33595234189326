import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../../Shared/api/axios';
import Button from '../../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import { createBonus, updateBonus } from '../../../api/services/BonusService';

const BonusForm = ({ closeModal, getBonuses, type, bonus }) => {
  const [bonusId, setBonusId] = useState('');
  const [bonusName, setBonusName] = useState('');
  const [bonusCriteria, setBonusCriteria] = useState('Order');
  const [bonusAmount, setBonusAmount] = useState('');
  const [bonusGoal, setBonusGoal] = useState('');
  const [active, setActive] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const errRef = useRef();
  const createBonusUrl = createBonus();
  const updateBonusUrl = updateBonus(bonusId);
  const { user } = useAuth();

  useEffect(() => {
    setErrorMsg('');
  }, [bonusName, bonusCriteria, bonusAmount, bonusGoal]);

  useEffect(() => {
    if (type === 'Edit' && bonus) {
      setBonusId(bonus.id);
      setBonusName(bonus.name);
      setBonusCriteria(bonus.criteria);
      setBonusAmount(bonus.amount);
      setBonusGoal(bonus.goal);
      setActive(bonus.active);
    }
  }, [bonus]);

  const clearFields = () => {
    setBonusName('');
    setBonusCriteria('Order');
    setBonusAmount('');
    setBonusGoal('');
    setErrorMsg('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      name: bonusName,
      criteria: bonusCriteria,
      amount: bonusAmount,
      goal: bonusGoal,
      fleetId: user?.fleetId
    };

    try {
      if (type === 'Edit') {
        await axiosPrivate.put(updateBonusUrl, data);
        toast.success('Edited!');
      } else {
        await axiosPrivate.post(createBonusUrl, data);
        toast.success('Created!');
      }

      getBonuses();
      clearFields();
      closeModal();
    } catch (err) {
      console.log(err.response);
      setErrorMsg('Error happened, please try again');
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateBonusUrl, { active: !active });
      toast.success('Status changed!');
      getBonuses();
      closeModal();
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 md:mt-0">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid w-full grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="bonus-name" className="block text-sm font-medium text-gray-700">
                    Bonus Name
                  </label>
                  <input
                    type="text"
                    name="bonus-name"
                    id="bonus-name"
                    onChange={(e) => setBonusName(e.target.value)}
                    value={bonusName}
                    autoComplete="bonus-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="criteria" className="block text-sm font-medium text-gray-700">
                    Bonus Criteria
                  </label>
                  <select
                    name="criteria"
                    id="criteria"
                    onChange={(e) => setBonusCriteria(e.target.value)}
                    value={bonusCriteria}
                    autoComplete="criteria"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                    <option>Order</option>
                    <option>Shift</option>
                    <option>Km</option>
                  </select>
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="bonus-goal" className="block text-sm font-medium text-gray-700">
                    Bonus Goal {`(is greater than)`}
                  </label>
                  <input
                    type="number"
                    name="bonus-goal"
                    id="bonus-goal"
                    onChange={(e) => setBonusGoal(e.target.value)}
                    value={bonusGoal}
                    autoComplete="bonus-goal"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="bonus-amount" className="block text-sm font-medium text-gray-700">
                    Bonus Amount {`(then bonus is)`}
                  </label>
                  <input
                    type="number"
                    name="bonus-amount"
                    id="bonus-amount"
                    onChange={(e) => setBonusAmount(e.target.value)}
                    value={bonusAmount}
                    autoComplete="address-level2"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
              </div>
              <div className="flex justify-center mt-4 space-x-5">
                <Button
                  type="submit"
                  text="Submit"
                  onClick={handleSubmit}
                  disabled={!bonusAmount || !bonusCriteria || !bonusGoal || !bonusName || submitting}
                />
                {type === 'Edit' && (
                  <Button
                    disabled={submitting}
                    text={active ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && bonusId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={bonusId}
            action={active ? 'deactivate' : 'activate'}
            name="bonus"
          />
        )}
      </div>
    </>
  );
};

export default BonusForm;
