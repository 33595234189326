const PAYMENT_URL = '/payment';

export const createPaymentIntent = () => {
  const url = `${PAYMENT_URL}/create-payment-intent`;
  return url;
};

export const getProductList = () => {
  const url = `${PAYMENT_URL}/products/all`;
  return url;
};
