import { useEffect, useRef, useState, Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { toast } from 'react-toastify';

import { updateCity } from '../../../Fleet/api/services/CityService.js';
import useAuth from '../../hooks/useAuth.js';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Button from '../Button';
import DynamicIcon from '../DynamicIcon.jsx';

const CityDetailsForm = ({ closeModal, city, getCities }) => {
  const [loading, setLoading] = useState(false);

  const [lockingHorizon, setLockingHorizon] = useState('');
  const [beforeShift, setBeforeShift] = useState('');
  const [afterShift, setAfterShift] = useState('');

  const [errorMsg, setErrorMsg] = useState('');
  const [cityId, setCityId] = useState('');
  const axiosPrivate = useAxiosPrivate();

  const errRef = useRef();

  const { user } = useAuth();
  const updateCityUrl = updateCity(cityId);
  const fleetId = user?.fleetId;

  useEffect(() => {
    if (city) {
      setCityId(city.id);
      setLockingHorizon(city.lockingHorizon);
      setBeforeShift(city.shiftEarly);
      setAfterShift(city.shiftLate);
    }
  }, [city]);

  const clearFields = () => {
    setLockingHorizon('');
    setErrorMsg('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axiosPrivate.put(updateCityUrl, {
        lockingHorizon,
        shiftEarly: beforeShift,
        shiftLate: afterShift,
        fleetId
      });
      console.log(response);
      getCities();
      clearFields();
      closeModal();
      toast.success('City settings saved!');
    } catch (err) {
      console.log(err.response);
      setErrorMsg('Something went wrong...');
      errRef.current.focus();
    }
    setLoading(false);
  };

  return (
    <>
      <div className="mt-5">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-visible shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-2">
                  <div className="flex items-center space-x-1">
                    <label htmlFor="lockingHorizon" className="block text-sm font-medium text-gray-700">
                      Locking Horizon (minutes)
                    </label>
                    <div>
                      <InfoButton
                        headline="Locking Horizon"
                        message="The number of minutes before the pickup time that the order is locked for the driver. Advised to be between 15 to 45 minutes."
                      />
                    </div>
                  </div>
                  <input
                    type="number"
                    min={1}
                    name="lockingHorizon"
                    id="lockingHorizon"
                    onChange={(e) => setLockingHorizon(e.target.value)}
                    value={lockingHorizon}
                    autoComplete="lockingHorizon"
                    className="block w-full mt-1 font-semibold text-center border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <div className="flex items-center space-x-1">
                    <label htmlFor="beforeShift" className="block text-sm font-medium text-gray-700">
                      Assign Before Shift (minutes)
                    </label>
                    <div>
                      <InfoButton
                        headline="Assign Before Shift"
                        message="The number of minutes order can be potentially assigned before driver starts his shift. Advised to be between 0 to 15 minutes."
                      />
                    </div>
                  </div>
                  <input
                    type="number"
                    min={1}
                    name="beforeShift"
                    id="beforeShift"
                    onChange={(e) => setBeforeShift(e.target.value)}
                    value={beforeShift}
                    autoComplete="beforeShift"
                    className="block w-full mt-1 font-semibold text-center border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
                <div className="col-span-4 sm:col-span-2">
                  <div className="flex items-center space-x-1">
                    <label htmlFor="afterShift" className="block text-sm font-medium text-gray-700">
                      Assign After Shift (minutes)
                    </label>
                    <div>
                      <InfoButton
                        headline="Assign After Shift"
                        message="The number of minutes order can be potentially assigned after driver starts his shift. Advised to be between 0 to 15 minutes."
                      />
                    </div>
                  </div>
                  <input
                    type="number"
                    min={1}
                    name="afterShift"
                    id="afterShift"
                    onChange={(e) => setAfterShift(e.target.value)}
                    value={afterShift}
                    autoComplete="afterShift"
                    className="block w-full mt-1 font-semibold text-center border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button text="Cancel" onClick={closeModal} bg="bg-white" border="border-blue" textColor="text-blue" rounded />
                <Button type="submit" text="Save" onClick={handleSubmit} disabled={!lockingHorizon || lockingHorizon < 1 || loading} rounded />
              </div>
            </div>
          </div>
        </form>

        <div>
          <p className="mt-5 text-sm text-left text-gray-600">
            <span className="font-semibold">NOTE:</span> These values should be altered carefully and only changed if you know what you are doing.
          </p>
        </div>
      </div>
    </>
  );
};

export default CityDetailsForm;

const InfoButton = ({ headline, message }) => {
  return (
    <Popover className="relative p-0 m-0">
      {() => (
        <>
          <Popover.Button>
            <DynamicIcon icon="InformationCircleIcon" width="w-4" height="h-4" />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute z-10 w-64 mt-2 transform -translate-x-1/2 -left-4 min-w-sm sm:-left-14">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
                <div className="relative p-2 bg-gray-200">
                  <h3 className="text-sm font-medium text-gray-900">{headline}</h3>
                  <p className="text-xs text-gray-600">{message}</p>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};
