import Loader from '../../../Shared/components/Loader';
import StatusPill from '../../../Shared/components/Pills/StatusPill';

const CustomerAnalytics = ({ customers, loading, error }) => {
  const headers = [
    { label: 'Company Name', key: 1 },
    { label: 'City', key: 3 },
    { label: 'Fleet connection', key: 4 },
    { label: 'Total Orders', key: 5 },
    { label: 'Avg. distance', key: 6 },
    { label: 'Total sales dkk', key: 7 },
    { label: 'Avg. sales dkk', key: 8 }
  ];

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[500px]">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {customers.length > 0 && (
            <tbody>
              {customers?.map((customer) => (
                <tr key={customer.id} className="bg-white border-b whitespace-nowrap">
                  <td className="px-6 py-4">{customer.name}</td>
                  <td className="px-6 py-4">{customer.city}</td>
                  <td className="px-6 py-4 text-green-500">
                    <StatusPill status={customer?.fleetId !== null ? 1 : 0} />
                  </td>
                  <td className="px-6 py-4">{customer.orderCount || 0}</td>
                  <td className="px-6 py-4">{customer.avgDist ? customer.avgDist.toFixed() : 0}</td>
                  <td className="px-6 py-4">{customer.totalFeeSum ? customer.totalFeeSum.toFixed(1) : 0}</td>
                  <td className="px-6 py-4">{customer.totalFeeAvg ? customer.totalFeeAvg.toFixed(1) : 0}</td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
    </div>
  );
};

export default CustomerAnalytics;
