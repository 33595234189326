import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import ShiftModal from './ShiftModal';

const ShiftTable = ({ loading, error, shifts, getShifts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [shift, setShift] = useState(null);

  console.log('shifts', shifts);

  const headers = [
    { label: 'City', key: 1 },
    { label: 'Date', key: 2 },
    { label: 'Driver limit', key: 3 },
    { label: 'Joined drivers', key: 4 },
    { label: 'Manage', key: 5 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 mb-5 overflow-x-auto border border-gray-200 rounded-3xl max-h-[700px] sm:max-h-[900px]">
      {loading ? (
        <div className="flex justify-center w-full p-5 my-5">
          <Loader small={true} />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {shifts && (
            <tbody>
              {shifts.map((shift) => (
                <tr key={shift.id} className="bg-white border-b whitespace-nowrap">
                  <td className="px-6 py-4">{shift.cityName}</td>
                  <td className="px-6 py-4">
                    {dayjs(shift.startDate).format('MMM DD')}, {dayjs(shift.startDate).format('HH:mm')} - {dayjs(shift.endDate).format('MMM DD')},{' '}
                    {dayjs(shift.endDate).format('HH:mm')}
                  </td>
                  <td className="px-6 py-4">{shift.maxNumber === 99999 ? 'Flexible' : shift.maxNumber}</td>
                  <td className="px-6 py-4">{shift.joinedDrivers}</td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        rounded
                        bg="bg-blue"
                        border="border-blue"
                        onClick={() => {
                          toggleModal(), setShift(shift);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && <ShiftModal closeModal={toggleModal} isOpen={isOpen} getShifts={getShifts} shift={shift} type="Edit" />}
    </div>
  );
};

export default ShiftTable;
