import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import '../../../index.css';

import Loader from '../../../Shared/components/Loader';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getAllDriverLocation } from '../../api/services/DriverService';

const DriverMapAll = ({ drivers, cityLatitude, cityLongitude, fleetId }) => {
  const [cityCoordinates, setCityCoordinates] = useState(null);
  const [locations, setLocations] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllLocationUrl = getAllDriverLocation(fleetId);
  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    if (fleetId) {
      setCityCoordinates({ lat: cityLatitude, lng: cityLongitude });

      const fetchDriverAndSetInterval = () => {
        fetchAll(); // Fetch driver location immediately

        const id = setInterval(() => {
          if (drivers?.length > 0) {
            fetchAll(); // Fetch driver location with interval
          }
        }, 10000);

        return id;
      };

      const intervalId = fetchDriverAndSetInterval();

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [fleetId, drivers]);

  const fetchAll = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(getAllLocationUrl);
      if (response.data?.length === 0) {
        console.log('No drivers found', response);
        setLocations([]);
      } else {
        console.log('ALLLLLL', response.data);
        setLocations(response.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const driverIcon = new L.Icon({
    iconUrl: require('../../../assets/images/driver.png'),
    iconRetinaUrl: require('../../../assets/images/driver.png'),
    popupAnchor: [0, -10],
    iconSize: new L.Point(45, 45)
  });

  return (
    <>
      {/* <div className="h-full map"> */}
      {locations?.length === 0 && (
        <div className="flex items-center justify-center w-full my-1">
          <p className="text-gray-500 text-md">🔴 No drivers currently sharing thier location </p>
        </div>
      )}
      {locations?.length !== 0 && (
        <div className="flex items-center justify-center w-full my-1">
          <p className="text-gray-500 text-md">🟢 {locations?.length} driver(s) currently sharing location </p>
        </div>
      )}
      <div className="w-full h-[600px] map">
        {!cityCoordinates ? (
          <div className="flex items-center justify-center w-full h-full">
            <Loader />
          </div>
        ) : (
          <MapContainer center={cityCoordinates} zoom={12} scrollWheelZoom={false}>
            <Recenter lat={cityCoordinates?.lat} lng={cityCoordinates?.lng} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {locations &&
              locations.map((location, index) => (
                <Marker key={index} icon={driverIcon} position={[location?.position?.coords?.latitude, location?.position?.coords?.longitude]}>
                  <Popup>
                    <p>Driver: {location?.name}</p>
                    <p>Last updated: {dayjs(location?.position?.timestamp).format('HH:mm')}</p>
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
        )}
      </div>

      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
        integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
        crossOrigin=""
      />
      <script src="https://unpkg.com/leaflet@1.9.2/dist/leaflet.js" integrity="sha256-o9N1jGDZrf5tS+Ft4gbIK7mYMipq9lqpVJ91xHSyKhg=" crossOrigin=""></script>
    </>
  );
};

export default DriverMapAll;

const Recenter = ({ lat, lng }) => {
  const map = useMap();
  useEffect(() => {
    map.setView([lat, lng]);
  }, [lat, lng]);

  return null;
};
