import React from 'react';

import DynamicIcon from '../../../Shared/components/DynamicIcon';

const ContactList = () => {
  return (
    <div className="flex flex-col md:flex-row md:flex-wrap gap-y-10">
      {/* <ContactItem title="Support" email="support@lastlap.delivery" icon="ChatBubbleOvalLeftEllipsisIcon" />
      <ContactItem title="Sales" email="sales@lastlap.delivery" icon="CurrencyDollarIcon" /> */}
      <ContactItem title="Info" email="info@lastlap.delivery" icon="InformationCircleIcon" />
    </div>
  );
};

export default ContactList;

const ContactItem = ({ title, email, phone, icon }) => {
  return (
    <div className="flex w-1/2 space-x-4">
      <div className="p-5 text-white rounded-md shadow-md bg-blue h-max shadow-gray-300">
        <DynamicIcon icon={icon} height="h-8" width="w-8" />
      </div>
      <div className="">
        <h1 className="text-xl font-medium text-gray-700">{title}</h1>
        <h1 className="">{email}</h1>
        <h1>{phone && phone}</h1>
      </div>
    </div>
  );
};
