import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../../Shared/api/axios';
import Button from '../../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import { updateCity } from '../../../api/services/CityService';

const EditCityForm = ({ closeModal, getCities, type, city }) => {
  const [name, setName] = useState('');
  const [postalCodes, setPostalCodes] = useState([]);
  // TODO: clear this unusued state and form fields
  const [country, setCountry] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [active, setActive] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [cityId, setCityId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const errRef = useRef();
  const updateCityUrl = updateCity(cityId);
  const { user } = useAuth();

  const fleetId = user?.fleetId;

  useEffect(() => {
    if (city) {
      setCityId(city.id);
      setName(city.name);
      setPostalCodes(city.postalCodes);
      setCountry(city.country);
      setActive(city.active);
    }
  }, [city]);

  const clearFields = () => {
    setName('');
    setPostalCodes([]);
    setCountry('');
    setErrorMsg('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateCityUrl, { active: !active });
      getCities();
      closeModal();
      toast.success('Status changed!');
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axiosPrivate.put(updateCityUrl, {
        name,
        countryCode: user?.countryCode,
        // postalCodes,
        // country,
        fleetId
      });
      console.log(response);
      getCities();
      clearFields();
      closeModal();
      toast.success('Edited!');
    } catch (err) {
      console.log(err.response);
      setErrorMsg('Something went wrong');
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="city-name" className="block text-sm font-medium text-gray-700">
                    City Name
                  </label>
                  <input
                    type="text"
                    name="city-name"
                    id="city-name"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    autoComplete="city-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                {/* <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="criteria" className="block text-sm font-medium text-gray-700">
                    Bonus Criteria
                  </label>
                  <select
                    name="criteria"
                    id="criteria"
                    onChange={(e) => setPostalCodes(e.target.value)}
                    value={postalCodes}
                    autoComplete="criteria"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                    <option>Order</option>
                    <option>Shift</option>
                    <option>Km</option>
                  </select>
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="bonus-goal" className="block text-sm font-medium text-gray-700">
                    Bonus Goal {`(is greater than)`}
                  </label>
                  <input
                    type="number"
                    name="bonus-goal"
                    id="bonus-goal"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                    autoComplete="bonus-goal"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div> */}
              </div>
              {/* <div className="flex justify-center mt-4">
                <Button text="Submit" onClick={handleSubmit} disabled={!name.length} />
              </div> */}
              <div className="flex justify-center mt-6 space-x-5">
                {/* <Button text="Submit" onClick={handleSubmit} disabled={!contractName.length} /> */}
                <Button
                  text="Cancel"
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                  disabled={submitting}
                />
                <Button type="submit" text="Submit" onClick={handleSubmit} disabled={!name.length || submitting} />
                {type === 'Edit' && (
                  <Button
                    text={active ? 'Deactivate' : 'Activate'}
                    // onClick={handleDeactivate}
                    disabled={submitting}
                    onClick={toggleDeleteModal}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && cityId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={cityId}
            action={active ? 'deactivate' : 'activate'}
            name="city"
          />
        )}
      </div>
    </>
  );
};

export default EditCityForm;
