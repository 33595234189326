import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../Shared/components/Button';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../Shared/components/Pills/StatusPill';
import AddressForm from './AddressForm';

const AddressTable = ({ addresses, loading, error, getAddresses }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState(null);

  const headers = [
    { label: 'Receiver Name', key: 1 },
    { label: 'Business Name', key: 2 },
    { label: 'Address', key: 3 },
    { label: 'Date of Registry', key: 4 },
    { label: 'Status', key: 6 },
    { label: 'Manage', key: 7 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-3 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {addresses && (
            <tbody>
              {addresses.map((address) => (
                <tr key={address.id} className="bg-white border-b ">
                  <td className="px-3 py-2 whitespace-nowrap">{address.fullName}</td>
                  <td className="px-3 py-2">{address.businessName ? address.businessName : '-'}</td>
                  <td className="px-3 py-2">{address.address}</td>
                  <td className="px-3 py-2 whitespace-nowrap">{dayjs(address.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-3 py-4">
                    <StatusPill status={address.active} />
                  </td>
                  <td className="px-3 py-2">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        bg="bg-blue"
                        border="border-blue"
                        rounded
                        onClick={() => {
                          toggleModal(), setAddress(address);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Edit Address"
          body={<AddressForm type="Edit" closeModal={toggleModal} getAddresses={getAddresses} address={address} />}
        />
      )}
    </div>
  );
};

export default AddressTable;
