import { useState } from 'react';

import dayjs from 'dayjs';

import NoteFormFleet from '../../../../Fleet/components/LiveDeliveries/NoteFormFleet';
import DeliveryDetails from '../../../../Shared/components/DeliveryDetails';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import TextModal from '../../../../Shared/components/Modals/TextModal';
import NoteIcon from '../../../../Shared/components/NoteIcon';
import calculateDifference from '../../../../Shared/helpers/calculateDifference';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';
import showDeliveryStatus from '../../../../Shared/helpers/showDeliveryStatus';
import showNoteCount from '../../../../Shared/helpers/showNoteCount';
import NoteForm from '../ManageDeliveries/NoteForm';

const DeliveryHistoryTable = ({ deliveries, loading, error, type }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [delivery, setDelivery] = useState({});

  const [noteModal, setNoteModal] = useState(false);

  const fleetHeaders = [
    { label: 'ORDER ID', key: 1 },
    { label: 'Delivery Date', key: 2 },
    { label: 'External Order ID', key: 3 },
    { label: 'Notes', key: 4 },
    { label: 'External Order Price', key: 5 },
    { label: 'Sender Account', key: 6 },
    { label: 'Customer Name', key: 7 },
    { label: 'Company Name', key: 8 },
    { label: 'Business Name', key: 9 },
    { label: 'Pickup address', key: 18 },
    { label: 'Total Fee', key: 10 },
    { label: 'Driver', key: 11 },
    // { label: 'Pickup Address', key: 11 },
    { label: 'Distance (km)', key: 12 },
    { label: 'Arrived At', key: 13 },
    { label: 'Pickup Time', key: 14 },
    { label: 'Delivery Time', key: 15 },
    { label: 'City', key: 16 },
    { label: 'STATUS', key: 17 }
  ];

  // business headers
  const businessHeaders = [
    { label: 'ORDER ID', key: 1 },
    { label: 'Notes', key: 12 },
    { label: 'Pickup from', key: 2 },
    { label: 'Pickup address', key: 4 },
    { label: 'Delivery to (company)', key: 5 },
    { label: 'Delivery address', key: 11 },
    { label: 'Distance (km)', key: 13 },
    { label: 'Created at', key: 6 },
    { label: 'Delivered at', key: 14 },
    { label: 'Arrived At', key: 7 },
    { label: 'PICK-UP', key: 8 },
    { label: 'DELIVERY', key: 9 },
    { label: 'STATUS', key: 10 }
  ];

  const renderHeaders = () => {
    if (type === 'business') {
      return businessHeaders;
    }
    return fleetHeaders;
  };

  const headers = renderHeaders();

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleNoteModal = () => {
    setNoteModal(!noteModal);
  };

  const renderNoteModalBody = () => {
    if (type === 'business') {
      return <NoteForm closeModal={toggleNoteModal} delivery={delivery} toggleNoteModal={toggleNoteModal} readonly />;
    } else {
      return (
        <NoteFormFleet
          delivery={delivery}
          closeModal={toggleNoteModal}
          toggleNoteModal={toggleNoteModal}
          businessNote={delivery?.businessNote ?? ''}
          readOnly={true}
        />
      );
    }
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[700px]">
      <div className="relative rounded-3xl">
        {loading ? (
          <div className="flex justify-center w-full p-5 ">
            <Loader />
          </div>
        ) : error ? (
          <p className="m-5 text-xl text-gray-500">{error}</p>
        ) : (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase ">
              <tr className="sticky top-0 z-10 bg-gray-50">
                {headers.map((header) => (
                  <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                    {header.label}
                  </th>
                ))}
              </tr>
            </thead>
            {deliveries && (
              <tbody className="">
                {deliveries.map((delivery) =>
                  type === 'business' ? (
                    <tr key={delivery.id} className="bg-white border-b">
                      <td
                        className="px-6 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80 whitespace-nowrap"
                        onClick={() => {
                          toggleModal(), setDelivery(delivery);
                        }}>
                        {delivery.orderReference}
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap">
                        <NoteIcon
                          counter={showNoteCount(delivery, 'business')}
                          onClick={() => {
                            toggleNoteModal(), setDelivery(delivery);
                          }}
                        />
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{delivery.branchName}</td>
                      <td className="px-6 py-4">
                        {delivery.branchAddress}, {delivery.branchCity}
                        {delivery.branchPostCode && `, ${delivery.branchPostCode}`}
                      </td>
                      <td className="px-6 py-4">
                        {delivery.customerFullName} {delivery.customerBusinessName ? `(${delivery.customerBusinessName})` : ''}
                      </td>
                      <td className="px-6 py-4">
                        {delivery.deliveryAddress}
                        {delivery.deliveryCity && `, ${delivery.deliveryCity}`}
                        {delivery.deliveryPostCode && `, ${delivery.deliveryPostCode}`}
                      </td>
                      <td className="px-6 py-4">{delivery.distance}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{dayjs(delivery.createdAt).format('MMM DD')}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {delivery?.realDeliveryTime ? `${dayjs(delivery.realDeliveryTime).format('MMM DD, HH:mm')}` : '---'}{' '}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {delivery.arrivedForPickupTime ? dayjs(delivery.arrivedForPickupTime).format('HH:mm') : '---'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{dayjs(delivery.pickupTime).format('HH:mm')}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{dayjs(delivery.deliveryTime).format('HH:mm')}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{showDeliveryStatus(delivery.status)}</td>
                    </tr>
                  ) : (
                    <tr key={delivery.id} className={`transition duration-300 ease-in-out  border-b ${delivery.openApi === 1 ? 'bg-orange-50' : 'bg-white'}`}>
                      <td
                        className="px-6 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80 whitespace-nowrap"
                        onClick={() => {
                          toggleModal(), setDelivery(delivery);
                        }}>
                        {delivery.orderReference}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{dayjs(delivery.deliveryTime).format('MMM DD, YYYY')}</td>
                      <td className="px-4 py-4 text-orange-500 whitespace-nowrap">
                        {delivery?.externalOrderReference ? `${delivery?.externalOrderReference}` : '---'}{' '}
                      </td>
                      {/* TODO: change to user/account currency */}
                      <td className="px-4 py-4 whitespace-nowrap">
                        <NoteIcon
                          counter={showNoteCount(delivery, 'fleet')}
                          onClick={() => {
                            toggleNoteModal(), setDelivery(delivery);
                          }}
                        />
                      </td>
                      <td className="px-4 py-4 whitespace-nowrap">{delivery?.externalOrderPrice ? `${delivery?.externalOrderPrice} kr.` : '---'} </td>
                      <td className="px-6 py-4 pr-2">{delivery?.business?.name}</td>
                      <td className="px-6 py-4 pr-2">{delivery.customerFullName}</td>
                      <td className="px-6 py-4 pr-2">{delivery.customerBusinessName || '---'} </td>
                      <td className="px-6 py-4 ">{delivery.branchName}</td>
                      <td className="px-6 py-4">
                        {delivery.branchAddress}, {delivery.branchCity}
                        {delivery.branchPostCode && `, ${delivery.branchPostCode}`}
                      </td>
                      <td className="px-6 py-4">{formatNumber(delivery.totalFeeFleet)}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{delivery.driverName ? delivery.driverName : '-'}</td>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                      {delivery.branchAddress}, {delivery.branchCity}
                    </td> */}
                      <td className="px-6 py-4 whitespace-nowrap">{delivery.distance}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {delivery.arrivedForPickupTime ? dayjs(delivery.arrivedForPickupTime).format('HH:mm') : '-'}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {dayjs(delivery.pickupTime).format('HH:mm')} {calculateDifference(delivery.pickupTime, delivery.realPickupTime)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {dayjs(delivery.deliveryTime).format('HH:mm')} {calculateDifference(delivery.deliveryTime, delivery.realDeliveryTime)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{delivery?.city?.name}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{showDeliveryStatus(delivery.status)}</td>
                    </tr>
                  )
                )}
              </tbody>
            )}
          </table>
        )}
      </div>
      {isOpen && (
        <BaseModal closeModal={toggleModal} isOpen={isOpen} title="Delivery Details" body={<DeliveryDetails closeModal={toggleModal} delivery={delivery} />} />
      )}
      {noteModal && delivery && <TextModal closeModal={toggleNoteModal} isOpen={noteModal} type="edit" title={'Order Notes'} body={renderNoteModalBody()} />}
    </div>
  );
};

export default DeliveryHistoryTable;
