import { useEffect, useRef, useState } from 'react';

import { LockClosedIcon } from '@heroicons/react/20/solid';
import { NavLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { login } from '../../api/services/Auth';
import DynamicIcon from '../../components/DynamicIcon';
import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import EmailSentConfirmation from './EmailSentConfirmation';
import ForgotPasswordForm from './ForgotPasswordForm';

export default function LoginScreen() {
  const { setAuth, setUser } = useAuth();
  const navigate = useNavigate();

  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [resetPasswordState, setResetPasswordState] = useState('1');

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  useEffect(() => {
    setErrorMsg('');
  }, [email, password]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await login(email, password);

      console.log(response);

      if (response?.status === 200) {
        const user = response?.data;
        if (user.approved) {
          console.log(user);
          setAuth({ token: user.token });
          setUser(user);

          setEmail('');
          setPassword('');

          const to = `/${user?.type}`;
          navigate(to, { replace: true });
        } else {
          toast.warning('You have not been approved yet.');
        }
      } else if (!response || response?.status === 400) {
        setErrorMsg('Invalid Credentials');
      } else {
        setErrorMsg('Something went wrong, login failed...');
      }
    } catch (err) {
      console.log(err);
      setErrorMsg('Something went wrong, login failed...');
    }
    errRef.current.focus();
    setSubmitting(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Logo />
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-700">
              {resetPasswordState === '1' ? 'Sign in to your account' : 'Reset Password'}
            </h2>
          </div>
          <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
            {errorMsg}
          </p>

          {resetPasswordState === '2' ? (
            <ForgotPasswordForm goBack={() => setResetPasswordState('1')} emailSent={() => setResetPasswordState('3')} />
          ) : resetPasswordState === '3' ? (
            <EmailSentConfirmation goBack={() => setResetPasswordState('1')} />
          ) : (
            <form className="mt-8 space-y-3" action="#" method="POST" onSubmit={handleLogin}>
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    ref={emailRef}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div className="relative block">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type={!showPassword ? 'password' : 'text'}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                    className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
                    placeholder="Password"
                  />
                  <div
                    className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 text-gray-500 cursor-pointer"
                    onClick={handleClickShowPassword}>
                    {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end">
                <div className="text-sm">
                  <p onClick={() => setResetPasswordState('2')} className="font-medium text-blue hover:opacity-80 hover:cursor-pointer">
                    Forgot password?
                  </p>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  disabled={submitting || !email.length || !password.length}
                  className={`${
                    submitting ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue cursor-pointer'
                  } relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group disabled:bg-gray-300 disabled:cursor-not-allowed hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}>
                  {submitting ? (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <div className="ml-2">
                        <Loader small={true} />
                      </div>
                    </span>
                  ) : (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                      <LockClosedIcon className="w-5 h-5 text-white group-hover:text-white" aria-hidden="true" />
                    </span>
                  )}
                  Sign in
                </button>

                <div className="flex flex-col items-center justify-between mt-6">
                  <div className="flex items-center">
                    <label className="block ml-2 text-sm text-gray-900">Don&apos;t have an account?</label>
                  </div>

                  <div className="text-sm">
                    <NavLink to="/register" className="font-medium text-blue hover:text-blue">
                      Register
                    </NavLink>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
}
