import { Fragment } from 'react';

import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { NavLink } from 'react-router-dom';

const Dropdown = ({ path, subPages, check, title }) => {
  const activeSubLink = 'text-white font-semibold text-sm bg-blue';

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`${open || path ? 'bg-blue text-white' : 'text-opacity-90 bg-dark'} ${
              path ? 'font-semibold' : ''
            } group inline-flex items-center text-white px-5 py-4 hover:bg-blue md:border-r-[#48676D] md:border-r-[1px] rounded-lg md:rounded-none duration-300 transition-all ease-in-out`}>
            <p className="text-sm uppercase hover:text-white hover:cursor-pointer">{title}</p>
            <ChevronDownIcon
              className={`${open ? '' : 'text-opacity-70'} ml-2 h-5 w-5 text-white-700 transition duration-150 ease-in-out group-hover:text-opacity-80`}
              aria-hidden="true"
            />
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <Popover.Panel className="absolute z-50 mt-3 transform rounded-lg left-1/2 w-fit -translate-x-1/3 sm:px-0 lg:max-w-max">
              {({ close }) => (
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-blue ring-opacity-5" onClick={() => close()}>
                  <div className="relative flex flex-col justify-center space-x-0 md:flex-row md:space-y-0">
                    {subPages.map(
                      (page) =>
                        check(page.action) && (
                          <NavLink
                            key={page.id}
                            to={page.url}
                            className={({ isActive }) => (isActive ? activeSubLink : 'text-white bg-dark') + ' p-3 px-4 hover:bg-blue'}>
                            <p className="text-sm truncate">{page.name}</p>
                          </NavLink>
                        )
                    )}
                  </div>
                </div>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Dropdown;
