import { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { getFleetById } from '../../../Fleet/api/services/FleetService';
import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Loader from '../../../Shared/components/Loader';
import ConfirmDeleteModal from '../../../Shared/components/Modals/ConfirmDeleteModal';
import Tooltip from '../../../Shared/components/Tooltip';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { disconnectFleetFromBranch } from '../../api/services/BranchService';

const FleetDetails = ({ closeModal, fleetId, branchId, getBranches }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [fleetInfo, setFleetInfo] = useState({});
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const fleetUrl = getFleetById(fleetId);
  const disconnectFleetUrl = disconnectFleetFromBranch(branchId);

  useEffect(() => {
    fleetId && getFleetInfo();
  }, [fleetId]);

  const getFleetInfo = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(fleetUrl);
      console.log(response.data);

      setFleetInfo(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleDisconnectModal = () => {
    setDisconnectModal(!disconnectModal);
  };

  const handleDisconnect = async () => {
    setSubmitting(true);
    try {
      const response = await axiosPrivate.put(disconnectFleetUrl);
      closeModal();
      getBranches();
      console.log(response.data);
      toast.success('Fleet disconnected!');
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center justify-center w-full my-10">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        fleetInfo && (
          <div className="my-5 space-y-3">
            <div className="flex text-lg">
              <p className="">Fleet name: </p>
              <p className="ml-2 font-bold">{fleetInfo?.name}</p>
            </div>

            <div className="flex text-lg">
              <p className="">Phone number: </p>
              <p className="ml-2 font-bold">{fleetInfo.phoneNumber}</p>
            </div>
            <div className="flex text-lg">
              <p className="">Address: </p>
              <p className="ml-2 font-bold">
                {fleetInfo.address}, {fleetInfo.postCode}
              </p>
            </div>
            <div className="flex text-lg">
              <p className="">Interval: </p>
              <p className="flex items-center ml-2 space-x-1">
                <span className="font-bold">25 minutes</span>
                <Tooltip message={`Time from placing the order to pickup time`}>
                  <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
                </Tooltip>
              </p>
            </div>
          </div>
        )
      )}

      <div className="my-2 border-b border-gray-200"></div>

      <div className="flex justify-center w-full mt-4 space-x-4">
        <Button
          type="button"
          disabled={submitting}
          onClick={closeModal}
          text="Close"
          border="border-blue"
          textColor={`${submitting ? 'text-white' : 'text-blue'}`}
          bg="bg-white"
        />
        <Button text="Disconnect" disabled={submitting} onClick={toggleDisconnectModal} bg="bg-red-500" border="border-red-500" />
      </div>

      {toggleDisconnectModal && (
        <ConfirmDeleteModal
          disabled={submitting}
          cancel={toggleDisconnectModal}
          isOpen={disconnectModal}
          remove={handleDisconnect}
          id={fleetId}
          action={'disconnect'}
          name="fleet"
        />
      )}
    </>
  );
};

export default FleetDetails;
