import useAuth from '../hooks/useAuth';

const Footer = () => {
  const { user } = useAuth();

  return (
    <div className="w-full">
      <div className="text-center flex items-center justify-center flex-col">
        {user && user?.businessId && (
          <h1 className="hidden sm:block py-2">
            Are you happy with Lastlap? Share your thoughts on{' '}
            <a className="text-blue" href="https://dk.trustpilot.com/review/lastlap.delivery" target="_blank" rel="noopener noreferrer">
              Trustpilot.{' '}
            </a>
            Your feedback matters!
          </h1>
        )}
        <h1 className="hidden sm:block bg-blue text-white w-full py-2">
          &#169; {new Date().getFullYear()} Lastlap.delivery. All Rights Reserved. |{' '}
          <a className="text-white" href="https://lastlap.delivery/tc/" target="_blank" rel="noopener noreferrer">
            {' '}
            Terms and Conditions{' '}
          </a>
        </h1>
      </div>
      <div className="text-center flex items-center flex-col space-y-2">
        {user && user?.businessId && (
          <h1 className="text-center sm:hidden p-2">
            Are you happy with Lastlap? Share your thoughts on{' '}
            <a className="text-blue" href="https://dk.trustpilot.com/review/lastlap.delivery" target="_blank" rel="noopener noreferrer">
              Trustpilot.
            </a>
            <br />
            Your feedback matters!
          </h1>
        )}
        <h1 className="flex flex-col text-center sm:hidden bg-blue text-white w-full p-2">
          <span>
            &#169; {new Date().getFullYear()} Lastlap.delivery. All Rights Reserved. <span className="hidden sm:block"> | </span>{' '}
          </span>
          <a className="text-white" href="https://lastlap.delivery/tc/" target="_blank" rel="noopener noreferrer">
            {' '}
            Terms and Conditions{' '}
          </a>
        </h1>
      </div>
    </div>
  );
};

export default Footer;
