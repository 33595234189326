import React, { useEffect, useState } from 'react';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import UsersForm from '../../../Shared/components/Users/UsersForm';
import UsersTable from '../../../Shared/components/Users/UsersTable';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getUsersByBusinessId } from '../../api/services/UsersService';

const Users = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const businessId = user?.businessId;
  const usersUrl = getUsersByBusinessId(businessId);

  useEffect(() => {
    setError('');
    getUsers();
  }, []);

  const getUsers = async () => {
    try {
      const response = await axiosPrivate.get(usersUrl);
      console.log('users', response.data);
      if (response.data.length === 0) {
        setError('No users found associated with this business');
      } else {
        setUsers(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full m-auto md:max-w-7xl">
      <div className="flex items-center justify-between">
        <Heading text="Manage Users" />
        <Button text="Invite New User" onClick={toggleModal} rounded>
          <DynamicIcon icon="PlusCircleIcon" />
        </Button>
      </div>
      <UsersTable users={users} loading={loading} error={error} getUsers={getUsers} />
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Invite new user"
          body={<UsersForm type="Add" getUsers={getUsers} closeModal={toggleModal} />}
        />
      )}
    </div>
  );
};

export default Users;
