import axios from '../axios';

const AUTH_URL = '/auth';

const login = async (email, password) => {
  try {
    const response = await axios.post(AUTH_URL + '/login', { email, password });
    return response;
  } catch (error) {
    // console.error(error);
    return error.response;
  }
};

const refresh = async () => {
  try {
    const response = await axios.get(AUTH_URL + '/refresh');
    return response;
  } catch (error) {
    console.error(error);
  }
};

const logout = async () => {
  try {
    const response = await axios.get(AUTH_URL + '/logout');
    return response;
  } catch (error) {
    console.error(error);
  }
};

const forgotPasswordSendEmail = async (email) => {
  try {
    const response = await axios.post(AUTH_URL + '/forgot/password', { email });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const resetPassword = async (resetToken, password) => {
  try {
    const response = await axios.post(AUTH_URL + `/reset/password/${resetToken}`, { password });
    return response;
  } catch (error) {
    console.error(error);
  }
};

const userInviteSendEmail = () => {
  const url = `${AUTH_URL}/user/invite`;
  return url;
};

const completeInvitedUserCreation = (inviteToken) => {
  const url = `${AUTH_URL}/complete/user/creation/${inviteToken}`;
  return url;
};

export { login, refresh, logout, forgotPasswordSendEmail, resetPassword, userInviteSendEmail, completeInvitedUserCreation };
