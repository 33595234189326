import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../../../Shared/api/axios';
import Button from '../../../../Shared/components/Button';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import { createContract, updateContract } from '../../../api/services/ContractService';

const ContractsForm = ({ closeModal, getContracts, type, contract }) => {
  const [contractName, setContractName] = useState('');
  const [payrollId, setPayrollId] = useState('1');
  const [orderPrice, setOrderPrice] = useState(0);
  const [shiftPrice, setShiftPrice] = useState(0);
  const [numberOfOrders, setNumberOfOrders] = useState(0);
  const [numberOfShifts, setNumberOfShifts] = useState(0);
  const [hourlyPrice, setHourlyPrice] = useState(0);
  const [pricePerOrder, setPricePerOrder] = useState(0);
  const [pricePerShift, setPricePerShift] = useState(0);
  const [active, setActive] = useState(true);
  const [fuelBonusState, setFuelBonusState] = useState(false);
  const [fuelBonus, setFuelBonus] = useState(0);
  const [contractId, setContractId] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { user } = useAuth();
  const errRef = useRef();
  const fleetId = user?.fleetId;

  const createContractUrl = createContract();
  const updateContractUrl = updateContract(contractId);

  useEffect(() => {
    if (contract) {
      setContractId(contract.id);
      setContractName(contract.name);
      setPayrollId(contract.payrollId);
      setOrderPrice(contract.orderPrice);
      setShiftPrice(contract.shiftPrice);
      setNumberOfOrders(contract.orderCount);
      setNumberOfShifts(contract.shiftCount);
      setHourlyPrice(contract.hourlyPrice);
      setPricePerOrder(contract.pricePerOrder);
      setPricePerShift(contract.pricePerShift);
      setActive(contract.active);
      setFuelBonus(contract.fuelBonus);
      if (contract.fuelBonus > 0) {
        setFuelBonusState(true);
      }
    }
  }, [contract]);

  const clearFields = () => {
    setContractName('');
    setPayrollId('1');
    setOrderPrice(0);
    setShiftPrice(0);
    setHourlyPrice(0);
    setNumberOfOrders(0);
    setPricePerOrder(0);
    setPricePerShift(0);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateContractUrl, { active: !active });
      getContracts();
      closeModal();
      toast.success('Status changed!');
    } catch (error) {
      console.log(error);
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: contractName,
      payrollId,
      orderPrice,
      shiftPrice,
      orderCount: numberOfOrders,
      shiftCount: numberOfShifts,
      hourlyPrice,
      pricePerOrder,
      pricePerShift,
      fuelBonus,
      fleetId
    };
    setSubmitting(true);
    try {
      console.log(payrollId);
      if (type === 'Edit') {
        const response = await axiosPrivate.put(updateContractUrl, data);
        console.log(response);
        toast.success('Edited!');
      } else {
        const response = await axiosPrivate.post(createContractUrl, data);
        console.log(response);
        toast.success('Created!');
      }
      getContracts();
      clearFields();
      closeModal();
    } catch (err) {
      console.log(err.response);
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrorMsg('Invalid Credentials');
      } else {
        setErrorMsg('Something went wrong');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const toggleFuelBonusChecked = () => {
    setFuelBonusState(!fuelBonusState);
    setFuelBonus(0);
  };

  const renderFormFields = () => {
    switch (payrollId) {
      case '1':
        return (
          <>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="order-price" className="block text-sm font-medium text-gray-700">
                Order Price*
              </label>
              <input
                type="number"
                name="order-price"
                id="order-price"
                onChange={(e) => setOrderPrice(e.target.value)}
                value={orderPrice}
                autoComplete="order-price"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="number-of-orders" className="block text-sm font-medium text-gray-700">
                For number of orders*
              </label>
              <input
                type="number"
                name="number-of-orders"
                id="number-of-orders"
                onChange={(e) => setNumberOfOrders(e.target.value)}
                value={numberOfOrders}
                autoComplete="number-of-orders"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
          </>
        );
      case '2':
        return (
          <>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="shift-price" className="block text-sm font-medium text-gray-700">
                Shift Price*
              </label>
              <input
                type="number"
                name="shift-price"
                id="shift-price"
                onChange={(e) => setShiftPrice(e.target.value)}
                value={shiftPrice}
                autoComplete="shift-price"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="number-of-shifts" className="block text-sm font-medium text-gray-700">
                For number of shifts*
              </label>
              <input
                type="number"
                name="number-of-shifts"
                id="number-of-shifts"
                onChange={(e) => setNumberOfShifts(e.target.value)}
                value={numberOfShifts}
                autoComplete="number-of-shifts"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
          </>
        );
      case '3':
        return (
          <div className="col-span-4 sm:col-span-4">
            <label htmlFor="hourly-price" className="block text-sm font-medium text-gray-700">
              Hourly Price*
            </label>
            <input
              type="text"
              name="hourly-price"
              id="hourly-price"
              onChange={(e) => setHourlyPrice(e.target.value)}
              value={hourlyPrice}
              autoComplete="hourly-price"
              className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
            />
          </div>
        );
      case '4':
        return (
          <>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="price-per-order" className="block text-sm font-medium text-gray-700">
                Price per order*
              </label>
              <input
                type="text"
                name="price-per-order"
                id="price-per-order"
                onChange={(e) => setPricePerOrder(e.target.value)}
                value={pricePerOrder}
                autoComplete="price-per-order"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
            <div className="col-span-4 sm:col-span-4">
              <label htmlFor="price-per-shift" className="block text-sm font-medium text-gray-700">
                Price per shift*
              </label>
              <input
                type="text"
                name="price-per-shift"
                id="price-per-shift"
                onChange={(e) => setPricePerShift(e.target.value)}
                value={pricePerShift}
                autoComplete="price-per-shift"
                className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
              />
            </div>
          </>
        );
      default:
        return <div className="col-span-4 sm:col-span-4">Something is wrong</div>;
    }
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="contracts-name" className="block text-sm font-medium text-gray-700">
                    Contract Name*
                  </label>
                  <input
                    type="text"
                    name="contracts-name"
                    id="contracts-name"
                    onChange={(e) => setContractName(e.target.value)}
                    value={contractName}
                    autoComplete="contracts-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                    Contract Type*
                  </label>
                  <select
                    name="type"
                    id="type"
                    onChange={(e) => setPayrollId(e.target.value)}
                    value={payrollId}
                    autoComplete="type"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                    <option value="1">Order</option>
                    <option value="2">Shift</option>
                    <option value="3">Hourly</option>
                    <option value="4">Order &#38; Shift</option>
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-6 py-5">{renderFormFields()}</div>
              <div className="flex items-center">
                <input
                  name="Fuel bonus"
                  type="checkbox"
                  id="fuelBonusState"
                  onChange={(e) => toggleFuelBonusChecked(e.target.value)}
                  checked={fuelBonusState}
                  className="w-4 h-4 border-gray-300 rounded text-blue focus:ring-0 hover:cursor-pointer"
                />
                <label htmlFor="fuelBonusState" className="block ml-2 text-sm text-gray-900 hover:cursor-pointer">
                  Fuel Bonus
                </label>
              </div>
              <div className="col-span-4 sm:col-span-4">
                <input
                  type="number"
                  disabled={!setFuelBonusState}
                  name="fuelBonus"
                  id="fuelBonus"
                  onChange={(e) => setFuelBonus(e.target.value)}
                  value={fuelBonus}
                  autoComplete="fuelBonus"
                  className={`${
                    !fuelBonusState ? 'border-gray-100 text-gray-200' : 'border-gray-300'
                  } mt-1 focus:ring-blue focus:border-blue block w-full shadow-sm sm:text-sm rounded-md`}
                />
              </div>
              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                  disabled={submitting}
                />
                <Button type="submit" text="Submit" onClick={handleSubmit} disabled={!contractName.length || submitting} />
                {type === 'Edit' && (
                  <Button
                    disabled={submitting}
                    text={active ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    bg={active ? 'bg-red-500' : 'bg-green-600'}
                    border={active ? 'border-red-500' : 'border-green-600'}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
        {deleteModal && contractId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={contractId}
            action={active ? 'deactivate' : 'activate'}
            name="contract"
          />
        )}
      </div>
    </>
  );
};

export default ContractsForm;
