import { useEffect, useRef, useState } from 'react';

import PhoneInput from 'react-phone-number-input';
import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import 'react-phone-number-input/style.css';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { getBonusesByFleetId } from '../../../api/services/BonusService';
import { getCitiesByFleetId } from '../../../api/services/CityService';
import { getContractsByFleetId } from '../../../api/services/ContractService';
import { createDriver, updateDriver } from '../../../api/services/DriverService';

const DriverForm = ({ getDrivers, closeModal, type, driver }) => {
  const [cities, setCities] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [bonuses, setBonuses] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [cityId, setCityId] = useState('');
  const [contractId, setContractId] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [bonusId, setBonusId] = useState('');
  // const [postalCode, setPostalCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [regNumber, setRegNumber] = useState('');
  const [address, setAddress] = useState('');
  const [active, setActive] = useState(true);
  const [driverId, setDriverId] = useState('');

  const [deleteModal, setDeleteModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const errRef = useRef();
  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const createDriverUrl = createDriver();
  const updateDriverUrl = updateDriver(driverId);
  const contractsUrl = getContractsByFleetId(fleetId, '?active=true');
  const citiesUrl = getCitiesByFleetId(fleetId, '?active=true');
  const bonusesUrl = getBonusesByFleetId(fleetId, '?active=true');

  useEffect(() => {
    setError('');
    getContracts();
    getCities();
    getBonuses();
  }, []);

  useEffect(() => {
    if (driver) {
      setDriverId(driver.id);
      setFirstName(driver.firstName);
      setLastName(driver.lastName);
      setEmail(driver.email);
      setPhoneNumber(driver.phoneNumber);
      setCityId(driver.cityId);
      setContractId(driver.contractId);
      setVehicleId(driver.vehicleId);
      setBonusId(driver.bonusId || '');
      // setPostalCode(driver.postalCode);
      setAccountNumber(driver.accountNumber);
      setRegNumber(driver.regNumber);
      setAddress(driver.address);
      setActive(driver.active);
    }
  }, [driver]);

  const getCities = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(citiesUrl);
      setLoading(false);
      setCities(response.data);
      if (response.data.length === 0) {
        setError('No cities found');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getContracts = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(contractsUrl);
      setLoading(false);
      setContracts(response.data);
      if (response.data.length === 0) {
        setError('You need to create a contract first');
      } else if (response.data.length === 1) {
        setContractId(response.data[0].id);
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const getBonuses = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(bonusesUrl);
      setLoading(false);
      setBonuses(response.data);
      // console.log('Bonuses', response);
      // if (response.data.length === 0) {
      //   setError('You need to create a bonus & contract first');
      // }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setCityId('');
    setContractId('');
    setVehicleId('');
    setBonusId('');
    // setPostalCode('');
    setAccountNumber('');
    setRegNumber('');
    setAddress('');
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleDeactivate = async () => {
    setSubmitting(true);
    try {
      await axiosPrivate.put(updateDriverUrl, { active: !active });
      toast.success('Status changed!');
      getDrivers();
      closeModal();
    } catch (error) {
      toast.error('Something went wrong...');
      console.log(error);
    }
    setSubmitting(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log({ firstName, lastName, email, phoneNumber, cityId, contractId, vehicleId, bonusId, accountNumber, regNumber, address });
    setSubmitting(true);
    const data = {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      regNumber,
      accountNumber,
      cityId,
      contractId,
      // postalCode,
      vehicleId,
      bonusId: bonusId.length ? bonusId : null,
      fleetId: user?.fleetId
    };
    try {
      if (type === 'Edit') {
        const response = await axiosPrivate.put(updateDriverUrl, data);
        console.log(response);
        toast.success('Edited!');
      } else {
        const response = await axiosPrivate.post(createDriverUrl, data);
        console.log(response);
        toast.success('Created!');
      }
      getDrivers();
      closeModal();
      clearFields();
    } catch (err) {
      console.log(err.response);
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrorMsg('Invalid Credentials');
      } else {
        setErrorMsg('Something went wrong...');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        {loading ? (
          <div className="flex justify-center w-full p-5 ">
            <Loader />
          </div>
        ) : error ? (
          <p className="m-5 text-xl text-gray-500">{error}</p>
        ) : (
          <form action="#" method="POST" onSubmit={handleSubmit}>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-8 gap-6">
                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                      First name*
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      autoComplete="given-name"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                      Last name*
                    </label>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      autoComplete="family-name"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address*
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      autoComplete="email"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                      Phone Number*
                    </label>
                    <div className="mt-1">
                      <PhoneInput international defaultCountry="DK" value={phoneNumber} onChange={setPhoneNumber} />
                    </div>
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="regNumber" className="block text-sm font-medium text-gray-700">
                      Bank Reg. Nr.
                    </label>
                    <input
                      type="number"
                      name="regNumber"
                      id="regNumber"
                      onChange={(e) => setRegNumber(e.target.value)}
                      value={regNumber}
                      autoComplete="regNumber"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>
                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">
                      Bank Account Nr.
                    </label>
                    <input
                      type="number"
                      name="accountNumber"
                      id="accountNumber"
                      onChange={(e) => setAccountNumber(e.target.value)}
                      value={accountNumber}
                      autoComplete="accountNumber"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                      Street address
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      onChange={(e) => setAddress(e.target.value)}
                      value={address}
                      autoComplete="address"
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                      City*
                    </label>
                    <select
                      id="city"
                      name="city"
                      autoComplete="city"
                      onChange={(e) => setCityId(e.target.value)}
                      value={cityId}
                      className="block w-full px-3 py-2 mt-1 placeholder-gray-500 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option disabled value={''}>
                        Select a city
                      </option>
                      {cities &&
                        cities.map((city) => (
                          <option value={city.id} key={city.id}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="vehicle" className="block text-sm font-medium text-gray-700">
                      Vehicle*
                    </label>
                    <select
                      id="vehicle"
                      name="vehicle"
                      onChange={(e) => setVehicleId(e.target.value)}
                      value={vehicleId}
                      autoComplete="vehicle"
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option value={''} disabled>
                        Select a vehicle
                      </option>
                      <option value="1">Bike</option>
                      <option value="2">Scooter</option>
                      <option value="3">Car</option>
                      <option value="4">Truck</option>
                    </select>
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="contract" className="block text-sm font-medium text-gray-700">
                      Contract*
                    </label>
                    <select
                      id="contract"
                      name="contract"
                      onChange={(e) => setContractId(e.target.value)}
                      value={contractId}
                      autoComplete="contract"
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option value={''} disabled>
                        Select a contract
                      </option>
                      {contracts &&
                        contracts.map((contract) => (
                          <option value={contract.id} key={contract.id}>
                            {contract.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="col-span-8 sm:col-span-4">
                    <label htmlFor="bonus" className="block text-sm font-medium text-gray-700">
                      Bonus*
                    </label>
                    <select
                      id="bonus"
                      name="bonus"
                      onChange={(e) => setBonusId(e.target.value)}
                      value={bonusId}
                      autoComplete="bonus"
                      className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                      <option value={''}>No bonus</option>
                      {bonuses &&
                        bonuses.map((bonus) => (
                          <option value={bonus.id} key={bonus.id}>
                            {bonus.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-center mt-6 space-x-5">
                  <Button
                    text="Cancel"
                    disabled={submitting}
                    onClick={closeModal}
                    bg="bg-white"
                    border="border-blue"
                    textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                  />
                  <Button
                    text="Submit"
                    type="submit"
                    onClick={handleSubmit}
                    disabled={
                      !firstName.length ||
                      !lastName.length ||
                      !email.length ||
                      !phoneNumber ||
                      !vehicleId.length ||
                      !contractId.length ||
                      !cityId.length ||
                      submitting
                    }
                  />

                  {type === 'Edit' && (
                    <Button
                      disabled={submitting}
                      text={active ? 'Deactivate' : 'Activate'}
                      onClick={toggleDeleteModal}
                      bg={active ? 'bg-red-500' : 'bg-green-600'}
                      border={active ? 'border-red-500' : 'border-green-600'}
                    />
                  )}
                </div>
              </div>
            </div>
          </form>
        )}
        {deleteModal && driverId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={driverId}
            action={active ? 'deactivate' : 'activate'}
            name="driver"
          />
        )}
      </div>
    </>
  );
};

export default DriverForm;
