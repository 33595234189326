import DynamicIcon from './DynamicIcon';

const NoteIcon = ({ counter, onClick }) => {
  return (
    <span onClick={onClick} className="relative inline-block hover:cursor-pointer hover:opacity-90">
      <DynamicIcon icon="ChatBubbleLeftEllipsisIcon" width="w-6" height="h-6" color="text-blue" />

      <span
        className={`${
          counter > 0 ? 'bg-yellow-500 text-white' : 'bg-white text-blue border-blue border-[0.5px]'
        } absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-[10px] font-semibold leading-none transform translate-x-1/2 -translate-y-1/2 rounded-full`}>
        {counter ?? 0}
      </span>
    </span>
  );
};

export default NoteIcon;
