import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import CityFilter from '../../../Shared/components/Filters/CityFilter';
import StatusFilter from '../../../Shared/components/Filters/StatusFilter';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDriversByFleetId } from '../../api/services/DriverService';
import DriverForm from '../../components/Drivers/AllDrivers/DriverForm';
import DriverTable from '../../components/Drivers/AllDrivers/DriverTable';

const AllDrivers = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [drivers, setDrivers] = useState([]);

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const fleetId = user?.fleetId;
  const driversUrl = getDriversByFleetId(fleetId, search);

  useEffect(() => {
    setError('');
    getDrivers();
  }, [search]);

  const getDrivers = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(driversUrl);
      setLoading(false);
      if (response.data.length === 0) {
        setError('No drivers found');
      } else {
        setDrivers(response.data);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-11/12 mx-auto">
      <div className="flex justify-center w-full sm:justify-end">
        <Button text="Register Driver" onClick={toggleModal} rounded>
          <DynamicIcon icon="PlusCircleIcon" />
        </Button>
      </div>
      <div className="flex flex-col items-baseline mt-5 sm:flex-row sm:items-center sm:justify-between">
        <Heading text="All Drivers" />
        {drivers.length > 0 && (
          <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-5">
            <div className="z-30 flex items-center mr-5">
              <CityFilter fleetId={fleetId} />
            </div>
            <div className="z-10 flex items-center w-fit">
              <StatusFilter />
            </div>
          </div>
        )}
      </div>
      <DriverTable drivers={drivers} loading={loading} error={error} getDrivers={getDrivers} />
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Add Driver"
          body={<DriverForm getDrivers={getDrivers} closeModal={toggleModal} type="Add" />}
        />
      )}
    </div>
  );
};

export default AllDrivers;
