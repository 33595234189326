import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import StatusFilter from '../../../Shared/components/Filters/StatusFilter';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getAddressesByBusinessId } from '../../api/services/AddressService';
import AddressForm from '../../components/Addresses/AddressForm';
import AddressTable from '../../components/Addresses/AddressTable';

const Addresses = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();
  const { search } = useLocation();

  const axiosPrivate = useAxiosPrivate();

  const id = user?.businessId;
  const addressesUrl = getAddressesByBusinessId(id, search);

  useEffect(() => {
    setError('');
    getAddresses();
  }, [search]);

  const getAddresses = async () => {
    try {
      const response = await axiosPrivate.get(addressesUrl);
      console.log(response.data);
      setLoading(false);
      if (response.data.length === 0) {
        setError('No addresses have been created');
      } else {
        setAddresses(response.data);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full m-auto md:max-w-7xl">
      <div className="flex justify-center w-full sm:justify-end">
        <Button text="Add Address" onClick={toggleModal} rounded>
          <DynamicIcon icon="PlusCircleIcon" />
        </Button>
      </div>
      <div className="flex flex-col items-baseline justify-between mt-5 sm:items-center sm:flex-row">
        <Heading text="Existing Addresses" />
        {addresses.length > 0 && (
          <div className="flex space-x-4">
            <div className="flex items-center space-x-2">
              <StatusFilter />
            </div>
          </div>
        )}
      </div>

      <AddressTable addresses={addresses} loading={loading} error={error} getAddresses={getAddresses} />
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Add Address"
          body={<AddressForm type="Add" closeModal={toggleModal} getAddresses={getAddresses} />}
        />
      )}
    </div>
  );
};

export default Addresses;
