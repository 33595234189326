import { NavLink } from 'react-router-dom';

const CitySelect = ({ cities, link }) => {
  const activeLink = 'text-white bg-blue p-2 rounded-full border-2 border-blue w-28 text-center min-w-fit';

  return (
    <div className="flex flex-wrap justify-center gap-2 p-2">
      {cities &&
        cities.map((city) => (
          <NavLink
            to={`${link}/${city?.name}`}
            key={city.id}
            className={({ isActive }) =>
              isActive ? activeLink : 'border-2 border-blue rounded-full p-2 w-28 min-w-fit text-center hover:bg-blue hover:text-white cursor-pointer'
            }>
            {city.name}
          </NavLink>
        ))}
    </div>
  );
};

export default CitySelect;
