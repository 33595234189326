import { useContext, useEffect, useState } from 'react';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Heading from '../../../Shared/components/Heading';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getBusinessById } from '../../api/services/BusinessService';
import AddressForm from '../../components/Addresses/AddressForm';
import Delivery from '../../components/Deliveries/NewDelivery/Delivery';
import DeliveryModal from '../../components/Deliveries/NewDelivery/DeliveryModal';
import Pickup from '../../components/Deliveries/NewDelivery/Pickup';
import { DeliveryContext } from '../../contexts/DeliveryContext';

const NewDelivery = () => {
  const [loadingBusiness, setLoadingBusiness] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [business, setBusiness] = useState({});
  const [addressModal, setAddressModal] = useState(false);

  const { user } = useAuth();
  const businessId = user?.businessId;

  const axiosPrivate = useAxiosPrivate();
  const businessUrl = getBusinessById(businessId);

  const { filled } = useContext(DeliveryContext);

  useEffect(() => {
    if (user.businessId) {
      getBusiness();
    }
  }, [isOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleAddressModal = () => {
    setAddressModal(!addressModal);
  };

  const getBusiness = async () => {
    setLoadingBusiness(true);
    try {
      const response = await axiosPrivate.get(businessUrl);
      // console.log('business', response.data);
      setBusiness(response.data);
      setLoadingBusiness(false);
    } catch (error) {
      console.log(error);
      setLoadingBusiness(false);
    }
  };

  return (
    <>
      <div className="flex flex-col ">
        <div className="flex flex-col items-center my-2 sm:flex-row sm:justify-between">
          <Heading text="Create New Delivery" />
          {/* <p className="text-lg font-bold text-yellow-500">Credits: {business.credits}</p> */}
          <div className="flex items-center space-x-2 text-lg font-bold text-yellow-500">
            <p className="">Credits: </p>
            {loadingBusiness ? (
              <div className="flex items-center">
                <Loader small={true} />
              </div>
            ) : (
              <p className="">{business?.isFreeAccount ? 'FREE' : business?.credits}</p>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-between mt-5 space-y-10 lg:flex-row lg:space-y-0 lg:space-x-6">
          <div className="flex flex-col w-full px-5 py-5 text-center bg-white standard-border lg:w-1/2">
            <Heading text="Pick-Up Location" />
            <Pickup />
          </div>
          <div className="flex flex-col w-full px-5 py-5 text-center bg-white standard-border lg:w-1/2">
            <div className="flex flex-col items-center space-y-2">
              <Heading text="Delivery Location" />
              <Button text="Add New Address" onClick={toggleAddressModal} rounded small>
                <DynamicIcon icon="PlusCircleIcon" />
              </Button>
            </div>
            <Delivery />
          </div>
        </div>
        <div className="flex justify-center w-full my-10">
          <Button text={'send delivery'} rounded disabled={!filled} onClick={toggleModal} />
        </div>
      </div>
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          big={true}
          width="w-3/4"
          title=""
          body={<DeliveryModal closeModal={toggleModal} withRefetch={true} business={business} />}
        />
      )}
      {addressModal && (
        <BaseModal
          closeModal={toggleAddressModal}
          isOpen={addressModal}
          title="Add Address"
          body={<AddressForm type="Add" closeModal={toggleAddressModal} withRefetch={true} />}
        />
      )}
    </>
  );
};

export default NewDelivery;
