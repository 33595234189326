import React, { useState } from 'react';

import DynamicIcon from './DynamicIcon';

const CopyText = ({ text }) => {
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    // copy the text to the clipboard
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <div className="relative">
      <div className="hover:cursor-pointer hover:opacity-70" onClick={handleCopyClick}>
        <DynamicIcon icon="ClipboardDocumentListIcon" className="w-5 h-5" />
      </div>
      {copied && (
        <div className="absolute px-2 py-1 text-xs text-white transform bg-gray-500 rounded -translate-y-1/4 -translate-x-1/4 top-full left-1/2">Copied!</div>
      )}
    </div>
  );
};

export default CopyText;
