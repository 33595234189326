import { useEffect, useState } from 'react';

import Heading from '../../../Shared/components/Heading';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDeliveriesTodayByBusinessId, getFutureDeliveriesByBusinessId } from '../../api/services/DeliveryService';
import ManageDeliveriesTable from '../../components/Deliveries/ManageDeliveries/ManageDeliveriesTable';

const ManageDeliveries = () => {
  const [ongoingDeliveriesLoading, setOngoingDeliveriesLoading] = useState(false);
  const [futureDeliveriesLoading, setFutureDeliveriesLoading] = useState(false);

  const [ongoingDeliveries, setOngoingDeliveries] = useState([]);
  const [futureDeliveries, setFutureDeliveries] = useState([]);

  const [ongoingDeliveryError, setOngoingDeliveryError] = useState('');
  const [futureDeliveryError, setFutureDeliveryError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const businessId = user?.businessId;

  const deliveriesUrl = getDeliveriesTodayByBusinessId(businessId);
  const futureDeliveriesUrl = getFutureDeliveriesByBusinessId(businessId);

  useEffect(() => {
    getOngoingDeliveries();
    getFutureDeliveries();
  }, []);

  const getOngoingDeliveries = async () => {
    setOngoingDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(deliveriesUrl);
      console.log(response.data);
      if (response.data.length === 0) {
        setOngoingDeliveryError('No deliveries have been created');
        setOngoingDeliveriesLoading(false);
      } else {
        setOngoingDeliveries(response.data);
        setOngoingDeliveryError('');
        setOngoingDeliveriesLoading(false);
      }
    } catch (error) {
      console.log(error);
      setOngoingDeliveryError('Something went wrong');
      setOngoingDeliveriesLoading(false);
    }
  };

  const getFutureDeliveries = async () => {
    setFutureDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(futureDeliveriesUrl);
      // console.log(response.data);
      if (response.data.length === 0) {
        setFutureDeliveryError('No deliveries have been created');
        setFutureDeliveriesLoading(false);
      } else {
        setFutureDeliveries(response.data);
        setFutureDeliveriesLoading(false);
        setFutureDeliveryError('');
      }
    } catch (error) {
      console.log(error);
      setFutureDeliveryError('Something went wrong');
      setFutureDeliveriesLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center">
        <Heading text="MANAGE DELIVERIES" />
        <div className="px-4 py-2 my-2 bg-yellow-500 rounded-xl sm:rounded-full">
          <p className="text-sm text-gray-700">*You can only cancel delivery before it has been assigned to a driver!</p>
        </div>
      </div>
      <div className="w-full mx-auto mt-10 lg:w-2/3">
        <div className="mb-2 text-left">
          <Heading text="Ongoing deliveries today" />
        </div>
        <ManageDeliveriesTable
          deliveries={ongoingDeliveries}
          error={ongoingDeliveryError}
          loading={ongoingDeliveriesLoading}
          getDeliveries={getOngoingDeliveries}
        />
      </div>
      <div className="w-full mx-auto mt-10 lg:w-2/3">
        <div className="mb-2 text-left">
          <Heading text="Future deliveries" />
        </div>
        <ManageDeliveriesTable
          type="future"
          deliveries={futureDeliveries}
          error={futureDeliveryError}
          loading={futureDeliveriesLoading}
          getDeliveries={getFutureDeliveries}
        />
      </div>
    </>
  );
};

export default ManageDeliveries;
