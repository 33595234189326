import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../../Shared/components/Pills/StatusPill';
import DriverForm from './DriverForm';

const DriverTable = ({ drivers, loading, error, getDrivers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [driver, setDriver] = useState(null);

  const headers = [
    { label: 'Drivers Name', key: 1 },
    { label: 'Email', key: 5 },
    { label: 'Phone nr.', key: 4 },
    { label: 'Vehicle', key: 2 },
    { label: 'Contract', key: 3 },
    { label: 'City', key: 6 },
    { label: 'Registered', key: 7 },
    { label: 'Status', key: 8 },
    { label: 'Manage', key: 9 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[800px]">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {drivers && (
            <tbody>
              {drivers.map((driver) => (
                <tr key={driver.id} className="bg-white border-b ">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                    {driver.firstName} {driver.lastName}
                  </th>
                  <td className="px-6 py-4">{driver.email}</td>
                  <td className="px-6 py-4">{driver.phoneNumber}</td>
                  <td className="px-6 py-4">{driver.vehicleType}</td>
                  <td className="px-6 py-4">{driver.contractName}</td>
                  <td className="px-6 py-4">{driver.cityName}</td>
                  <td className="px-6 py-4">{dayjs(driver.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-4 py-4">
                    <StatusPill status={driver.active} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        rounded
                        bg="bg-blue"
                        border="border-blue"
                        onClick={() => {
                          toggleModal(), setDriver(driver);
                        }}
                      />
                      {/* <Button text="Delete" bg="bg-red-500" border="border-red-500" /> */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Edit Driver"
          body={<DriverForm getDrivers={getDrivers} closeModal={toggleModal} type="Edit" driver={driver} />}
        />
      )}
    </div>
  );
};

export default DriverTable;
