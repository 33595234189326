const DRIVER_SHIFT_URL = '/driver-shift';

export const createDriverShift = () => {
  const url = `${DRIVER_SHIFT_URL}`;
  return url;
};

export const getDriverShiftByShiftId = (shiftId) => {
  const url = `${DRIVER_SHIFT_URL}/shift/${shiftId}`;
  return url;
};

// export const updateDriverShift = (shiftId) => {
//   const url = `${DRIVER_SHIFT_URL}/${shiftId}`;
//   return url;
// };

export const deleteDriverShift = (driverId, shiftId) => {
  const url = `${DRIVER_SHIFT_URL}/${driverId}/${shiftId}`;
  return url;
};

export const pauseDriverShift = (driverId, shiftId) => {
  const url = `${DRIVER_SHIFT_URL}/driver/pause/${driverId}/${shiftId}`;
  return url;
};
