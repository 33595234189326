export const startOfDay = (dateString) => {
  const date = new Date(dateString);
  date.setHours(0, 0, 0, 0);

  return date;
};

export const endOfDay = (dateString) => {
  const date = new Date(dateString);
  date.setHours(23, 59, 59, 0);

  return date;
};
