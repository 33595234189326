const DISTANCE_URL = '/distance';

export const getDistance = () => {
  const url = `${DISTANCE_URL}`;
  return url;
};

export const getGeocode = () => {
  const url = `${DISTANCE_URL}/geocode`;
  return url;
};
