const PRICING_URL = '/price';

export const getShippingPriceByCity = (fleetId, cityId) => {
  const url = `${PRICING_URL}/${fleetId}/city/${cityId}`;
  return url;
};

export const getShippingPriceByBranch = (fleetId, branchId) => {
  const url = `${PRICING_URL}/${fleetId}/branch/${branchId}`;
  return url;
};

export const createShippingPrice = () => {
  const url = `${PRICING_URL}`;
  return url;
};

export const deleteShippingPrice = (id) => {
  const url = `${PRICING_URL}/${id}`;
  return url;
};

export const updateShippingPrice = (id) => {
  const url = `${PRICING_URL}/${id}`;
  return url;
};
