import { useContext, useEffect, useState } from 'react';

import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import notificationSound from '../../../assets/sounds/notificationSound.wav';
import Alert from '../../../Shared/components/Alert';
import EditCitySettingsModal from '../../../Shared/components/City/EditCitySettingsModal.jsx';
import CitySelect from '../../../Shared/components/CitySelect';
import CustomSwitch from '../../../Shared/components/CustomSwitch';
import DynamicIcon from '../../../Shared/components/DynamicIcon.jsx';
import Loader from '../../../Shared/components/Loader';
import NotificationIcon from '../../../Shared/components/NotificationIcon';
import SmallHeading from '../../../Shared/components/SmallHeading';
import SocketContext from '../../../Shared/context/Socket';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import useNotificationContext from '../../../Shared/hooks/useNotificationContext';
import { getCitiesByFleetId, updateCity } from '../../api/services/CityService';
import {
  getCompletedDeliveriesTodayByFleetId,
  getDeliveriesStatsByFleetId,
  getOngoingDeliveriesTodayByFleetId,
  getPreordersByFleetId
} from '../../api/services/DeliveryService';
import { getClockedInDrivers } from '../../api/services/DriverService';
import DeliveryDrivers from '../../components/LiveDeliveries/DeliveryDrivers';
import DeliveryStats from '../../components/LiveDeliveries/DeliveryStats';
// import DriverMapAll from '../../components/LiveDeliveries/DriverMapAll';
import ExpandableTable from '../../components/LiveDeliveries/ExpandableTable';
import OrderTable from '../../components/LiveDeliveries/OrderTable';

const LiveDeliveries = () => {
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [clockedInLoading, setClockedInLoading] = useState(false);
  const [preordersLoading, setPreordersLoading] = useState(false);
  const [completedDeliveriesLoading, setCompletedDeliveriesLoading] = useState(false);
  const [ongoingDeliveriesLoading, setOngoingDeliveriesLoading] = useState(false);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  const [cities, setCities] = useState([]);
  const [cityError, setCityError] = useState('');
  const [autoAssignEnabled, setAutoAssignEnabled] = useState(false);
  const [isCitySettingsModalOpen, setIsCitySettingsModalOpen] = useState(false);

  const [ongoingDeliveries, setOngoingDeliveries] = useState([]);
  const [ongoingDeliveryError, setOngoingDeliveryError] = useState('');

  const [completedDeliveries, setCompletedDeliveries] = useState([]);
  const [completedDeliveryError, setCompletedDeliveryError] = useState('');

  const [preorders, setPreorders] = useState([]);
  const [preorderError, setPreorderError] = useState('');

  const [driverError, setDriverError] = useState('');

  const [todayDeliveries, setTodayDeliveries] = useState(0);
  const [lastWeekDeliveries, setLastWeekDeliveries] = useState(0);
  const [lastMonthDeliveries, setLastMonthDeliveries] = useState(0);
  const [clockedInDrivers, setClockedInDrivers] = useState([]);

  const { user } = useAuth();
  const navigate = useNavigate();
  const { city } = useParams();

  const axiosPrivate = useAxiosPrivate();
  const socket = useContext(SocketContext);
  const { newOrderCount, resetOrderCount, increaseOrderCount } = useNotificationContext();

  const fleetId = user?.fleetId;

  const citiesUrl = getCitiesByFleetId(fleetId);
  // const updateCityUrl = updateCity(cityId);
  const ongoingDeliveriesUrl = getOngoingDeliveriesTodayByFleetId(fleetId);
  const completedDeliveriesUrl = getCompletedDeliveriesTodayByFleetId(fleetId);
  const preordersUrl = getPreordersByFleetId(fleetId);
  const deliveriesStatsUrl = getDeliveriesStatsByFleetId(fleetId);
  const clockedInDriversUrl = getClockedInDrivers(fleetId, city);

  const findCityObject = () => {
    const foundCity = cities.find((c) => c.name === city);
    return foundCity;
  };

  const cityObject = findCityObject();

  useEffect(() => {
    if (cityObject) {
      setAutoAssignEnabled(cityObject.autoAssign);
    }
  }, [cityObject]);

  useEffect(() => {
    setError('');

    const handleFleetRefresh = async (_fleetId) => {
      if (fleetId === _fleetId) {
        console.log('refreshing completed orders & drivers');
        await getCompletedDeliveries();
        await getOngoingDeliveries();
        if (city.length > 0) {
          await getClockedIn();
        }
      }
    };

    getCities();
    socket.on('fleetRefresh', handleFleetRefresh);

    return () => {
      socket.off('fleetRefresh', handleFleetRefresh);
    };
  }, [city]);

  useEffect(() => {
    const handleNewOrderFleet = async (_fleetId) => {
      const audio = new Audio(notificationSound);

      if (fleetId === _fleetId) {
        await getDeliveriesStats();
        await getOngoingDeliveries();
        increaseOrderCount();

        // console.log('sound,', user?.soundEnabled);
        if (user?.soundEnabled) {
          audio.play();
        }
      }
    };

    socket.on('newOrderFleet', handleNewOrderFleet);

    return () => {
      socket.off('newOrderFleet', handleNewOrderFleet);
    };
  }, []);

  useEffect(() => {
    const handleNewPreOrderFleet = async (_fleetId) => {
      if (fleetId === _fleetId) {
        await getPreorders();
      }
    };

    socket.on('newPreOrderFleet', handleNewPreOrderFleet);

    return () => {
      socket.off('newPreOrderFleet', handleNewPreOrderFleet);
    };
  }, []);

  useEffect(() => {
    if (city && fleetId) {
      getOngoingDeliveries();
      getCompletedDeliveries();
      getDeliveriesStats();
      getPreorders();
      getClockedIn();
      findCityObject();
    }
  }, [city, fleetId]);

  const getClockedIn = async () => {
    if (!city) {
      console.log('City is undefined');
      return; // or handle the error accordingly
    }

    setClockedInLoading(true);
    try {
      const response = await axiosPrivate.get(clockedInDriversUrl);
      console.log('CLOCKED IN', response.data);
      if (response.data.length === 0) {
        console.log('No drivers found here', response);
        setClockedInDrivers([]);
        setClockedInLoading(false);
        setDriverError('No drivers found');
      } else {
        console.log(response.data);
        setClockedInDrivers(response.data);
        setClockedInLoading(false);
        setDriverError('');
      }
    } catch (error) {
      console.log(error);
      setDriverError('Something went wrong');
      setClockedInLoading(false);
    }
  };

  useEffect(() => {
    if (cities.length > 0 && (city === ':city' || !city)) {
      navigate(`/fleet/live-deliveries/${cities[0].name}`);
    } else {
      // navigate(`/fleet/live-deliveries`);
      // setCities([]);
    }
  }, [cities, city]);

  const getOngoingDeliveries = async () => {
    setOngoingDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(ongoingDeliveriesUrl);
      // console.log('DELIVERIES', response.data);
      if (response.data.length === 0) {
        setOngoingDeliveriesLoading(false);
        setOngoingDeliveryError('No ongoing orders');
      } else {
        setOngoingDeliveriesLoading(false);
        setOngoingDeliveries(response.data);
        setOngoingDeliveryError('');
      }
    } catch (error) {
      console.log(error);
      setOngoingDeliveryError('Something went wrong');
      setOngoingDeliveriesLoading(false);
    }
  };

  const getCompletedDeliveries = async () => {
    setCompletedDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(completedDeliveriesUrl);
      // console.log('COMPLETED DELIVERIES', response.data);
      if (response.data.length === 0) {
        setCompletedDeliveriesLoading(false);
        setCompletedDeliveryError('No deliveries have been completed or cancelled');
      } else {
        setCompletedDeliveriesLoading(false);
        setCompletedDeliveries(response.data);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setCompletedDeliveryError('Something went wrong');
      setCompletedDeliveriesLoading(false);
    }
  };

  const getPreorders = async () => {
    setPreordersLoading(true);
    try {
      const response = await axiosPrivate.get(preordersUrl);
      console.log('PREORDERS', response.data);
      if (response.data.length === 0) {
        setPreordersLoading(false);
        setPreorderError('No pre-orders created');
      } else {
        setPreorders(response.data);
        setPreordersLoading(false);
        setError('');
      }
    } catch (error) {
      console.log(error);
      setPreorderError('Something went wrong');
      setPreordersLoading(false);
    }
  };

  const getDeliveriesStats = async () => {
    setStatsLoading(true);
    try {
      const response = await axiosPrivate.get(deliveriesStatsUrl);
      // console.log('STATS', response.data);
      setTodayDeliveries(response.data?.today);
      setLastWeekDeliveries(response.data?.lastWeek);
      setLastMonthDeliveries(response.data?.lastMonth);
      setStatsLoading(false);
    } catch (error) {
      console.log(error);
      setStatsLoading(false);
    }
  };

  const getCities = async () => {
    setCitiesLoading(true);
    try {
      const response = await axiosPrivate.get(citiesUrl);
      if (response.data.length === 0) {
        setCitiesLoading(false);
        setCityError('No cities found');
      } else {
        console.log('CITIES', response.data);
        const filteredCities = _.filter(response.data, { active: 1 });
        setCities(filteredCities);
        setCitiesLoading(false);
      }
    } catch (error) {
      console.log(error);
      setCityError('Something went wrong');
      setCitiesLoading(false);
    }
  };

  const toggleAutoAssign = async () => {
    setSubmitting(true);

    const cityId = cityObject?.id;
    console.log(cityId);

    try {
      const response = await axiosPrivate.put(updateCity(cityId), { autoAssign: !autoAssignEnabled });
      console.log(response.data);
      getCities();
      toast.success(`Auto assign turned ${!autoAssignEnabled ? 'ON' : 'OFF'}!`);
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
    setSubmitting(false);
  };

  const toggleCitySettingsModal = () => {
    setIsCitySettingsModalOpen(!isCitySettingsModalOpen);
  };

  const activeDriverCount = clockedInDrivers?.filter((driver) => !driver?.paused).length;

  return (
    <>
      {cityObject ? (
        <div className="mt-5">
          <div className="p-5 bg-white border border-gray-200 rounded-2xl">
            <div className="flex flex-col items-center justify-between w-full space-y-4 md:flex-row md:space-y-0">
              <div className="flex items-center justify-center space-x-2">
                <p className="text-md">Auto assign</p>
                <CustomSwitch enabled={autoAssignEnabled} onChange={toggleAutoAssign} enabledColor="bg-blue" />
                <p className="font-semibold text-dark">{autoAssignEnabled ? 'ON' : 'OFF'}</p>
              </div>
              <CitySelect cities={cities} error={error} link="/fleet/live-deliveries" />
              <div onClick={toggleCitySettingsModal} className="flex flex-row px-3 py-2 bg-gray-200 border-2 cursor-pointer rounded-xl hover:opacity-80">
                <div className="mr-2">City Settings</div>
                <DynamicIcon icon="Cog6ToothIcon" />
              </div>
            </div>
          </div>

          <div className="grid grid-flow-row grid-cols-12 gap-5 my-10 xl:grid-flow-col min-h-max h-max">
            {cityObject ? (
              <>
                <div className="grid-cols-12 col-span-12 xl:col-span-4">
                  <div className="col-span-12 xl:col-span-4">
                    <SmallHeading text="Delivery stats today" />
                    <DeliveryStats today={todayDeliveries} lastWeek={lastWeekDeliveries} lastMonth={lastMonthDeliveries} />
                  </div>
                  <div className="col-span-12 mt-5 xl:col-span-4">
                    <SmallHeading text={`Active drivers in selected city (${activeDriverCount})`} />
                    <DeliveryDrivers
                      drivers={clockedInDrivers}
                      error={driverError}
                      cityLatitude={cityObject?.latitude}
                      cityLongitude={cityObject?.longitude}
                      fleetId={fleetId}
                      getDrivers={getClockedIn}
                    />
                  </div>
                </div>
                <div className="grid-cols-12 col-span-12 xl:col-span-8">
                  <div className="col-span-12 xl:col-span-8">
                    <div className="flex items-center space-x-2">
                      <SmallHeading text={`Ongoing orders today (${ongoingDeliveries?.length})`} />
                      <NotificationIcon counter={newOrderCount} onClick={resetOrderCount} />
                    </div>
                    <OrderTable deliveries={ongoingDeliveries} drivers={clockedInDrivers} error={ongoingDeliveryError} getDeliveries={getOngoingDeliveries} />
                  </div>
                  <div className="col-span-12 mt-5 xl:col-span-8">
                    <ExpandableTable
                      deliveries={completedDeliveries}
                      loading={completedDeliveriesLoading}
                      drivers={clockedInDrivers}
                      error={completedDeliveryError}
                      getDeliveries={getCompletedDeliveries}
                      headingText={`Delivered & Cancelled Orders (${completedDeliveries.length})`}
                    />
                  </div>
                  <div className="col-span-12 mt-5 xl:col-span-8">
                    <ExpandableTable
                      type="preorder"
                      loading={preordersLoading}
                      deliveries={preorders}
                      drivers={clockedInDrivers}
                      error={preorderError}
                      getDeliveries={getPreorders}
                      headingText={`Pre-orders (${preorders.length})`}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="col-span-12 row-span-full">
                {clockedInLoading && ongoingDeliveriesLoading && completedDeliveriesLoading && citiesLoading && statsLoading && preordersLoading && (
                  <div className="flex items-center justify-center w-full mt-20">
                    <Loader />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <Alert variant="info" text="Firstly, create a city to see details on this page!" />
      )}

      {isCitySettingsModalOpen && cityObject && (
        <EditCitySettingsModal city={cityObject} closeModal={toggleCitySettingsModal} isOpen={isCitySettingsModalOpen} getCities={getCities} />
      )}
    </>
  );
};

export default LiveDeliveries;
