import { useEffect, useState, useContext } from 'react';

import dayjs from 'dayjs';

import DateSelectorFilter from '../../../Shared/components/Filters/DateSelectorFilter';
import PeriodFilter from '../../../Shared/components/Filters/PeriodFilter';
import Heading from '../../../Shared/components/Heading';
import Loader from '../../../Shared/components/Loader';
import SocketContext from '../../../Shared/context/Socket';
import { endOfDay, startOfDay } from '../../../Shared/helpers/date.js';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getDeliveriesTodayByBusinessId, getFutureDeliveriesByBusinessId } from '../../api/services/DeliveryService';
import { getDeliveryStatsByBusinessId } from '../../api/services/StatisticsService';
import OverviewDeliveries from '../../components/Overview/OverviewDeliveries';
import OverviewInNumbers from '../../components/Overview/OverviewInNumbers';

const Overview = () => {
  const [ongoingDeliveriesLoading, setOngoingDeliveriesLoading] = useState(false);
  const [futureDeliveriesLoading, setFutureDeliveriesLoading] = useState(false);
  const [statsLoading, setStatsLoading] = useState(false);

  const [ongoingDeliveries, setOngoingDeliveries] = useState([]);
  const [futureDeliveries, setFutureDeliveries] = useState([]);
  const [deliveryStats, setDeliveryStats] = useState();

  const [ongoingDeliveryError, setOngoingDeliveryError] = useState('');
  const [futureDeliveryError, setFutureDeliveryError] = useState('');
  const [statsError, setStatsError] = useState('');

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [period, setPeriod] = useState('Today');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();
  const socket = useContext(SocketContext);

  const businessId = user?.businessId;

  const deliveriesUrl = getDeliveriesTodayByBusinessId(businessId);
  const futureDeliveriesUrl = getFutureDeliveriesByBusinessId(businessId);
  const deliveriesStatsUrl = getDeliveryStatsByBusinessId(businessId);

  useEffect(() => {
    setOngoingDeliveryError('');
    setFutureDeliveryError('');
    getOngoingDeliveries();
    getFutureDeliveries();
    getDeliveryStats();

    socket.on('businessRefresh', (_businessId) => {
      if (businessId === _businessId) {
        console.log('refreshing business orders');
        getOngoingDeliveries();
        getDeliveryStats();
      }
    });

    return () => {
      socket.off('businessRefresh');
    };
  }, []);

  useEffect(() => {
    if (period === 'Today') {
      setStartDate(null);
      setEndDate(null);
      getDeliveryStats();
    }
  }, [period]);

  const getOngoingDeliveries = async () => {
    setOngoingDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(deliveriesUrl);
      // console.log(response.data);
      if (response.data.length === 0) {
        setOngoingDeliveryError('No deliveries have been created');
        setOngoingDeliveriesLoading(false);
      } else {
        setOngoingDeliveries(response.data);
        setOngoingDeliveryError('');
        setOngoingDeliveriesLoading(false);
      }
    } catch (error) {
      console.log(error);
      setOngoingDeliveryError('Something went wrong');
      setOngoingDeliveriesLoading(false);
    }
  };

  const getFutureDeliveries = async () => {
    setFutureDeliveriesLoading(true);
    try {
      const response = await axiosPrivate.get(futureDeliveriesUrl);
      // console.log(response.data);
      if (response.data.length === 0) {
        setFutureDeliveryError('No deliveries have been created');
        setFutureDeliveriesLoading(false);
      } else {
        setFutureDeliveries(response.data);
        setFutureDeliveriesLoading(false);
        setFutureDeliveryError('');
      }
    } catch (error) {
      console.log(error);
      setFutureDeliveryError('Something went wrong');
      setFutureDeliveriesLoading(false);
    }
  };

  const getDeliveryStats = async () => {
    setStatsLoading(true);
    let from = null;
    let to = null;
    if (period !== 'Today') {
      from = startDate && startOfDay(startDate);
      to = endDate && endOfDay(endDate);
    }

    try {
      const response = await axiosPrivate.get(deliveriesStatsUrl, { params: { startDate: from, endDate: to } });
      setDeliveryStats(response.data[0]);
      // console.log(response);
      setStatsLoading(false);
    } catch (error) {
      console.log(error);
      setStatsError('Something went wrong while fetching statistics');
      setStatsLoading(false);
    }
  };

  return (
    <>
      {futureDeliveriesLoading && ongoingDeliveriesLoading && statsLoading ? (
        <div className="flex items-center justify-center w-full mt-20">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col w-full m-auto xl:w-4/5 2xl:w-3/5">
          <div className="flex flex-col">
            <div className="text-left">
              <Heading text="In numbers" />
              <div className="flex flex-col mb-2 space-y-2 md:space-y-0 md:flex-row md:justify-between">
                <PeriodFilter period={period} setPeriod={setPeriod} />
                {period !== 'Today' && (
                  <DateSelectorFilter
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    onClick={getDeliveryStats}
                    withSearch={true}
                  />
                )}
              </div>
            </div>
            <OverviewInNumbers stats={deliveryStats} error={statsError} loading={statsLoading} />
          </div>

          <div className="flex flex-col mt-10">
            <div className="mb-2 text-left">
              <Heading text="Ongoing deliveries today" />
            </div>
            <OverviewDeliveries deliveries={ongoingDeliveries} error={ongoingDeliveryError} />
          </div>

          <div className="flex flex-col mt-10">
            <div className="mb-2 text-left">
              <Heading text="Future deliveries" />
            </div>
            <OverviewDeliveries deliveries={futureDeliveries} error={futureDeliveryError} type="future" />
          </div>
        </div>
      )}
    </>
  );
};

export default Overview;
