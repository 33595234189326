import React from 'react';

import { MapPinIcon, ClockIcon, UserIcon, BuildingOfficeIcon, ScaleIcon, PencilSquareIcon, DocumentIcon, CubeIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';

const DeliveryDetails = ({
  pickupLocation,
  pickupTime,
  distance,
  weight,
  deliveryLocation,
  deliveryTime,
  notes,
  branchName,
  customerName,
  customerBusinessName,
  numberOfPackages
}) => {
  return (
    <div className="flex flex-col gap-8 py-5 mt-5 lg:flex-row">
      <div className="flex flex-col w-full p-4 bg-white rounded-lg shadow-md lg:w-1/3">
        <h1 className="mb-3 text-2xl font-bold text-blue">Pick-Up</h1>
        <hr className="border-gray-200" />
        <div className="flex flex-col gap-6 mt-3">
          <div className="flex items-start">
            <DocumentIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Store Name:</strong> {branchName}
            </p>
          </div>
          <div className="flex items-start">
            <MapPinIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Address:</strong> {pickupLocation}
            </p>
          </div>
          <div className="flex items-start">
            <ClockIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Pick-up time:</strong> {dayjs(pickupTime).format('MMMM DD, HH:mm')}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full p-4 bg-white rounded-lg shadow-md lg:w-1/3">
        <h1 className="mb-3 text-2xl font-bold text-blue">Delivery</h1>
        <hr className="border-gray-200" />
        <div className="flex flex-col gap-6 mt-3">
          <div className="flex items-start">
            <UserIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Customer Name:</strong> {customerName}
            </p>
          </div>
          <div className="flex items-start">
            <BuildingOfficeIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Customer Business Name:</strong> {customerBusinessName ? customerBusinessName : '-'}
            </p>
          </div>
          <div className="flex items-start">
            <MapPinIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Address:</strong> {deliveryLocation}
            </p>
          </div>
          <div className="flex items-start">
            <ClockIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Delivery time:</strong> {dayjs(deliveryTime).format('MMMM DD, HH:mm')}
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-full p-4 bg-white rounded-lg shadow-md lg:w-1/3">
        <h1 className="mb-3 text-2xl font-bold text-blue">Other Details</h1>
        <hr className="border-gray-200" />
        <div className="flex flex-col gap-6 mt-3">
          <div className="flex items-start">
            <MapPinIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Distance:</strong> {distance}
            </p>
          </div>
          <div className="flex items-start">
            <ScaleIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Weight:</strong> {weight} kg
            </p>
          </div>
          <div className="flex items-start">
            <CubeIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Nr. of packages:</strong> {numberOfPackages}
            </p>
          </div>
          <div className="flex items-start">
            <PencilSquareIcon className="w-5 h-5 mr-2 text-gray-500" />
            <p>
              <strong className="text-slate-800">Notes:</strong> {notes ? notes : '-'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetails;
