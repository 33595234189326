import { useEffect, useState } from 'react';

import Button from '../../../../Shared/components/Button';
import PricingCriteriaFields from './PricingCriteriaFormFields';

const PricingCriteriaFormEdit = ({ type, closeModal, pricingItems, getPricing, cityId, branchId }) => {
  const [measure, setMeasure] = useState('');
  const [addMoreFields, setAddMoreFields] = useState([]);

  useEffect(() => {
    if (type === 'Weight') {
      setMeasure('KG');
    } else if (type === 'Distance') {
      setMeasure('KM');
    } else {
      setMeasure('Credits');
    }
  }, [type]);

  const addMore = () => {
    setAddMoreFields([
      ...addMoreFields,
      {
        formVariant: 'Add',
        measure: measure,
        id: addMoreFields.length + 1
      }
    ]);
  };

  const clearFields = () => {
    setAddMoreFields([]);
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <form action="#" method="POST">
          <div className="overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="flex flex-col justify-center sm:flex-row space-x-14">
                <div className="flex flex-col mt-5 space-y-3">
                  {pricingItems?.length > 0 ? (
                    pricingItems?.map((item) => (
                      <PricingCriteriaFields
                        key={item.id}
                        pricing={item}
                        measure={measure}
                        getPricing={getPricing}
                        formVariant="Edit"
                        type={type}
                        clearFields={clearFields}
                      />
                    ))
                  ) : (
                    <PricingCriteriaFields
                      measure={measure}
                      getPricing={getPricing}
                      formVariant="Add"
                      type={type}
                      cityId={cityId}
                      branchId={branchId}
                      clearFields={clearFields}
                    />
                  )}
                  {addMoreFields?.map((item) => (
                    <PricingCriteriaFields
                      clearFields={clearFields}
                      key={item.id}
                      measure={item.measure}
                      getPricing={getPricing}
                      formVariant={item.formVariant}
                      type={type}
                      cityId={cityId}
                      branchId={branchId}
                    />
                  ))}
                </div>
              </div>

              <div className="flex justify-center mt-10 space-x-5">
                <Button
                  text="Close"
                  onClick={() => {
                    closeModal();
                    getPricing();
                  }}
                  bg="bg-white"
                  border="border-blue"
                  textColor="text-blue"
                  rounded
                />
                {type !== 'Base' && <Button text="Add more" onClick={addMore} textColor="text-white" rounded />}
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default PricingCriteriaFormEdit;
