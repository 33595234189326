import React from 'react';

import Alert from '../../../../../Shared/components/Alert.jsx';
import DynamicIcon from '../../../../../Shared/components/DynamicIcon.jsx';
import BasePill from '../../../../../Shared/components/Pills/BasePill.jsx';
import CreditPill from '../../../../../Shared/components/Pills/CreditPill.jsx';
import Tooltip from '../../../../../Shared/components/Tooltip.jsx';
import { formatNumber } from '../../../../../Shared/helpers/numberFormatter.js';

const PricingBlock = ({ pricing, allOrderCount, creditError }) => {
  return (
    <div className="flex flex-col w-4/5 p-1 mx-auto space-y-2 text-center">
      {creditError ? <Alert variant="error" text="You don't have enough credits to complete this request!" /> : null}
      <div className="flex items-center justify-between">
        <p className="font-medium text-md">Order count: </p>
        <BasePill text={`${allOrderCount} order(s)`} />
      </div>
      <hr className="w-full mx-auto my-1 border-[1px] rounded-full bg-slate-100 border-slate-100" />
      <div className="flex items-center justify-between">
        <p className="font-medium text-md">Base fee: </p>
        <CreditPill amount={formatNumber(pricing.baseFee * allOrderCount)} />
      </div>
      <div className="flex items-center justify-between">
        <p className="font-medium text-md">Distance fee: </p>
        <CreditPill amount={formatNumber(pricing.distanceFee * allOrderCount)} bgColor="bg-zinc-200" textColor="text-zinc-700" />
      </div>
      <div className="flex items-center justify-between ">
        <p className="font-medium text-md">Weight fee: </p>
        <CreditPill amount={formatNumber(pricing.weightFee * allOrderCount)} bgColor="bg-stone-200" textColor="text-stone-700" />
      </div>
      <div className="flex items-center justify-between ">
        <p className="font-medium text-md">Service fee: </p>
        <div className="flex items-center space-x-2">
          <Tooltip message={`5% payment processing fee & 8% LastLap service fee`}>
            <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
          </Tooltip>
          <CreditPill amount={formatNumber(pricing?.serviceFee * allOrderCount)} bgColor="bg-stone-200" textColor="text-stone-700" />
        </div>
      </div>
      <hr className="w-full mx-auto my-1 border-[1px] rounded-full bg-slate-100 border-slate-100" />
      <div className="flex items-center justify-between">
        <p className="text-lg font-bold text-blue"> Total credits to be used:</p>
        <div className="flex items-center space-x-2">
          <Tooltip message={`equals ${pricing.totalFee * allOrderCount} dkk`}>
            <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
          </Tooltip>
          <CreditPill amount={formatNumber(pricing.totalFee * allOrderCount)} bgColor="bg-blue" textColor="text-white" />
        </div>
      </div>
    </div>
  );
};

export default PricingBlock;
