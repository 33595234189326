import BusinessNavigation from '../../Business/components/BusinessNavigation/BusinessNavigation';
import FleetNavigation from '../../Fleet/components/FleetNavigation/FleetNavigation';
import useAuth from '../hooks/useAuth';

const Navbar = () => {
  const { user } = useAuth();

  return (
    <div className="flex flex-col flex-wrap items-center justify-center w-screen mt-5 space-x-0 uppercase sm:flex-row sm:space-x-9">
      {user && user?.type === 'fleet' ? <FleetNavigation /> : <BusinessNavigation />}
    </div>
  );
};

export default Navbar;
