import React from 'react';

const UserStatusPill = ({ status }) => {
  switch (status) {
    case 'active':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-green-700 bg-green-200`}>{status}</p>;
    case 'pending':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-yellow-600 bg-yellow-200`}>{status}</p>;
    case 'deactivated':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-red-700 bg-red-200`}>{status}</p>;
    default:
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-gray-700 bg-gray-200`}>{status}</p>;
  }
};

export default UserStatusPill;
