import { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { useSearchParams } from 'react-router-dom';

const ContractTypeFilter = () => {
  const [criteriaFilter, setCriteriaFilter] = useState('All');

  const [searchParams, setSearchParams] = useSearchParams();

  const handleCriteriaFilter = (criteria) => {
    if (criteria === 'All') {
      searchParams.delete('payrollId');
    } else {
      let value;
      if (criteria === 'Order') {
        value = 1;
      } else if (criteria === 'Shift') {
        value = 2;
      } else if (criteria === 'Hourly') {
        value = 3;
      } else if (criteria === 'Order & Shift') {
        value = 4;
      }
      searchParams.set('payrollId', value);
    }
    setSearchParams(searchParams);
    setCriteriaFilter(criteria);
  };

  const criteriaFilterList = ['All', 'Order', 'Shift', 'Hourly', 'Order & Shift'];

  return (
    <div className="flex items-center space-x-3 z-[49]">
      <h2 className="whitespace-nowrap">Contract type:</h2>
      <div className="relative z-10">
        <Listbox value={criteriaFilter} onChange={handleCriteriaFilter}>
          <div className="relative mt-1">
            <Listbox.Button className="relative py-2 pl-3 pr-10 text-left text-white bg-gray-500 shadow-md cursor-pointer w-fit rounded-3xl focus:outline-none focus-visible:border-blue focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
              <span className="block truncate">{criteriaFilter}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon className="w-5 h-5 text-white" aria-hidden="true" />
              </span>
            </Listbox.Button>
            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options className="absolute py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 min-w-max ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {criteriaFilterList.map((criteria, criteriaIdx) => (
                  <Listbox.Option
                    key={criteriaIdx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-2 border-b border-gray-200 pr-10 ${
                        active ? 'bg-yellow-400 text-amber-900' : 'text-gray-900'
                      }`
                    }
                    value={criteria}>
                    {({ selected }) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{criteria}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 right-0 flex items-center pr-2 text-amber-600">
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
};

export default ContractTypeFilter;
