import { useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import { axiosPrivate } from '../../api/axios';
import { userInviteSendEmail } from '../../api/services/Auth';
import { updateUser } from '../../api/services/User';
import useAuth from '../../hooks/useAuth';
import Button from '../Button';
import 'react-phone-number-input/style.css';
// import ConfirmDeleteModal from '../Modals/ConfirmDeleteModal';

const UsersForm = ({ closeModal, getUsers, type, user }) => {
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  // const [approved, setApproved] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const errRef = useRef();

  const { user: authUser } = useAuth();
  const accountType = authUser?.type;
  const typeId = accountType === 'business' ? authUser?.businessId : accountType === 'fleet' ? authUser?.fleetId : null;

  const inviteUserUrl = userInviteSendEmail();
  const updateUserUrl = updateUser(userId);

  useEffect(() => {
    if (user) {
      console.log(userId);
      setUserId(user.id);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setRole(user.role);
      setEmail(user.email);
      // setApproved(user.approved);
    }
  }, [user]);

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
  };

  // const toggleDeleteModal = () => {
  //   setDeleteModal(!deleteModal);
  // };

  // const handleDeactivate = async () => {
  //   setSubmitting(true);

  //   console.log('deactived user or smth');
  // try {
  //   await axiosPrivate.put(updateBranchUrl, { active: !active });
  //   getUsers();
  //   closeModal();
  //   toast.success('User status changed!');
  // } catch (error) {
  //   toast.error('Something went wrong...');
  // }
  //   setSubmitting(false);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    console.log('submitting');
    try {
      if (type === 'Edit') {
        const data = {
          role
        };
        const response = await axiosPrivate.put(updateUserUrl, data);
        console.log(response);
        toast.success('User role changed!');
      } else {
        const data = {
          firstName,
          lastName,
          email,
          role,
          type: accountType,
          typeId
        };
        const response = await axiosPrivate.post(inviteUserUrl, data);
        console.log(response);
        toast.success(`User invitation sent to ${email}!`);
      }
      getUsers();
      clearFields();
      closeModal();
    } catch (err) {
      if (!err?.response) {
        setErrorMsg('Something went wrong...');
      } else if (err?.response?.status === 403) {
        setErrorMsg('User with such email already exists or has been invited!');
      } else {
        setErrorMsg('Something went wrong, try again later or contact support');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  const inputStyle = `${type === 'Edit' ? 'text-gray-400 cursor-not-allowed' : 'text-gray-700'} col-span-4 sm:col-span-4`;

  return (
    <>
      <div className="mt-3 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center mb-1' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className={inputStyle}>
                  <label htmlFor="first-name" className="block text-sm font-medium">
                    First Name*
                  </label>
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    disabled={type === 'Edit'}
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    autoComplete="first-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className={inputStyle}>
                  <label htmlFor="last-name" className="block text-sm font-medium">
                    Last Name*
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    disabled={type === 'Edit'}
                    required
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    autoComplete="last-name"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                {/* {type !== 'Edit' && (
                  <div className={inputStyle}>
                    <label htmlFor="phoneNumber" className="block text-sm font-medium ">
                      Phone number
                    </label>
                    <PhoneInput international defaultCountry="DK" value={phoneNumber} onChange={setPhoneNumber} />
                  </div>
                )} */}

                <div className={inputStyle}>
                  <label htmlFor="email" className="block text-sm font-medium">
                    Email*
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    disabled={type === 'Edit'}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    autoComplete="email"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="role" className="block text-sm font-medium">
                    User Role*
                  </label>
                  <select
                    name="role"
                    id="role"
                    onChange={(e) => setRole(e.target.value)}
                    value={role}
                    autoComplete="role"
                    className="block w-full px-3 py-2 mt-1 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue sm:text-sm">
                    <option disabled value={''}>
                      Select user role
                    </option>
                    <option value="admin">Admin</option>
                    <option value="user">User</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  disabled={submitting}
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                />

                <Button
                  text={type === 'Edit' ? 'Submit' : 'Send Invite'}
                  onClick={handleSubmit}
                  type="submit"
                  disabled={submitting || !firstName.length || !lastName.length || !email.length || !role.length}
                />

                {/* {type === 'Edit' && user.approved !== null && user.inviteTokenExpiresAt === null && (
                  <Button
                    text={approved ? 'Deactivate' : 'Activate'}
                    onClick={toggleDeleteModal}
                    disabled={submitting}
                    bg={approved ? 'bg-red-500' : 'bg-green-600'}
                    border={approved ? 'border-red-500' : 'border-green-600'}
                  />
                )} */}
              </div>
            </div>
          </div>
        </form>
        {/* {deleteModal && branchId !== null && (
          <ConfirmDeleteModal
            disabled={submitting}
            cancel={toggleDeleteModal}
            isOpen={deleteModal}
            remove={handleDeactivate}
            id={branchId}
            action={active ? 'deactivate' : 'activate'}
            name="branch"
          />
        )} */}
      </div>
    </>
  );
};

export default UsersForm;
