import { useState, useEffect } from 'react';

import { Outlet } from 'react-router-dom';

import Loader from '../components/Loader';
import useAuth from '../hooks/useAuth';
import useRefreshToken from '../hooks/useRefreshToken';

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
        // setIsLoading(false);
      }
    };

    // !auth?.token && persist ? verifyRefreshToken() : setIsLoading(false);
    !auth?.token ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  // useEffect(() => {
  //   console.log(`isLoading: ${isLoading}`);
  //   console.log(`aT: ${JSON.stringify(auth?.token)}`);
  // }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div className="justify-center flex mt-96">
          {' '}
          <Loader />{' '}
        </div>
      ) : (
        <Outlet />
      )}
    </>
  );
  //   return <>{!persist ? <Outlet /> : isLoading ? <p>Loading...</p> : <Outlet />}</>;
};

export default PersistLogin;
