const DRIVER_URL = '/driver';

export const getDriversByFleetId = (fleetId, filters) => {
  const url = `${DRIVER_URL}/fleet/${fleetId}${filters}`;
  return url;
};

export const getDriversByFleetIdAndCity = (fleetId, cityName) => {
  const url = `${DRIVER_URL}/fleet/${fleetId}/${cityName}`;
  return url;
};

export const getActiveDriversByFleetIdAndCity = (fleetId, cityName) => {
  const url = `${DRIVER_URL}/fleet/active/${fleetId}/${cityName}`;
  return url;
};

export const createDriver = () => {
  const url = `${DRIVER_URL}`;
  return url;
};

export const updateDriver = (id) => {
  const url = `${DRIVER_URL}/${id}`;
  return url;
};

export const getDriverByFleetId = () => {
  const url = `${DRIVER_URL}`;
  return url;
};

export const getClockedInDrivers = (fleetId, cityName) => {
  const url = `${DRIVER_URL}/clockedin/${fleetId}/${cityName}`;
  return url;
};

export const getDriverLocation = (fleetId, driverId) => {
  const url = `${DRIVER_URL}/location/${fleetId}/${driverId}`;
  return url;
};

export const getAllDriverLocation = (fleetId) => {
  const url = `${DRIVER_URL}/location/all/fleet/${fleetId}`;
  return url;
};
