import Heading from '../../../Shared/components/Heading';
import BranchList from '../../components/FleetConnection/BranchList';

const FleetConnection = () => {
  return (
    <>
      <div className="max-w-max mx-auto mt-10 flex flex-col space-y-10 items-center">
        <Heading text="Fleet Connection" />
        <BranchList />
      </div>
    </>
  );
};

export default FleetConnection;
