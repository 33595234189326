import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../Shared/components/Button';
import Loader from '../../../Shared/components/Loader';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../Shared/components/Pills/StatusPill';
import PricingCriteriaList from '../Sales/Pricing/PricingCriteriaList';

const CustomerTable = ({ customers, loading, error, getCustomers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [customer, setCustomer] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const headers = [
    // { label: 'ID', key: 1 },
    { label: 'Company Name', key: 2 },
    { label: 'City', key: 3 },
    { label: 'Connection Day', key: 4 },
    { label: 'Fleet Connection', key: 5 },
    { label: 'Pricing', key: 6 },
    { label: 'Manage', key: 7 }
  ];

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl max-h-[500px]">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase">
            <tr className="sticky top-0 bg-gray-50">
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {customers && (
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id} className="bg-white border-b whitespace-nowrap">
                  <td className="px-6 py-4">{customer.name}</td>
                  <td className="px-6 py-4">{customer.city}</td>
                  <td className="px-6 py-4">{dayjs(customer.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4 text-green-500">
                    <StatusPill status={customer?.fleetId !== null ? 1 : 0} />
                  </td>
                  <td className="px-6 py-4">{customer.hasSpecialPricing == 1 ? 'SPECIAL' : 'CITY'}</td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text={customer.hasSpecialPricing == 1 ? 'Edit custom pricing' : 'Add custom pricing'}
                        bg="bg-blue"
                        border="border-blue"
                        rounded
                        onClick={() => {
                          toggleModal();
                          setCustomer(customer);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Edit Pricing"
          body={<PricingCriteriaList pricingType="CustomerPricing" customer={customer} getCustomers={getCustomers} />}
        />
      )}
    </div>
  );
};

export default CustomerTable;
