import CopyText from '../../../Shared/components/CopyText';
import useAuth from '../../../Shared/hooks/useAuth';

const ApiControlHeader = () => {
  const { user } = useAuth();
  const businessId = user?.businessId;

  return (
    <div className="my-2 overflow-hidden bg-white shadow md:rounded-full rounded-2xl">
      <div className="flex flex-col items-center p-4 justify-items-center">
        <div className="flex flex-col items-center xl:flex-row">
          <span> Your business ID: </span>
          <div className="flex items-center ml-2 space-x-2">
            <div className="text-xl font-bold text-blue">{businessId}</div>
            <CopyText text={businessId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiControlHeader;
