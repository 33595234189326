import * as HIcons from '@heroicons/react/24/outline';

const DynamicIcon = ({ icon, height, width, color, hover, onClick }) => {
  const { ...icons } = HIcons;
  const TheIcon = icons[icon];

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div onClick={handleClick}>
      <TheIcon className={`${height} ${width} {${color} ? ${color} : 'text-black'}, {${hover} ? ${hover} : ''}`} aria-hidden="true" />
    </div>
  );
};

DynamicIcon.defaultProps = {
  // color: 'text-black',
  height: 'h-6',
  width: 'w-6',
  icon: 'ComputerDesktopIcon'
};

export default DynamicIcon;
