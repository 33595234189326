import { useContext, useEffect, useRef, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../../Shared/components/Button';
import useAuth from '../../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../../Shared/hooks/useAxiosPrivate';
import { getGeocode } from '../../../../api/services/DistanceService';
import { DeliveryContext } from '../../../../contexts/DeliveryContext';

const GeocodeAddressForm = ({ closeModal }) => {
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const { setCustomPickupDetails, customPickupDetails } = useContext(DeliveryContext);

  const { user } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const errRef = useRef();
  const geocodeUrl = getGeocode();

  useEffect(() => {
    if (Object.keys(customPickupDetails).length) {
      setAddress(customPickupDetails?.address);
      setCity(customPickupDetails?.city);
      setPostCode(customPickupDetails?.postCode);
    }
  }, [customPickupDetails]);

  const clearFields = () => {
    setAddress('');
    setCity('');
    setPostCode('');
  };

  const handleSubmit = async () => {
    setSubmitting(true);
    const addressToValidate = address + ' ' + city + ' ' + postCode;
    const data = {
      address: addressToValidate,
      countryCode: user.countryCode
    };
    try {
      console.log(data);
      const response = await axiosPrivate.post(geocodeUrl, data);
      console.log('GEOCODE', response.data);
      toast.success('Custom address set!');
      setCustomPickupDetails({
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        address: address,
        city: city,
        postCode: postCode
      });
      clearFields();
      closeModal();
    } catch (err) {
      if (!err?.response) {
        setErrorMsg('No Server Response');
      } else if (err.response?.status === 422) {
        setErrorMsg('Could not get the geocode of the address. Make sure it is valid.');
      } else {
        setErrorMsg('Address validation failed, please try again.');
      }
      errRef.current.focus();
    }
    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
          {errorMsg}
        </p>
        <form action="#" method="POST" onSubmit={handleSubmit}>
          <div className="overflow-hidden shadow sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-4 gap-6">
                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    Address*
                  </label>
                  <input
                    type="text"
                    name="address"
                    id="address"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    autoComplete="address"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                    City*
                  </label>
                  <input
                    type="text"
                    name="city"
                    id="city"
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    autoComplete="city"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>

                <div className="col-span-4 sm:col-span-4">
                  <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">
                    Postal code*
                  </label>
                  <input
                    type="number"
                    name="postCode"
                    id="postCode"
                    onChange={(e) => setPostCode(e.target.value)}
                    value={postCode}
                    autoComplete="postCode"
                    className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex justify-center mt-6 space-x-5">
                <Button
                  text="Cancel"
                  onClick={closeModal}
                  bg="bg-white"
                  border="border-blue"
                  textColor={`${submitting ? 'text-white' : 'text-blue'}`}
                  disabled={submitting}
                />
                <Button text="Submit" onClick={handleSubmit} disabled={!address.length || !city.length || !postCode.length || submitting} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default GeocodeAddressForm;
