const API_KEY_URL = '/key';

export const getApiKeysByBusinessId = (businessId) => {
  const url = `${API_KEY_URL}/${businessId}`;
  return url;
};

export const createApiKey = () => {
  const url = `${API_KEY_URL}`;
  return url;
};

export const deleteApiKey = (id) => {
  const url = `${API_KEY_URL}/${id}`;
  return url;
};
