import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useLogout from '../hooks/useLogout';
import DynamicIcon from './DynamicIcon';
import Logo from './Logo';
import EditUserDetailsModal from './User/EditUserDetailsModal';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();
  const logout = useLogout();
  const { user } = useAuth();

  console.log(user);

  const signOut = async () => {
    await logout();
    navigate('/login');
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="relative hidden w-screen p-4 lg:flex">
        <div className="flex flex-col items-center w-full mx-auto place-content-center">
          <Logo />
          <div className="text-sm uppercase text-dark">{user.countryName}</div>
        </div>
        <div className="absolute flex space-x-2 right-5 h-max top-5">
          {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
            <div className="flex flex-row items-center justify-center px-3 py-2 bg-gray-200 border-2 cursor-pointer border-blue rounded-xl hover:opacity-80">
              <div className="font-semibold text-blue">STAGING</div>
            </div>
          )}
          <div onClick={toggleModal} className="flex flex-row px-3 py-2 bg-gray-200 border-2 cursor-pointer rounded-xl hover:opacity-80">
            <div className="mr-2">{user.email}</div>
            <DynamicIcon icon="Cog6ToothIcon" />
          </div>
          <div className="px-3 py-2 bg-gray-200 border-2 cursor-pointer rounded-xl md:flex md:flex-row hover:opacity-80" onClick={signOut}>
            <div className="mr-2">Logout</div>
            <DynamicIcon icon="ArrowRightOnRectangleIcon" />
          </div>
        </div>
      </div>

      {/* MOBILE VIEW */}
      <div className="flex flex-col items-center w-screen p-4 lg:hidden">
        <div className="flex flex-col items-center w-full mx-auto place-content-center">
          <Logo />
          <div className="text-sm uppercase text-dark">{user.countryName}</div>
        </div>
        <div className="flex flex-col items-center justify-center w-full mt-4 space-y-2">
          {process.env.REACT_APP_ENVIRONMENT === 'staging' && (
            <div className="flex flex-row items-center justify-center px-3 py-2 bg-gray-200 border-2 cursor-pointer border-blue rounded-xl hover:opacity-80">
              <div className="font-semibold text-blue">STAGING</div>
            </div>
          )}
          <div onClick={toggleModal} className="flex px-3 py-2 bg-gray-200 border-2 cursor-pointer rounded-xl hover:opacity-80">
            <div className="mr-2">{user.email}</div>
            <DynamicIcon icon="Cog6ToothIcon" />
          </div>
          <div className="flex px-3 py-2 bg-gray-200 border-2 cursor-pointer rounded-xl hover:opacity-80" onClick={signOut}>
            <div className="mr-2">Logout</div>
            <DynamicIcon icon="ArrowRightOnRectangleIcon" />
          </div>
        </div>
      </div>
      {isOpen && user && <EditUserDetailsModal closeModal={toggleModal} isOpen={isOpen} />}
    </>
  );
};

export default Header;
