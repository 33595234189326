import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../../Shared/components/Pills/StatusPill';
import ContractsForm from './ContractsForm';

const ContractsTable = ({ contracts, loading, error, getContracts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contract, setContract] = useState(null);

  const headers = [
    { label: 'Contract Name', key: 1 },
    { label: 'Type', key: 2 },
    { label: 'Created at', key: 3 },
    { label: 'Drivers assigned', key: 4 },
    { label: 'Status', key: 5 },
    { label: 'Manage', key: 6 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const displayContractType = (payrollId) => {
    switch (payrollId) {
      case '1':
        return 'Order';
      case '2':
        return 'Shift';
      case '3':
        return 'Hourly';
      default:
        return 'Order & Shift';
    }
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {contracts &&
              contracts.map((contract) => (
                <tr key={contract.id} className="bg-white border-b whitespace-nowrap">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {contract.name}
                  </th>
                  <td className="px-6 py-4">{displayContractType(contract.payrollId)}</td>
                  <td className="px-6 py-4">{dayjs(contract.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4">{contract.driverCount}</td>
                  <td className="px-2 py-4">
                    <StatusPill status={contract.active} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        rounded
                        bg="bg-blue"
                        border="border-blue"
                        onClick={() => {
                          toggleModal(), setContract(contract);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Edit Contract"
          body={<ContractsForm closeModal={toggleModal} getContracts={getContracts} contract={contract} type="Edit" />}
        />
      )}
    </div>
  );
};

export default ContractsTable;
