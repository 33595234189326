import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { createOrUpdateBusinessNote } from '../../../api/services/DeliveryService';

const NoteForm = ({ closeModal, labelText, delivery, getDeliveries, readonly = false }) => {
  const [charCount, setCharCount] = useState(0);
  const [error, setError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [note, setNote] = useState('');

  const axiosPrivate = useAxiosPrivate();

  const createOrUpdateNoteUrl = createOrUpdateBusinessNote(delivery?.id);

  const maxValue = 300;

  useEffect(() => {
    if (delivery) {
      setNote(delivery?.businessNote?.length ? delivery?.businessNote : '');
      setCharCount(delivery?.businessNote?.length ?? 0);
    }
  }, [delivery]);

  const clearFields = () => {
    setNote('');
    setCharCount(0);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    setCharCount(newValue?.length);

    if (newValue?.length > maxValue) {
      setError(true);
    } else {
      setError(false);
    }

    setNote(newValue);
  };

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      businessNote: note
    };

    try {
      const response = await axiosPrivate.put(createOrUpdateNoteUrl, data);
      console.log('response', response);
      toast.success('Note added!');
      getDeliveries();
      closeModal();
      clearFields();
    } catch (error) {
      console.log(error);
      toast.error('Error happened while adding the note! Try again later...');
    }
    setSubmitting(false);
  };

  return (
    <>
      {!readonly ? (
        <form action="#" method="POST" onSubmit={handleNoteSubmit}>
          <div className="">
            <label htmlFor="note" className="block text-sm font-bold text-gray-700">
              {labelText}
            </label>
            <textarea
              type="text"
              name="note"
              id="note"
              value={note}
              onChange={handleChange}
              autoComplete="note"
              className={`block w-full h-32 mt-1 border-gray-300 rounded-md shadow-sm sm:text-sm ${
                error ? 'focus:ring-red-500 focus:border-red-500' : 'focus:ring-blue focus:border-blue'
              }`}
            />
            <div className={`flex justify-end text-sm text-right mt-2 space-x-2 text-${error ? 'red' : 'gray'}-500`}>
              {error && <span className="ml-2">Character limit exceeded</span>}
              <span>
                {charCount}/{maxValue}
              </span>
            </div>
          </div>
          <div className="flex justify-end w-full mt-2 space-x-2">
            <div>
              <Button small text="Cancel" bg="bg-white" textColor="text-blue" border="border-blue" width="w-fit" onClick={closeModal} disabled={submitting} />
            </div>
            <div>
              <Button text="Submit" small width="w-fit" type="submit" disabled={!note?.length || submitting || error} />
            </div>
          </div>
        </form>
      ) : (
        <div>
          {note?.length > 0 ? (
            <div className="mt-5">
              <h3 className="font-bold text-gray-700 text-md">Business Note:</h3>
              <p className="mt-2 text-sm italic">&quot;{note}&quot;</p>
            </div>
          ) : (
            <div className="flex flex-col items-center space-y-10">
              <h3 className="mt-5 text-center text-dark text-md">No notes were added 💭</h3>
            </div>
          )}
          <div className="flex flex-col items-center mt-10 space-y-10">
            <div>
              <Button small text="close" bg="bg-white" textColor="text-blue" border="border-blue" width="w-fit" onClick={closeModal} disabled={submitting} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default NoteForm;
