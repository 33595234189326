import { useState } from 'react';

import dayjs from 'dayjs';

import Button from '../../../../Shared/components/Button';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import StatusPill from '../../../../Shared/components/Pills/StatusPill';
import EditCityForm from './EditCityForm';

const CityTable = ({ loading, error, cities, getCities }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [city, setCity] = useState(null);

  const headers = [
    { label: 'City name', key: 1 },
    { label: 'Created at', key: 2 },
    { label: 'Drivers assigned', key: 3 },
    { label: 'Status', key: 4 },
    { label: 'Manage', key: 5 }
  ];

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative mt-2 overflow-x-auto border border-gray-200 rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5 ">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {cities && (
            <tbody>
              {cities.map((city) => (
                <tr key={city.id} className="bg-white border-b whitespace-nowrap">
                  <td className="px-6 py-4">{city.name}</td>
                  <td className="px-6 py-4">{dayjs(city.createdAt).format('MMM DD, YYYY')}</td>
                  <td className="px-6 py-4">{city.driverCount}</td>
                  <td className="px-2 py-4">
                    <StatusPill status={city.active} />
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex space-x-2">
                      <Button
                        text="Edit"
                        bg="bg-blue"
                        rounded
                        border="border-blue"
                        onClick={() => {
                          toggleModal(), setCity(city);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Edit City"
          body={<EditCityForm closeModal={toggleModal} getCities={getCities} city={city} type="Edit" />}
        />
      )}
    </div>
  );
};

export default CityTable;
