import { useEffect, useState } from 'react';

import Button from '../../../Shared/components/Button';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import Heading from '../../../Shared/components/Heading';
import BaseModal from '../../../Shared/components/Modals/BaseModal';
import useAuth from '../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../Shared/hooks/useAxiosPrivate';
import { getBranchesByBusinessId } from '../../api/services/BranchService';
import BranchesForm from '../../components/Branches/BranchesForm';
import BranchesTable from '../../components/Branches/BranchesTable';

const Branches = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const { user } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const id = user?.businessId;
  const branchesUrl = getBranchesByBusinessId(id);

  useEffect(() => {
    setError('');
    getBranches();
  }, []);

  const getBranches = async () => {
    try {
      const response = await axiosPrivate.get(branchesUrl);
      console.log(response.data);
      if (response.data.length === 0) {
        setError('No branches have been created so far');
      } else {
        setBranches(response.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full m-auto md:max-w-7xl">
      <div className="flex items-center justify-between">
        <Heading text="Created Branches" />
        <Button text="Add Branch" onClick={toggleModal} rounded>
          <DynamicIcon icon="PlusCircleIcon" />
        </Button>
      </div>
      <BranchesTable branches={branches} loading={loading} error={error} getBranches={getBranches} />
      {isOpen && (
        <BaseModal
          closeModal={toggleModal}
          isOpen={isOpen}
          title="Add Branch"
          body={<BranchesForm type="Add" getBranches={getBranches} closeModal={toggleModal} />}
        />
      )}
    </div>
  );
};

export default Branches;
