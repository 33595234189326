import { useState } from 'react';

import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import DynamicIcon from '../../../../Shared/components/DynamicIcon';
import Loader from '../../../../Shared/components/Loader';
import ConfirmModal from '../../../../Shared/components/Modals/ConfirmModal';
import Tooltip from '../../../../Shared/components/Tooltip';
import { formatNumber } from '../../../../Shared/helpers/numberFormatter';
import useAuth from '../../../../Shared/hooks/useAuth';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { postWithdrawal } from '../../../api/services/WithdrawalService';

const WithdrawalRequest = ({ loadingBalance, creditBalance, getPreviousWithdrawal, getWithdrawableCredits }) => {
  const [confirmationModal, setConfirmationModal] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const { user } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const withdrawalUrl = postWithdrawal();

  const fleetId = user?.fleetId;
  const userCurrency = user?.currency;

  const availableCredits = creditBalance?.totalAvailableCredits ? creditBalance.totalAvailableCredits : 0;
  const receivedCredits = creditBalance?.totalReceivedCredits ? creditBalance.totalReceivedCredits : 0;

  const exchangeRatesAgainstDkk = {
    DKK: 1,
    EUR: 0.13,
    USD: 0.15,
    ILS: 0.55
  };

  const calculateAvailableValue = () => {
    if (userCurrency === 'DKK') {
      console.log('dkk here');
      return `${formatNumber(availableCredits?.toFixed(1))} ${userCurrency}`;
    } else {
      const availableValue = availableCredits * exchangeRatesAgainstDkk[userCurrency];
      return `${formatNumber(availableValue)} ${userCurrency}`;
    }
  };

  const handleSubmitWithdrawal = async () => {
    setSubmitting(true);
    setLoading(true);
    const data = {
      fleetId,
      totalReceivedCredits: creditBalance?.totalReceivedCredits,
      withdrawableAmount: availableCredits
    };
    try {
      const response = await axiosPrivate.post(withdrawalUrl, data);
      console.log('AV CREDS', response.data);
      setLoading(false);
      setConfirmationModal(false);
      toast.success('Withdrawal request sent');
      getPreviousWithdrawal();
      getWithdrawableCredits();
    } catch (error) {
      console.log(error);
      setError('Something went wrong');
      setLoading(false);
      toast.error('Something went wrong');
    }
    setSubmitting(false);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  return (
    <div className="flex flex-col items-center justify-center p-8 space-y-5 border border-gray-300 rounded-3xl">
      {loadingBalance ? (
        <Loader />
      ) : (
        creditBalance && (
          <>
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:space-x-10 sm:flex-row lg:flex-col lg:space-y-5 lg:space-x-0">
              <div className="text-center">
                <h2 className="text-sm">Total Credits</h2>
                <p className="text-2xl font-medium text-center text-blue">{formatNumber(receivedCredits)}</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex space-x-1">
                  <Tooltip message={`Total Credits  - 10% (LastLap service fee)`}>
                    <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
                  </Tooltip>
                  <h2 className="text-sm">Available Credits</h2>
                </div>
                <p className="text-2xl font-medium text-center text-blue">{formatNumber(availableCredits?.toFixed(1))}</p>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="flex space-x-1">
                  <Tooltip
                    message={`Available amount after calculation (1 credit ~ ${exchangeRatesAgainstDkk[userCurrency]} ${userCurrency})`}
                    subMessage="Conversion rate might change based on exchange rate at the time of withdrawal">
                    <DynamicIcon icon="InformationCircleIcon" width="w-5" height="h-5" />
                  </Tooltip>
                  <h2 className="text-sm">Available to withdraw</h2>
                </div>
                <p className="text-2xl font-medium text-center text-blue">{calculateAvailableValue()}</p>
              </div>
            </div>

            <div className="">
              <Button
                disabled={availableCredits <= 0 || submitting}
                onClick={toggleConfirmationModal}
                text="Request withdrawal"
                small
                rounded
                textSize="text-sm"
              />
            </div>
          </>
        )
      )}

      {confirmationModal && (
        <ConfirmModal
          disabled={submitting}
          cancel={toggleConfirmationModal}
          isOpen={confirmationModal}
          confirm={handleSubmitWithdrawal}
          action={'withdraw'}
          name={`amount of ${calculateAvailableValue()}`}
        />
      )}
    </div>
  );
};

export default WithdrawalRequest;
