import React from 'react';

const RolePill = ({ role }) => {
  switch (role) {
    case 'admin':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-indigo-700 bg-indigo-200`}>{role}</p>;
    case 'superadmin':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-indigo-600 bg-indigo-300`}>{role}</p>;
    case 'support':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-amber-600 bg-amber-200`}>{role}</p>;
    case 'user':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-fuchsia-700 bg-fuchsia-200`}>{role}</p>;
    case 'marketing':
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-rose-700 bg-rose-200`}>{role}</p>;
    default:
      return <p className={`capitalize px-2 py-1 w-28 rounded-full text-center font-bold text-gray-700 bg-gray-200`}>{role}</p>;
  }
};

export default RolePill;
