const SHIFT_URL = '/shift';

export const getShiftsByFleetId = (fleetId) => {
  const url = `${SHIFT_URL}/${fleetId}`;
  return url;
};
// export const getShiftsByFleetIdAndCity = (fleetId, cityName) => {
//   const url = `${SHIFT_URL}/${fleetId}/${cityName}`;
//   return url;
// };

export const getShiftsByFleetIdAndCity = (fleetId, cityName, filters) => {
  if (filters) {
    return `${SHIFT_URL}/${fleetId}/${cityName}${filters}`;
  } else {
    return `${SHIFT_URL}/${fleetId}/${cityName}`;
  }
};

export const createShift = () => {
  const url = `${SHIFT_URL}`;
  return url;
};

export const updateShift = (shiftId) => {
  const url = `${SHIFT_URL}/${shiftId}`;
  return url;
};

export const deleteShift = (shiftId) => {
  const url = `${SHIFT_URL}/${shiftId}`;
  return url;
};
