import React from 'react';

const InfoBanner = ({ variant, children }) => {
  const variantStyle = {
    success: 'text-green-800 border-green-500 bg-green-50',
    error: 'text-red-800 border-red-300 bg-red-50',
    warning: 'border-yellow-500 text-yellow-500 bg-yellow-50',
    info: 'border-blue text-blue bg-cyan-50'
  };

  return (
    <div id="alert-border-1" className={`w-full flex items-start p-4 border-l-4 rounded-md ${variantStyle[variant]}`} role="alert">
      <svg className="flex-shrink-0 w-4 h-4 mt-0.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
      </svg>
      <div className="ml-2">{children}</div>
    </div>
  );
};

export default InfoBanner;
