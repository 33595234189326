const BasePill = ({ text, bgColor, textColor }) => {
  return (
    <p
      className={`px-2 py-1 w-32 rounded-full text-sm text-center  font-bold ${bgColor ? bgColor : ' bg-gray-200'} ${textColor ? textColor : 'text-gray-700'}`}>
      {text}
    </p>
  );
};

export default BasePill;
