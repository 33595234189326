const BRANCH_URL = '/branch';

export const getBranchesByBusinessId = (businessId) => {
  const url = `${BRANCH_URL}/${businessId}`;
  return url;
};

export const getConnectedBranchesByBusinessId = (businessId) => {
  const url = `${BRANCH_URL}/connected/${businessId}`;
  return url;
};

export const createBranch = () => {
  const url = `${BRANCH_URL}`;
  return url;
};

export const updateBranch = (id) => {
  const url = `${BRANCH_URL}/${id}`;
  return url;
};

export const assignFleetToBranch = (id) => {
  const url = `${BRANCH_URL}/${id}/fleet`;
  return url;
};

export const disconnectFleetFromBranch = (id) => {
  const url = `${BRANCH_URL}/${id}/disconnect/fleet`;
  return url;
};
