import { useContext, useEffect, useState } from 'react';

import BaseModal from '../../../../../Shared/components/Modals/BaseModal';
import { DeliveryContext } from '../../../../contexts/DeliveryContext';
import BasicPickupForm from '../BasicPickup/BasicPickupForm';
import GeocodeAddressForm from './GeocodeAddressForm';

const CustomPickupForm = () => {
  const [geocodeAddressModal, setGeocodeAddressModal] = useState(false);

  const { submitted, customPickupDetails, setCustomPickupDetails, pickupType } = useContext(DeliveryContext);

  useEffect(() => {
    if (submitted) clearFields();
  }, [submitted]);

  const clearFields = () => {
    setCustomPickupDetails({});
  };

  const toggleGeocodeAddressPickupModal = () => {
    setGeocodeAddressModal(!geocodeAddressModal);
  };

  return (
    <div className="">
      <div className="w-full">
        <form>
          <div className="overflow-hidden sm:rounded-md">
            <div className="px-4 pt-5 pb-1 sm:px-6">
              <div className="grid grid-cols-4 gap-6">
                {pickupType === 'custom' && (
                  <div className="flex items-center col-span-4 space-x-3 text-left sm:col-span-4">
                    <label
                      htmlFor="customAddress"
                      className={`text-sm font-bold ${!customPickupDetails?.address ? 'text-red-500' : 'text-gray-700'} block w-2/5 text-sm min-w-fit`}>
                      Custom Address*
                    </label>
                    <input
                      type="text"
                      name="customAddress"
                      id="customAddress"
                      readOnly
                      onClick={toggleGeocodeAddressPickupModal}
                      value={
                        customPickupDetails.address?.length > 0
                          ? `${customPickupDetails.address}, ${customPickupDetails.city}, ${customPickupDetails.postCode}`
                          : ''
                      }
                      className="block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-blue focus:border-blue sm:text-sm"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      <BasicPickupForm />
      {geocodeAddressModal && (
        <BaseModal
          closeModal={toggleGeocodeAddressPickupModal}
          isOpen={geocodeAddressModal}
          withRefetch={true}
          title="Create custom pickup address"
          body={<GeocodeAddressForm closeModal={toggleGeocodeAddressPickupModal} type="Add" withRefetch={true} />}
        />
      )}
    </div>
  );
};

export default CustomPickupForm;
