const rules = {
  admin: {
    business: true,
    fleet: true
  },
  superadmin: {
    business: true,
    fleet: true
  },
  user: {
    business: [
      'overview:see',
      'deliveries:see',
      'deliveries-new:see',
      'deliveries-history:see',
      'deliveries-manage:see',
      'branches:see',
      'addresses:see',
      'contact:see'
    ],
    fleet: [
      'live-deliveries:see',
      'drivers:see',
      'drivers-all-drivers:see',
      'drivers-shifts:see',
      'sales:see',
      'sales-delivery-history:see',
      'customers:see',
      'contact:see'
    ]
  }
};

//   business: [
//     'overview:see',
//     'deliveries:see',
//     'deliveries-new:see',
//     'deliveries-history:see',
//     'deliveries-manage:see',
//     'credits:see',
//     'credits-buy:see',
//     'credits-history:see',
//     'addresses:see',
//     'fleet-connection:see',
//     'api-control:see',
//     'branches:see',
//     'users:see',
//     'contact:see'
//   ],
//   fleet: [
//     'live-deliveries:see',
//     'drivers:see',
//     'drivers-all-drivers:see',
//     'drivers-shifts:see',
//     'drivers-salaries:see',
//     'drivers-contracts:see',
//     'drivers-bonuses:see',
//     'drivers-cities-and-fleet-codes:see',
//     'sales:see',
//     'sales-analytics:see',
//     'sales-pricing:see',
//     'sales-delivery-history:see',
//     'sales-balance-and-withdraw:see',
//     'customers:see',
//     'users:see',
//     'contact:see'
//   ]

export default rules;
