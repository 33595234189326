import { useEffect, useRef, useState } from 'react';

import { CheckCircleIcon, ExclamationCircleIcon, LockClosedIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { completeInvitedUserCreation } from '../../api/services/Auth';
import DynamicIcon from '../../components/DynamicIcon';
import Loader from '../../components/Loader';
import Logo from '../../components/Logo';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const AcceptInvite = () => {
  const navigate = useNavigate();

  const { inviteToken } = useParams();
  const emailRef = useRef();
  const errRef = useRef();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const [errorMsg, setErrorMsg] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const completeCreationUrl = completeInvitedUserCreation(inviteToken);

  const axiosPrivate = useAxiosPrivate();

  useEffect(() => {
    emailRef?.current?.focus();
  }, []);

  useEffect(() => {
    setErrorMsg('');
  }, [password]);

  const handlePasswordChange = (event) => {
    // setPassword(event.target.value);
    setPassword(event.target.value.replace(/\s/g, ''));
    setIsPasswordValid(/^(?=.*[0-9!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(event.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    if (password !== repeatPassword) {
      setErrorMsg('Passwords do not match');
      errRef.current.focus();
      setSubmitting(false);
      return;
    }
    try {
      console.log(inviteToken, password);

      const data = { password };

      const response = await axiosPrivate.post(completeCreationUrl, data);
      console.log(response);

      if (response?.status === 200) {
        toast.success('User creation completed successfully!');
        navigate('/login');
      } else if (response?.status === 403) {
        setErrorMsg('No user found with such invite parameters');
      } else {
        setErrorMsg('Something went wrong, try again later or contact support');
      }
    } catch (err) {
      console.log(err);
      setErrorMsg('Something went wrong, login failed...');
    }
    errRef.current.focus();
    setSubmitting(false);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="flex items-center justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <Logo />
            <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-700">Create Password</h2>
          </div>
          <p ref={errRef} className={errorMsg ? 'text-red-500 text-center' : 'hidden'} aria-live="assertive">
            {errorMsg}
          </p>

          <form className="mt-8 space-y-3" action="#" method="POST" onSubmit={handleSubmit}>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="-space-y-px rounded-md shadow-sm">
              <div className="relative block">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={!showPassword ? 'password' : 'text'}
                  autoComplete="current-password"
                  // onChange={(e) => setPassword(e.target.value)}
                  onChange={handlePasswordChange}
                  value={password}
                  required
                  className="relative block w-full px-3 py-2 mt-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-t-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                  placeholder="*Password"
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5 text-gray-500 cursor-pointer"
                  onClick={handleClickShowPassword}>
                  {showPassword ? <DynamicIcon icon="EyeIcon" /> : <DynamicIcon icon="EyeSlashIcon" />}
                </div>
              </div>
              <div>
                <label htmlFor="repeatPassword" className="sr-only">
                  Repeat Password
                </label>
                <input
                  id="repeatPassword"
                  name="repeatPassword"
                  type="password"
                  // autoComplete="current-password"
                  onChange={(e) => setRepeatPassword(e.target.value)}
                  value={repeatPassword}
                  required
                  className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-none appearance-none rounded-b-md focus:outline-none focus:ring-blue focus:border-blue focus:z-10 sm:text-sm"
                  placeholder="*Repeat Password"
                />
              </div>
            </div>
            <div className="mt-1">
              {password.length > 0 && !isPasswordValid && (
                <div className="flex items-center mt-1 text-sm text-red-500">
                  <ExclamationCircleIcon className="w-4 h-4 mr-2" />
                  Password must contain at least 8 characters, capital letter and one special character.
                </div>
              )}
              {isPasswordValid && (
                <div className="flex items-center mt-1 text-green-500">
                  <CheckCircleIcon className="w-4 h-4 mr-2" />
                  Password is valid.
                </div>
              )}
            </div>

            <div>
              <button
                type="submit"
                disabled={!password.length || !repeatPassword.length || !isPasswordValid || submitting}
                className={`${
                  submitting ? 'bg-gray-300 cursor-not-allowed' : 'bg-blue cursor-pointer'
                } relative flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md group disabled:bg-gray-300 disabled:cursor-not-allowed hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue`}>
                {submitting ? (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <div className="ml-2">
                      <Loader small={true} />
                    </div>
                  </span>
                ) : (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <LockClosedIcon className="w-5 h-5 text-white group-hover:text-white" aria-hidden="true" />
                  </span>
                )}
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AcceptInvite;
