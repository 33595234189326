import React from 'react';

import Loader from '../../../../../Shared/components/Loader.jsx';
import BasePill from '../../../../../Shared/components/Pills/BasePill.jsx';
import PricingBlock from '../elements/PricingBlock.jsx';

const Summary = ({ isFreeAccount, loading, pricing, allOrderCount, creditError }) => {
  if (loading) {
    return (
      <div className="flex items-center justify-center h-full mb-8">
        <Loader loading={loading} />
      </div>
    );
  }

  return (
    <div className="flex flex-col my-8 space-y-8">
      <h1 className="text-2xl text-center text-slate-700">Final order summary</h1>
      <div>
        {isFreeAccount ? (
          <div className="flex flex-col w-4/5 p-1 mx-auto space-y-2 text-center">
            <div className="flex items-center justify-between">
              <p className="font-medium text-md">Order count: </p>
              <BasePill text={`${allOrderCount} order(s)`} />
            </div>

            <hr className="w-full mx-auto my-1 border-[1px] rounded-full bg-slate-100 border-slate-100" />
            <div className="flex items-center justify-between">
              <p className="text-lg font-bold text-blue"> Total Cost:</p>
              <div className="flex items-center space-x-2">
                <p className="px-2 py-1 text-sm font-bold text-center text-gray-700 bg-gray-200 rounded-full w-36 whitespace-nowrap">FREE</p>
              </div>
            </div>
          </div>
        ) : (
          <PricingBlock pricing={pricing} allOrderCount={allOrderCount} creditError={creditError} />
        )}
      </div>
    </div>
  );
};

export default Summary;
