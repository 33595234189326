import { useState } from 'react';

import dayjs from 'dayjs';
import { toast } from 'react-toastify';

import Button from '../../../../Shared/components/Button';
import DeliveryDetails from '../../../../Shared/components/DeliveryDetails';
import Loader from '../../../../Shared/components/Loader';
import BaseModal from '../../../../Shared/components/Modals/BaseModal';
import ConfirmDeleteModal from '../../../../Shared/components/Modals/ConfirmDeleteModal';
import TextModal from '../../../../Shared/components/Modals/TextModal';
import showDeliveryStatus from '../../../../Shared/helpers/showDeliveryStatus';
import useAxiosPrivate from '../../../../Shared/hooks/useAxiosPrivate';
import { cancelDelivery } from '../../../api/services/DeliveryService';
import NoteForm from './NoteForm';

const headers = [
  { label: 'ORDER ID', key: 1 },
  { label: 'PICK-UP', key: 2 },
  { label: 'DELIVERY', key: 3 },
  { label: 'STATUS', key: 4 },
  { label: 'MANAGE', key: 5 }
];

const ManageDeliveriesTable = ({ deliveries, loading, error, getDeliveries, type }) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);

  const [delivery, setDelivery] = useState(null);
  const [deliveryId, setDeliveryId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const cancelDeliveryUrl = cancelDelivery(deliveryId);

  const axiosPrivate = useAxiosPrivate();

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleInfoModal = () => {
    setInfoModal(!infoModal);
  };

  const toggleNoteModal = () => {
    setNoteModal(!noteModal);
  };

  const handleDeliveryCancel = async () => {
    setSubmitting(true);
    try {
      const response = await axiosPrivate.put(cancelDeliveryUrl);
      console.log(response);
      getDeliveries();
      toast.success('Order Cancelled!');
      toggleDeleteModal();
    } catch (error) {
      console.log(error);
      toast.error('Error happened while cancelling the order! Try again later...');
    }
    setSubmitting(false);
  };

  return (
    <div className="relative overflow-x-auto standard-border rounded-3xl">
      {loading ? (
        <div className="flex justify-center w-full p-5">
          <Loader />
        </div>
      ) : error ? (
        <p className="m-5 text-xl text-gray-500">{error}</p>
      ) : (
        <table className="w-full text-sm text-left text-gray-500 ">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {headers.map((header) => (
                <th key={header.key} scope="col" className="px-6 py-3 whitespace-nowrap">
                  {header.label}
                </th>
              ))}
            </tr>
          </thead>
          {deliveries && (
            <tbody>
              {deliveries.map((delivery) => (
                <tr key={delivery.id} className="bg-white border-b whitespace-nowrap">
                  <td
                    className="px-6 py-4 font-bold cursor-pointer text-blue w-max hover:opacity-80"
                    onClick={() => {
                      toggleInfoModal(), setDelivery(delivery);
                    }}>
                    {delivery.orderReference}
                  </td>
                  {type === 'future' ? (
                    <td className="px-6 py-4">{dayjs(delivery.pickupTime).format('MMM DD, HH:mm')}</td>
                  ) : (
                    <td className="px-6 py-4">{dayjs(delivery.pickupTime).format('HH:mm')}</td>
                  )}

                  {type === 'future' ? (
                    <td className="px-6 py-4">{dayjs(delivery.deliveryTime).format('MMM DD, HH:mm')}</td>
                  ) : (
                    <td className="px-6 py-4">{dayjs(delivery.deliveryTime).format('HH:mm')}</td>
                  )}
                  <td className="px-6 py-4">{showDeliveryStatus(delivery.status)}</td>
                  <td className="flex px-6 py-4 space-x-2">
                    {delivery.status === 0 && (
                      <div className="">
                        <Button
                          disabled={delivery.locked}
                          text="Cancel"
                          rounded
                          bg="bg-pink-500"
                          border="border-pink-500"
                          onClick={() => {
                            toggleDeleteModal(), setDeliveryId(delivery.id);
                          }}
                        />
                      </div>
                    )}
                    <div className="flex space-x-2">
                      <Button
                        text={delivery.businessNote ? 'Edit Note' : 'Add Note'}
                        bg={delivery.businessNote ? 'bg-white' : 'bg-blue'}
                        textColor={delivery.businessNote ? 'text-blue' : 'text-white'}
                        border="border-blue"
                        rounded
                        onClick={() => {
                          toggleNoteModal(), setDelivery(delivery);
                        }}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      )}
      {noteModal && delivery && (
        <TextModal
          closeModal={toggleNoteModal}
          isOpen={noteModal}
          type={delivery?.businessNote?.length ? 'edit' : 'add'}
          title={delivery?.businessNote?.length ? 'Edit Note' : 'Add Note'}
          labelText="Your note:"
          body={<NoteForm closeModal={toggleNoteModal} delivery={delivery} getDeliveries={getDeliveries} toggleNoteModal={toggleNoteModal} />}
        />
      )}
      {deleteModal && deliveryId !== null && (
        <ConfirmDeleteModal
          disabled={submitting}
          cancel={toggleDeleteModal}
          isOpen={deleteModal}
          remove={handleDeliveryCancel}
          id={deliveryId}
          name="delivery"
          action="cancel"
        />
      )}
      {infoModal && delivery && (
        <BaseModal
          closeModal={toggleInfoModal}
          isOpen={infoModal}
          title="Delivery Details"
          body={<DeliveryDetails closeModal={toggleInfoModal} delivery={delivery} />}
        />
      )}
    </div>
  );
};

export default ManageDeliveriesTable;
