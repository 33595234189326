import React, { useState } from 'react';

import _ from 'lodash';

import Divider from '../../../Shared/components/Divider';
import DynamicIcon from '../../../Shared/components/DynamicIcon';
import showDeliveryStatus from '../../../Shared/helpers/showDeliveryStatus';
import DriverMap from './DriverMap';

const DriverMapDetails = ({ closeModal, driver }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const toggleSelectedOrder = (order) => {
    if (selectedOrder?.id === order.id) {
      setSelectedOrder(null);
    } else {
      setSelectedOrder(order);
    }
  };

  const activeDeliveries = _.filter(driver?.orders, ({ status }) => status < 3);

  const finishedDeliveries = _.filter(driver?.orders, ({ status }) => status === 3);

  return (
    <div className="flex w-full h-[700px] ">
      <div className="w-1/3 h-full mr-3 space-y-2 overflow-auto">
        <div className="flex flex-col px-1 space-y-1">
          <div className="flex items-center space-x-2">
            <h1 className="text-2xl font-semibold text-gray-700">{driver.name}</h1>
            <img src={require(`../../../../public/icons/${driver?.vehicleId?.toString()}.svg`)} alt="" width={25} height={25} />
          </div>
          <p className="text-sm font-semibold text-gray-500">{driver.phoneNumber}</p>
        </div>
        <Divider />
        <div className="flex flex-col px-1 space-y-3 overflow-hidden">
          {activeDeliveries.length > 0 && (
            <div className="flex flex-col space-y-2">
              <h1 className="text-xl font-semibold text-gray-700">Ongoing Orders: </h1>
              {activeDeliveries.map((order) => (
                <DriverOrder select={() => toggleSelectedOrder(order)} selectedOrder={selectedOrder} order={order} key={order.id} />
              ))}
            </div>
          )}
          {finishedDeliveries.length > 0 && (
            <div className="flex flex-col space-y-2">
              <h1 className="text-xl font-semibold text-gray-700">Finished Orders: </h1>
              {finishedDeliveries.map((order) => (
                <DriverOrder select={() => toggleSelectedOrder(order)} selectedOrder={selectedOrder} order={order} key={order.id} />
              ))}
            </div>
          )}
          {activeDeliveries.length === 0 && finishedDeliveries.length === 0 && <p className="text-lg font-semibold text-gray-500">No orders yet</p>}
        </div>
      </div>
      <div className="w-2/3 h-full">
        <DriverMap closeModal={closeModal} driver={driver} selectedOrder={selectedOrder} />
      </div>
    </div>
  );
};

export default DriverMapDetails;

const DriverOrder = ({ order, selectedOrder, select }) => {
  return (
    <div
      onClick={select}
      className={`${
        order?.id === selectedOrder?.id ? 'border-blue' : 'border-gray-300'
      } hover:cursor-pointer hover:bg-gray-50 flex flex-col p-3 space-y-1 rounded-lg border-2`}>
      <div className="flex items-center justify-between">
        <p className="text-sm font-semibold text-gray-800">#{order.orderReference}</p>
        <span className="text-sm font-semibold text-gray-500">{showDeliveryStatus(order.status)}</span>
      </div>
      <div className="flex flex-col justify-center space-y-0.5">
        <div className="flex items-center space-x-1">
          <DynamicIcon icon="HomeIcon" width="w-4" height="h-4" />
          <p className="text-sm text-gray-600">
            {order.branchAddress}, {order.branchCity}, {order.branchPostCode}
          </p>
        </div>
        <div className="w-0.5 h-5 bg-gray-500 border-gray-500 border-0.5 ml-1.5"></div>
        <div className="flex items-center space-x-1">
          <DynamicIcon icon="MapPinIcon" width="w-4" height="h-4" />
          <p className="text-sm text-gray-600">
            {order.deliveryAddress}, {order.deliveryCity}, {order.deliveryPostCode}
          </p>
        </div>
      </div>
    </div>
  );
};
