import DynamicIcon from '../../../../../Shared/components/DynamicIcon';
import useAuth from '../../../../../Shared/hooks/useAuth';

const AdvancedBonusFields = ({
  measure,
  id,
  addMore,
  interval,
  newIntervals,
  handleAmountChange,
  handleMinDistanceChange,
  handleMaxDistanceChange,
  handleDeleteInterval
}) => {
  const { user } = useAuth();

  const labelStyle = 'block text-sm font-medium text-left text-gray-600';
  const inputWrapperStyle = 'flex items-center px-2 mt-1 space-x-1 border border-gray-300 rounded-md shadow-sm w-max';
  const inputStyle = 'block w-20 font-medium text-gray-500 border-none focus:ring-0 focus:border-blue text-md';

  const isRemoveDisabled = newIntervals?.length === 1;

  return (
    <div className="flex space-x-5">
      <div className="">
        <label htmlFor="minimum" className={labelStyle}>
          From
        </label>
        <div className={inputWrapperStyle}>
          <input
            type="number"
            name="minimum"
            id="minimum"
            onChange={(e) => handleMinDistanceChange(e, id)}
            value={interval?.minDistance}
            min={0}
            autoComplete="minimum"
            className={inputStyle}
          />
          <span className="text-sm font-medium text-gray-500 ">{measure}</span>
        </div>
      </div>

      <div className="">
        <label htmlFor="maximum" className={labelStyle}>
          To
        </label>
        <div className={inputWrapperStyle}>
          <input
            type="number"
            name="maximum"
            id="maximum"
            min={interval?.minDistance}
            onChange={(e) => handleMaxDistanceChange(e, id)}
            value={interval?.maxDistance}
            autoComplete="maximum"
            className={inputStyle}
          />
          <span className="text-sm font-medium text-gray-500">{measure}</span>
        </div>
      </div>

      <div className="">
        <label htmlFor="price" className={labelStyle}>
          Price
        </label>
        <div className={inputWrapperStyle}>
          <input
            type="number"
            name="price"
            id="price"
            min={0}
            onChange={(e) => handleAmountChange(e, id)}
            value={interval?.amount}
            autoComplete="price"
            className={inputStyle}
          />
          <span className="text-sm font-medium text-gray-500">{user?.currency}</span>
        </div>
      </div>

      <div className="flex self-end space-x-2">
        <div
          className={`${
            isRemoveDisabled ? 'cursor-not-allowed bg-gray-300' : 'cursor-pointer bg-red-600'
          } flex items-center justify-center w-10 h-10 p-2 text-white rounded-full cursor-pointer hover:opacity-90`}
          onClick={() => {
            !isRemoveDisabled && handleDeleteInterval(id);
          }}>
          <DynamicIcon icon="MinusIcon" />
        </div>
        {id === newIntervals?.length - 1 && (
          <div className="flex items-center justify-center w-10 h-10 p-2 text-white rounded-full cursor-pointer bg-blue hover:opacity-90" onClick={addMore}>
            <DynamicIcon icon="PlusIcon" />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancedBonusFields;
