const ADDRESS_URL = '/address';

export const getAddressesByBusinessId = (businessId, filters) => {
  if (filters) {
    return `${ADDRESS_URL}/${businessId}${filters}`;
  } else {
    return `${ADDRESS_URL}/${businessId}`;
  }
};

export const createAddress = () => {
  const url = `${ADDRESS_URL}`;
  return url;
};

export const updateAddress = (id) => {
  const url = `${ADDRESS_URL}/${id}`;
  return url;
};
