const Button = ({ text, small, textColor, width, textSize, onClick, bg, border, children, disabled, rounded, smallText, type, noHover }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick && onClick();
    }
  };

  return (
    <button className={`flex ${width && 'w-full'}`} type={type} disabled={disabled}>
      <div
        className={`${small ? 'px-2 py-1' : 'px-3 py-3'} justify-center border ${textColor} font-medium ${width ? width : 'w-max'} flex ${
          rounded ? 'rounded-full' : 'rounded'
        } ${disabled ? 'bg-gray-300 cursor-not-allowed text-gray-400 border-gray-300' : `${border} ${bg}`} ${
          noHover ? 'hover:opacity-100 cursor-default' : 'hover:opacity-90 cursor-pointer'
        }`}
        onClick={handleClick}>
        <div className="flex flex-col px-3 text-center">
          <p className={`uppercase ${textSize}`}>{text}</p>
          <p className="text-xs">{smallText}</p>
        </div>
        {children && <div className="">{children}</div>}
      </div>
    </button>
  );
};

Button.defaultProps = {
  text: 'Button Text',
  textColor: 'text-white',
  bg: 'bg-blue',
  border: 'border-blue',
  type: 'button'
};

export default Button;
